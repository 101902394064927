.consultant-beneficiary-course-edit-page {
  flex: 1;

  .parcours-header {
    height: 100%;
  }

  .course-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 2.8rem;

    &-list {
      padding: 0;
      list-style: none;
    }

    &-list-item {
      margin-bottom: 1rem;
    }

    .steps {
      flex: 1;
      min-height: 10rem;
      padding-bottom: 8rem;
    }
  }

  .course-count {
    display: flex;
    margin: 0.5rem 0 0.8rem;

    > div {
      font-size: 1.4rem;
      @extend .pro-semibold !optional;

      > span {
        font-size: 1.2rem;
        color:var(--text-secondary);
      }

      &:first-child {
        margin-right: 3.5rem;
      }
    }
  }

  .course-description {
    @extend .pro-regular !optional;
    font-size: 1.2rem;
    line-height: 1.4rem;
    padding-bottom: 1.2rem;
    margin-bottom: 1.2rem;
  }

  .export-button {
    margin-bottom: 2.7rem;
  }

  .parcours-step-quiz {
    margin-bottom: 1rem;
  }

  .parcours-module-container {
    margin-bottom: 1rem;
  }

  .parcours-module-container .actions {
    flex: 1;
  }

  .action-item:first-child {
    .rdv-at {
      display: flex;
    }
  }

  .rdv-at {
    align-items: center;
    border-left: 1px solid var(--grey);
    padding-left: 2rem;
    margin: 0 auto;

    .rdv-info {
      margin-right: 1.3rem;
    }
  }

  .action-item:first-child {
    flex: 1;
  }
}
