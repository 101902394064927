.centraltest-module-beneficiary {
  width: 100%;
  display: flex;

  align-items: center;

  > * {
    padding: 1rem;
    align-items: center;
    display: flex;
  }

  > .selection {
    width: 100%;
    justify-content: space-between;

    > .details {
      width: 100%;
      display: flex;
      align-items: center;

      > .label {
        margin-left: 1rem;
      }
    }

    > .report-trigger {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 30px;
      padding: 7px 3rem;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
      border: 1px solid var(--main-color);
      background: transparent;
      box-shadow: 0 2px 4px 0 rgba(112, 130, 243, 0.5);
      border-radius: 3px;
      color: var(--main-color);
      font-size: 1.4rem;
      width: 25%;
    }

    > .primary-button {
      margin-left: 2rem;
    }
  }
}
