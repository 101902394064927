.tests-list {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    border-collapse: separate;
    border-spacing: 6px 12px;

  th,
    td {
      padding: 12px;
      text-align: center;
    }

    th {
      background-color: var(--main-color-light);
      font-size: 14px;
      color: var(--blue-dark);
    }

    td {
      font-size: 14px;
      color: var(--text-primary);
      border-radius: 6px;
      background-color: var(--pure-white);

      svg {
        display: flex;
      }
      &:nth-child(1) {
        text-align: left;
      }
    }

    tr {
      .test-name {
        display: flex;
        align-items: center;
        .icon {
          display: flex;
        align-items: center;
        }
        svg {
          width: 35px;
          height: 35px;
        }
      }
    }

    tr.row-clickable {
      cursor: pointer;
    }

    tr.row-clickable:hover {
      background-color: #efeffe;
    }
  
}
