@import "../../../stylesheets/common/fonts";

.parcours-synthesis {
    display: flex;
    flex-direction: column;
    gap: 12px;

    h1 {
        @extend .headline-1;
        font-size: 24px;
    }

    .primary-button {
        width: fit-content;
        margin: 0 auto;
    }
}