@import '../../../stylesheets/common/fonts';

.clients-list-wrapper {
    overflow: auto;
    .clients-list {
        width: 100%;
        border-collapse: separate;
        border-spacing: 6px 12px;

        th, td {
            padding: 12px;
            text-align: center;
        }

        th {
            background-color: var(--main-color-light);
            @extend .primary-default;
            font-size: 14px;
            color: var(--blue-dark);
        }

        td {
            @extend .primary-default;
            font-size: 14px;
            color: var(--text-primary);
            border-radius: 6px;

            &:nth-child(1) {
                text-align: left;
            }

        }

        tr {
            cursor: pointer;
            background-color: var(--pure-white);
        }
        tr:hover {
            // background-color: #efeffe;
            background-color: var(--main-color-lighter);

        }

        .client-name {
            @extend .primary-strong;
        }

        .actions {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 24px;

            .icon {
                cursor: pointer;
            }
            
            .icon.preview {
                width: 16px;
                height: auto;
            }

            .icon.trash2 {
                width: auto;
                height: 16px;
            }
        }
        .div-icon {
            display: flex;
            justify-content: center;
        }

    }
}