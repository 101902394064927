.published-modules-container-popin {
  display: flex;
  flex-direction: column;
  gap: 24px;
  // min-width: 720px;
  padding-bottom: 24px;
  height: 600px;
  max-height: 600px;
  overflow: scroll;

  .published-modules-container-popin-header {
    background-color: var(--main-color);
    padding: 12px 24px;
    border-radius: 6px 6px 0 0;

    >h1 {
      color: var(--pure-white);
      font-size: 24px;
      font-weight: 600;
    }
  }

  .published-modules-container-popin-content {
    padding: 12px 24px;

    .all-filters {
      margin-bottom: 24px;
      .all-filters-title {
        display: flex;
        align-items: center;
        gap: 6px;

        p {
          color: var(--text-secondary);
          font-size: 12px;
        }

        .icon {
          width: 12px;
          height: auto;
        }
      }

      .all-filters-content {
        display: flex;
        flex-direction: column;
        gap: 6px;
      }

      .big-combo {
        margin: 6px 0;
        border: 1px solid var(--main-color-dark);
        padding: 3px 8px;
        border-radius: 6px;
        
        .select-container {
          color: var(--text-primary-dark);
          width: 100%;
          padding: 6px 0;

          .Dropdown-placeholder {
            color: var(--main-color-dark);
          }
        } 
  
        .Dropdown-menu {
          top: 42px;
          border: 1px solid var(--main-color-dark);
          border-top: none;
          border-radius: 0 0 6px 6px;
          .Dropdown-option {
            font-size: 14px;
            color: var(--text-primary)
          }
        }
      }
    }

    .all-modules-containers {

      label {
        @extend .primary-strong !optional;
        color: var(--text-primary);
        font-size: 14px;
        font-weight: 700;
      }
      .module-container-row {
        border: 1px dashed var(--main-color);
        border-radius: 6px;
        background-color: var(--main-color-lighter);
        padding: 6px 12px;
        margin: 12px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
      }
    }
  }

}