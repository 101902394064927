.buttons-group {
    display: flex;
    gap: 12px;
    align-items: center;

    button {
        width: 40px;
        height: 40px;
        background-color: var(--main-color);
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:disabled {
            background-color: var(--grey-medium);
            cursor: not-allowed;
        }

        &:first-child {
            border-radius: 24px 3px 3px 24px;
        }

        &:last-child {
            border-radius: 3px 24px 24px 3px;

            .icon {
                transform: rotate(180deg);
            }
        }

        .icon {
            
            width: 18px;
            height: 18px;
            display: block;

            svg {
                width: 100%;
                height: 100%;
            }
        }
    }
}
