.icon {
  $default-icon-size: 18px;
  $icon-accent-color: var(--main-color) !default;
  $icon-grey1-color: var(--grey) !default;
  $icon-grey2-color: var(--text-secondary) !default;
  $icon-grey3-color: var(--text-primary-dark) !default;
  $icon-red1-color: var(--red) !default;
  $icon-orange1-color: var(--orange) !default;
  $icon-green1-color: var(--green) !default;

  @mixin colorSvg($color) {
    *[fill]:not([fill="none"]) {
      fill: $color;
    }
    *[stroke]:not([stroke="none"]) {
      stroke: $color;
    }
  }

  &[data-color="accent"] {
    @include colorSvg(var(--main-color));
  }

  &[data-color="grey1"] {
    @include colorSvg($icon-grey1-color);
  }

  &[data-color="grey2"] {
    @include colorSvg($icon-grey2-color);
  }

  &[data-color="grey3"] {
    @include colorSvg($icon-grey3-color);
  }

  &[data-color="red1"] {
    @include colorSvg($icon-red1-color);
  }

  &[data-color="orange1"] {
    @include colorSvg($icon-orange1-color);
  }

  &[data-color="green1"] {
    @include colorSvg($icon-green1-color);
  }

  &[data-color="white"] {
    @include colorSvg(white);
  }

  > svg {
    width: $default-icon-size;
    height: $default-icon-size;
  }

  &.round-minus {
    width: 100%;
    height: 100%;
  }

  &.trash2 {
    width: 1.5rem;
  }

  &.lock {
    width: 1.5rem;
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &.message {
    width: 24px;
    height: 24px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}
