@import '../../../../../stylesheets/common/fonts';

.appointment-ressource {
    h2 {
        @extend .headline-2;
        font-size: 18px;
        color: var(--text-primary)
    }

    .waiting-appointment {
        @extend .secondary-default;
        font-size: 14px;
        color: var(--text-secondary);

        span {
            color: var(--text-primary);
        }
    }
}