$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

.consultant-beneficiary-calendar-page {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .action-button {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    font-size: 1.2rem;
    color: var(--text-secondary);

    > button {
      margin-right: 1rem;
    }
  }

  button {
    // width: fit-content;
  }

  .calendar-page-content {
    @media screen and (max-width: $sm) { //Val
      position: absolute !important;
      left: 0;
    }
    
  }
}
