.icon-background {
    background-color: var(--main-color-lighter);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    border-radius: 3px;
    .icon {
        height: 15px;
        svg > * {
            height: 12px;
            width: auto;
        }  
    }
}