.user-left-column-layout {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px 0;

  header {
    display: flex;
    justify-content: center;
  }

  section {
    margin-top: 100px;
  }

  footer {
    margin-top: auto;
  }
}