$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

.manager-consultant-beneficiaries {
  display: flex;
  flex-flow: column;
  width: 100%;

  .status-filter {
    align-self: end;
    margin-bottom: 2rem;
  }

  .beneficiaries {
    display: flex;
    gap: 15px;
    flex-flow: wrap;

    > .candidat-card {
      width: 230px;
      @media screen and (max-width: $md) {
        width: 100%;
      }
    }
  }

  > .no-result {
    width: 100%;
    padding: 3rem;
    text-align: center;
  }
}
