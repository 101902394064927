.links-ressource {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .file,
    .video,
    .link {
        display: flex;
        align-items: center;
        gap: 6px;

        svg {
            display: block;
            width: 12px;
            height: auto;

        }
    }
}