.form-multiple-choice-configuration-row {
  width: 100%;
  display: flex;
  align-items: center;

  > .step-alpha-key {
    width: 1rem;
    margin-right: 1rem;
    display: inline-block;

    @extend .pro-semibold !optional;
    color: var(--main-color);
  }

  > .choice-field {
    display: inline-block;
    flex: 1;
  }

  > .buttons {
    display: inline-block;
    float: right;

    .icon-button {
      display: inline-block;
      height: 1.3rem;
      width: 1.3rem;
      margin-left: 1rem;
    }
  }
}
