.quiz-date {
  width: 100%;

  .date-container {
    margin-top: 3rem;
    padding-left: 3rem;

    display: flex;

    .combo-field {
      width: 12rem;
      margin: 0 1rem;
    }
  }

  // @include mobile() {
  //   .date-container {
  //     margin-top: 2rem;
  //     padding-left: 3rem;

  //     .combo-field {
  //       width: 8rem;
  //       margin-left: 0;
  //     }
  //   }
  // }
}
