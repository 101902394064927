@import '../../../stylesheets/common/fonts';

.client-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px;

    .client-title {
        display: flex;
        align-items: center;
        gap: 12px;
        width: 400px;

        p {
            @extend .headline-2;
            font-size: 16px;
            color: var(--text-secondary);
        }
    }

    .client-actions {
        display: flex;
        align-items: center;
        gap: 24px;
    }
}