.background-icon {
    display: flex;
    padding: 6px;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    width: 30px;
    height: 30px;

    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        
            svg > * {
                width: 18px;
                height: 18px;
                line-height: 1;
            } 
    }
}