.manager-office-page {
    .manager-office-page-content {
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-bottom: 12px;

      .primary-button {
        width: fit-content;
        align-self: flex-end;
      }
    }
  
  }