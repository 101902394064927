$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

.left-aside-layout {
  flex: 1;
  display: flex;
  flex-direction: row;
  max-width: 100vw;
  max-height: 100vh;

  .aside {
    position: relative;
    background-color: var(--pure-white);
    width: 25rem;
    transition: width 300ms ease-in-out;
    /* Remove display on prod */
    //display: none;

    @media screen and (max-width: $md) {
      //Val
      display: none;

      &.mobile {
        display: block;
        width: 100%;
      }
    }

    .toggle-btn {
      position: absolute;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: var(--main-color);
      border: none;

      cursor: pointer;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
    
        svg {
          width: 21px;
          height: 21px;
        }
      }
    }
  }

  .aside.open {
    .toggle-btn {
      top: 10px;
      right: 3px;
    }
  }

  .aside.close {
    .toggle-btn {
      top: 10px;
      right: calc(50% - 12px);
    }
  }

  .aside > .beneficiary-page-aside > aside > section > ul > li {
    cursor: pointer;
    position: relative;

    a {
      text-decoration: none;
      display: flex;
      align-items: center;
      gap: 12px;
      color: var(--text-secondary);
      padding: 12px 0px;
    }

    a.active {
      font-weight: 700;
      height: 100%;
    }

    a.active::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 3px;
      height: 100%;
      background-color: var(--main-color);
    }

    .icon {
      height: auto;
      display: flex;
      align-items: center;
    }
  }

  .aside > .beneficiary-page-aside > aside > section > ul > .side-menu-sublinks > li {
    a.active {
      @extend .primary-strong !optional;
      font-weight: 700;
      height: 100%;
    }

    a.active::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 3px;
      height: 100%;
      background-color: var(--main-color);
    }
  }

  .aside.open > .beneficiary-page-aside > aside > section > ul > li > a > span {
    display: flex;
  }

  .aside.open > .beneficiary-page-aside > aside > section > ul > li > a > .icon {
    margin-left: 30px;
  }

  .aside.open > .beneficiary-page-aside > aside > section > ul > li {
    a.active {
      color: var(--text-primary);
      background-color: var(--main-color-lighter);
      @extend .primary-strong !optional;
    }
    a.active > .icon > svg {
      * {
        stroke: var(--main-color);
      }
    }

    a.active::before {
      background-color: var(--main-color);
      @extend .primary-strong !optional;
    }
  }

  .aside.open > .beneficiary-page-aside > aside > section > ul > li:hover {
    background-color: var(--main-color-lighter);
  }

  .aside.open > .beneficiary-page-aside > aside > section > ul > .side-menu-sublinks > .main-link {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    padding: 12px 28px;

    .label {
      display: flex;
      align-items: center;
      gap: 12px;
      color: var(--text-secondary);
    }

    .icon {
      align-self: end;
    }
  }

  .aside.open > .beneficiary-page-aside > aside > section > ul > .side-menu-sublinks > .selected {
    position: relative;
    .label {
      color: var(--text-primary);
    }

    .icon {
      svg {
        * {
          stroke: var(--main-color);
        }
      }
    }
  }

  .aside.open
    > .beneficiary-page-aside
    > aside
    > section
    > ul
    > .side-menu-sublinks
    > .selected::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background-color: var(--main-color);
  }

  .aside.open > .beneficiary-page-aside > aside > section > ul > .side-menu-sublinks > li {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .aside.open > .beneficiary-page-aside > aside > section > ul > .side-menu-sublinks > li > a {
    padding: 12px 28px;
    align-self: end;
    width: 100%;
    color: var(--text-secondary);

    a.active {
      color: var(--text-primary);
      background-color: var(--main-color-lighter);
      @extend .primary-strong !optional;
    }
    a.active > .icon > svg {
      * {
        stroke: var(--main-color);
      }
    }

    a.active::before {
      @extend .primary-strong !optional;

      background-color: var(--main-color);
    }
  }

  // .aside.open > .beneficiary-page-aside>aside>section>ul> .side-menu-sublinks > li > a:hover {
  //   background-color: var(--main-color-lighter);
  //   color: var(--text-primary);
  // }

  .aside.open > .beneficiary-page-aside > aside > footer {
    padding: 0 28px;
  }

  .aside.close {
    width: 8rem;
    background-color: var(--blue-dark);
  }

  .aside.close > .beneficiary-page-aside > aside > section > ul > li > a {
    display: flex;
    justify-content: center;
    margin-left: 0px;
    padding-left: 0;
  }

  .aside.close > .beneficiary-page-aside > aside > section > ul > li > a > .icon {
    display: flex;
    justify-content: center;
  }

  .aside.close > .beneficiary-page-aside > aside > section > ul > li > a > span {
    display: none;
  }

  .aside.close > .beneficiary-page-aside > aside > section > ul > li {
    a.active {
      color: var(--pure-white);
      background-color: var(--main-color);
      @extend .primary-strong !optional;
    }
    a.active > .icon > svg {
      * {
        stroke: var(--pure-white);
      }
    }

    a.active::before {
      content: '';
      background-color: var(--pure-white);
    }
  }

  .aside.close > .beneficiary-page-aside > aside > section > ul > li:hover {
    background-color: var(--main-color);
  }

  .aside.close > .beneficiary-page-aside > aside > section > ul > .side-menu-sublinks > .main-link {
    display: flex;
    justify-content: center;
    cursor: pointer;

    .label > span {
      display: none;
    }
  }

  .aside.close > .beneficiary-page-aside > aside > section > ul > .side-menu-sublinks > .main-link {
    position: relative;
  }

  .aside.close
    > .beneficiary-page-aside
    > aside
    > section
    > ul
    > .side-menu-sublinks
    > .main-link
    > .icon {
    position: absolute;
    right: 12px;
  }

  .aside.close > .beneficiary-page-aside > aside > footer {
    display: flex;
    justify-content: center;
    .logout-button > .label {
      display: none;
    }

    .tutorials > a {
      display: none;
    }
  }

  .aside.close > .beneficiary-page-aside > aside > header {
    .logos > img {
      margin-top: 24px;
      width: 45px;
      height: auto;
    }
  }
  .content {
    flex: 1;
    padding: 2.5rem;
    background: var(--grey-light);
    overflow: auto;
    height: auto;

    @media screen and (max-width: $sm) {
      //Val
      padding: 0px;
      padding-top: 5.5rem;
    }

    .content-section {
      padding: 2.5rem;
      padding-top: 0rem;

      @media screen and (max-width: $md) {
        //Val
        overflow-x: hidden;
        padding-top: 4.5rem;
        padding-bottom: 6.5rem;
      }
    }

    .navbar {
      margin-bottom: 12px;
    }

    .chatbot {
      position: absolute;
      bottom: 24px;
      right: 24px;
    }
  }
}
