@import '../../../stylesheets/common/fonts';

.sorter-button {
    cursor: pointer;
    border-radius: 6px;
    padding: 6px 12px;
}

.active {
    background-color: var(--main-color);
    p {
        @extend .secondary-default;
        color: var(--pure-white);
        font-size: 14px;
    }
} 

.inactive {
    background-color: var(--main-color-lighter);
    p {
        @extend .secondary-default;
        font-weight: 400;
        color: var(--text-secondary);
        font-size: 14px;
    }
}