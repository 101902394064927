.module-synthesis-edit-page {
  height: 100vh;

  .popin-layout {
    height: 100%;

    > header {
      .action-popin-header {
        > .actions-part {
          > .statuses_combo {
            margin-left: 9.5rem;
          }
        }
      }
    }

    .description {
      > p {
        font-size: 1.2rem;
      }
    }

    .side-synthesis {
      display: flex;
      flex-direction: column;

      .icon-card-title {
        display: flex;
        justify-content: center;
      }

      .separator {
        width: 100%;
        height: 1px;
        background-color: var(--grey);
        margin: .4rem 0 .9rem;
      }

      .side-text {
        margin: 0 2rem;
        font-size: 1.2rem;
        word-break: break-all;
      }
    }

    > section {
      padding: 0;

      .right-aside-layout {
        padding-top: 0.5rem;

        > section {
          padding-top: 0;
        }
      }
    }

    .icon-card-title {
      margin-top: 2rem;
    }

    .text-area-field {
      //height: 90%;
    }
  }

  // @include mobile() {
  //   .right-aside-layout {
  //     > section {
  //       padding: 2rem;

  //       .icon-card-title {
  //         margin-top: 1rem;
  //       }
  //     }
  //   }
  // }
}
