@import '../../../../../stylesheets/common/fonts';
.document-to-return-ressource {
    display: flex;
    flex-direction: column;
    gap: 12px;
    
    h2 {
        @extend .headline-2;
        font-size: 18px;
    }

    .document-to-return-ressource-all-documents {
        display: flex;
        gap: 26px;
        justify-content: space-between;
        position: relative;

        .document-to-download, .document-to-upload {
            display: flex;
            flex-direction: column;
            font-size: 14px;
            width: 50%;
        }
    
        .document-to-upload > span, .document-to-download > span {
            font-size: 12px;
            color: var(--text-secondary);
        } 
    
        .document-url {
            display: flex;
            justify-content: space-between;
            align-items: center;
    
            a {
                color: var(--text-primary);
            }
    
            .waiting-document {
                color: var(--text-secondary)
            }
        }
    }

    .document-to-download::after {
        content: "";
        position: absolute;
        height: 100%;
        width: 1px;
        background-color: var(--grey-medium);
        left: 50%;
    }


}