/******** Icon ********/

// Note: Font generate with Icomoon, to update font:
// - go to https://icomoon.io
// - upload selection.json
// - update your font
// - download updated font
// - replace icon.eot icon.ttf icon.woff icon.svg selection.json
// - replace class name at the end of this file

@font-face {
  font-family: 'icon';
  src: url('../../static/fonts/icon/icon.eot');
  src: url('../../static/fonts/icon/icon.eot?#iefix') format('embedded-opentype'),
  url('../../static/fonts/icon/icon.ttf') format('truetype'),
  url('../../static/fonts/icon/icon.woff') format('woff'),
  url('../../static/fonts/icon/icon.svg?#icon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*="icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
