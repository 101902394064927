.image-step-popin {
    position: relative;

    .image-step-popin-edit {
        // position: absolute;
        color: var(--black);
        display: flex;
        justify-content: space-between;
        padding: 12px 24px;

        h2 {
            font-size: 24px;
        }
        .actions {
            display: flex;
            align-items: center;
            gap: 12px;
            color: var(--text-primary);

            .action {
                display: flex;
                align-items: center;
                gap: 6px;
            }

            .edit-image {
                cursor: pointer;
                input {
                    display: none;
                }

            }

            .delete-image {
                cursor: pointer;
            }

            .icon {
                display: flex;
                align-items: center;
                justify-content: center;
                svg {
                    width: 12px;
    
                    * {
                        stroke: var(--text-primary);
                    }
                }
            }
        }


    }

    img {
        width: 700px;
        height: auto;
    }
}