.parcours-list-modules {
    background-color: var(--grey-light);
    padding: 24px;
    border-radius: 6px;
    border: 1px solid var(--grey-medium);
    margin: 12px 0;


    .parcours-list-modules-header {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding-bottom: 12px;

        .tag-container {
            width: fit-content;
        }

        .title {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        h2 {
            @extend .headline-2 !optional;
            font-size: 18px;
            line-height: 26px;
        }
    }

    .parcours-list-module-description {
        margin: 3px 0;
    }

    .parcours-list-module {
        margin: 6px 0;

        .description {
            font-size: 16px;
            line-height: 24px;
            color: var(--text-secondary);
        }
    }
}