$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

.groups-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 0.25fr));
        gap: 24px;
    margin: 24px 0;
    @media screen and (max-width: $md) {
        grid-template-columns: repeat(auto-fit, minmax(200px, 100%));
        
    }
    .group-item {
        @media screen and (max-width: $md) {
            width: 100%;
        }
    }
}