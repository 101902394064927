@import '../../../stylesheets/common/fonts';

.parcours-consultant-synthesis {
    background-color: var(--pure-white);
    border-radius: 12px;
    .header-parcours-consultant-synthesis {
        position: relative;
        margin: 12px 0;

        h3 {
            padding: 0 12px;
            @extend .headline-3;
            font-size: 16px;
        }
    }

    .header-parcours-consultant-synthesis::after {
        content: "";
        position: absolute;
        background-color: var(--main-color);
        width: 4px;
        height: 100%;
        top: 0;
        left: -2px;
        border-radius: 12px;
    }

    p {
        padding: 12px;
        @extend .secondary-default;
        font-size: 14px;
    }
}