.financial-edition-card {
  box-shadow: 0 4px 6px 0 rgba(112, 130, 243, 0.08);

  > .icon-card-title {
    margin-left: 0.5rem;
  }

  .financial-edition-card-content {
    padding: 1.5rem 2.5rem;
    background: white;

    .combo-field {
      margin-bottom: 1rem;

      > .select-container {
        > .prefix-node {
          .icon {
            width: 1rem;
            height: 1rem;
            position: relative;

            svg {
              width: 1rem;
              height: 1rem;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        }
      }
    }

    .input-field {
      margin-bottom: 1rem;
    }

    .upload-input, .download-input {
      margin-bottom: 1rem;

      display: flex;
      align-items: center;

      .delete-icon {
        width: 3rem;
        height: 3rem;
        position: relative;

        .icon-button {
          position: absolute;
          bottom: 0.5rem;
          left: 50%;
          transform: translateX(-50%);
          width: 1rem;
          height: 1rem;

          .icon, .icon svg {
            width: 1rem;
            height: 1rem;
            min-width: auto;
            min-height: auto;
            position: relative;
          }

          .icon svg {
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }

      .download-icon, .upload-icon {
        width: 3rem;
        height: 3rem;

        .circle-button {
          width: 3rem;
          height: 3rem;

          .circle-button .icon, .circle-button .icon svg {
            width: 1.5rem;
            height: 1.5rem;
          }
        }
      }

      .delete-icon, .download-icon, .upload-icon {
        margin: 0 0.5rem;
      }
    }

    .info-field {
      margin-bottom: 1rem;
    }

    .financial-ht {
      display: flex;
      align-items: center;

      .input-field {
        flex: 1;
      }

      > div:last-child {
        @extend .common-form-input !optional;
        margin: 0 0.5rem;
      }
    }

    .financial-tva {
      display: flex;
      align-items: center;

      > div {
        flex: 1;
      }

      > .info-field {
        margin-left: 1rem;

        > div .light-label {
          margin-bottom: 0;
        }
      }
    }
  }
}
