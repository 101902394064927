.top-search {
  flex: 1;

  > .search-field {
    > input {
      text-align: right;
      font-size: 1.4rem;
    }

    > .icon {
      width: 2rem;
      min-width: 2rem;
      min-height: 2rem;
    }
  }
}
