.toggle-button {

  $toggle-active-color: var(--main-color);
  $toggle-height: 16px;
  $toggle-width: 24px;

  height: $toggle-height;
  width: $toggle-width;
  position: relative;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  user-select: none;

  @extend .pro-semibold !optional;
  font-size: 1rem;
  border: 1px solid $toggle-active-color;
  border-radius: $toggle-height / 2;
  background: white;

  transition: background-color 150ms ease-in-out;

  .dot {
    position: absolute;
    width: $toggle-height - 4px;
    height: $toggle-height - 4px;
    border-radius: $toggle-height / 2;
    top: 1px;
  }

  .active-dot {
    background-color: $toggle-active-color;
    left: 1px;
    transition: left 150ms ease-in-out;
  }

  .white-dot {
   background-color: var(--pure-white);
    right: 1px;
    opacity: 0;
    transition: opacity 150ms ease-in-out;
  }

  .label {
    position: absolute;
    width: 100%;
  }

  .label-on {
    color: white;
    text-align: left;
    padding-left: 5px;
    opacity: 0;
  }

  .label-off {
    color: $toggle-active-color;
    text-align: right;
    padding-right: 5px;
    opacity: 1;
    margin-left: 2px;
  }

  &.on {
    background-color: $toggle-active-color;

    .active-dot {
      left: $toggle-width  - $toggle-height + 1px;
    }

    .white-dot {
      opacity: 1;
    }

    .label-on {
      opacity: 1;
    }

    .label-off {
      opacity: 0;
    }
  }

}
