.avatar-card {
  $avatar-card-text-color: var(--text-primary-dark) !default;
  $avatar-card-border-color: var(--grey-medium) !default;

  box-shadow: 0 4px 6px 0 rgba(112, 130, 243, 0.08);

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
 background-color: var(--pure-white);

  width: 100%;
  height: 100%;

  > .info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 13px;
    flex-grow: 1;

    .avatar-container {
      width: 80px;
      height: 80px;
    }

    .name {
      font-size: 1.2rem;
      @extend .pro-semibold !optional;
      color: $avatar-card-text-color;
      margin-top: 7px;
    }

    .job {
      font-size: 1.2rem;
      @extend .pro-light !optional;
      color: $avatar-card-text-color;
      margin-top: 2px;
      text-align: center;
    }

    .phone {
      font-size: 1.2rem;
      @extend .pro-semibold !optional;
      color: $avatar-card-text-color;
      margin-top: 13px;
    }
  }

  > .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid $avatar-card-border-color;
    width: 100%;
    margin-top: auto;
    min-height: 65px;

    .primary-button {
      label {
        font-size: 1.2rem;
      }
    }
  }

  // @include mobile() {
  //   > .info-container {
  //     .name {
  //       font-size: 1.8rem;
  //     }

  //     .job {
  //       font-size: 1.4rem;
  //     }

  //     .phone {
  //       font-size: 2rem;
  //       margin-top: 1.8rem;
  //       margin-bottom: 3rem;
  //     }
  //   }
  // }

}
