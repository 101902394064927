$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

.user-card {
  display: flex;
  flex-direction: column;
  width: 37rem;
  @media screen and (max-width: $md) {
    width: 100%;
  }

  > .icon-card-title {
    margin-left: 10px;
    margin-bottom: 8px;
  }

  > .form {
    padding: 2rem;
    display: flex;
    flex-direction: row;
    background: var(--pure-white);
    box-shadow: 0 4px 6px 0 rgba(112, 130, 243, 0.08);
    border-radius: 3px;

    > .photo {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 2rem;
    }

    > .fields {
      display: flex;
      flex: 1;
      flex-direction: column;

      .field-container {
        margin-bottom: 1rem;
      }

      > * {
        flex-basis: 48%;
      }

      .separator {
        flex-basis: 4%;
      }

      .two-column-row {
        display: flex;
        margin-bottom: 1rem;

        .field-container {
          flex-basis: 48%;
        }
      }

      .fields-display-flex {
        display: flex;
        gap: 24px;
      }
    }
  }
}
