.radio-field {

 background-color: var(--pure-white);

  > .radio-container {
    display: inline-block;

    &:not(:last-of-type) {
      margin-right: 15px;
    }

    input[type="radio"] {
      position: absolute;
      visibility: hidden;
      margin: 0;
    }

    label {
      display: flex;
      align-items: center;
      cursor: pointer;

      .icon {
        width: 100%;
        height: 100%;
        display: inline-flex;

        svg {
          width: 12px;
          height: 12px;
        }
      }

      .label {
        @extend .common-form-input !optional;
        margin-left: 6px;
      }
    }
  }

}
