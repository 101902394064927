:root {
  --login-background-image-url: url('/static/images/background-login.jpg');
}

.portal-login {
  min-height: 100vh;
  display: flex;
  flex: 1;
  flex-direction: row;

  > header.login-back {
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;

    & .icon-button svg {
      width: 30px;
      height: 30px;
    }
  }

  > section.login-form-container {
    display: flex;
    flex: 2;
    flex-direction: column;
    align-self: center;
    align-items: center;
    margin: auto 40px;
    color: var(--text-primary-dark);

    > h2 {
      font-size: 1.4rem;
      margin-bottom: 40px;

      > b {
        @extend .pro-bold !optional;
      }
    }

    > .error {
      display: flex;
      margin-top: 20px;
      flex-direction: column;
      align-items: center;

      > span {
        color: red;
        margin-bottom: 10px;
      }
    }

    > .login-form {
      .input-field {
        width: 24rem;
        margin-bottom: 2rem;

        .error {
          display: flex;
          flex-direction: column;
          align-items: left;
          font-size: 1rem;

          > span {
            color: red;
          }
        }
      }
    }
  }

  > section.cover {
    @extend .pro-semibold !optional;

    display: flex;
    flex: 3;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 2.4rem;
    background-size: cover;

    background: var(--login-background-image-url) no-repeat center center;
    background-size: cover;

    svg {
      width: 135px;
      height: 35px;
      margin-bottom: 20px;
    }

    > .office-logo {
      max-width: 150px;
    }
  }

  // @include mobile() {
  //   flex-direction: column;

  //   > section.cover {
  //     font-weight: normal;
  //     flex: none;
  //     order: -1;
  //     height: 11.5rem;
  //     font-size: 1.4rem;

  //     svg {
  //       width: 8.8rem;
  //       height: 3rem;
  //       margin-bottom: 1.4rem;
  //     }
  //   }

  //   > section.login-form-container {
  //     justify-content: center;
  //   }
  // }
}
