$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

.menu-mobile {
  display: none;
  .left-aside-layout {
    height: 100%;
  }
  @media screen and (max-width: $md) {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: -100%;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    background-color: rgba(255, 255, 255, 1);
    overflow: scroll;
    transition: left 0.5s ease-in-out;

    .beneficiary-page-aside {
      width: 100%;

      header {
        display: none;
      }

      section {
        margin-top: 4.5rem;
      }

    }
  }
}

.navbar {
  display: flex;
  flex-flow: row wrap;
  color: var(--text-primary);
  justify-content: flex-end;

  @media screen and (max-width: $md) {
    justify-content: space-between;
    width: 100%;
    background-color: var(--pure-white);
    margin: 0 !important;
    padding: 10px;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
  }

  >.resources {
    display: flex;
    flex-direction: row;
    gap: 12px;
    justify-self: flex-end;

    @media screen and (max-width: $md) {
      width: 100%;

      p {
        display: none
      }

      .office-logo {
        object-fit: cover;
        border-radius: 100%;
        width: 36px;
        height: 36px;
      }

      img {
        width: 36px;
        height: 36px;
      }
    }

    .resources-title-mobile {
      display: none;

      @media screen and (max-width: $md) {
        display: block;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        align-self: center;
        width: 100%;
      }
    }

    .resources-header-mobile {
      display: none;

      @media screen and (max-width: $md) { //Val
          display: flex;
          width: 100px;
          .logos {
            gap: 12px;
            .separator {
              height: 22px;
              width: 1px;
              background-color: var(--grey-medium);
            }
          } 
      }
    }

    
    .resources-btns-wrapper {
      display: flex;
      gap: 12px;
    
      >.resources-btn {
        border-radius: 6px;
        padding: 12px;
        align-items: center;
        gap: 12px;
        background-color: var(--pure-white);
        ;
        border: 1px solid var(--grey-medium);
        display: flex;
        flex-direction: row;
        font-size: 14px;
        position: relative;
        cursor: pointer;

        @media screen and (max-width: $md) { //Val
          padding: 0px;
          width: 100px;
          border: none;
          border-radius: 100%;
          aspect-ratio: 1;
          width: 36px;
          height: 36px;
          justify-content: center;
          align-items: center;
        }

        &.resources-profile {
          padding: 12px 24px;

          @media screen and (max-width: $md) { //Val
            padding: 0px;
          }
        }

        &.resources-alert {
          // Edit to add bell icon to navbar
          display: none;

          .red-dot {
            background: var(--red);
            height: 0.9rem;
            width: 0.9rem;
            border-radius: 100%;
            position: absolute;
            right: 1.2rem;
            top: 1.2rem;
          }
        }

        &.resources-burger {
          display: none;
          
          @media screen and (max-width: $md) { //Val
            display: flex;
            cursor: pointer;
          }
        }

        &.resources-msg {

          >.red-dot {
            background: var(--red);
            height: 0.9rem;
            width: 0.9rem;
            border-radius: 100%;
            position: absolute;
            left: 2.7rem;
            top: 1.2rem;
            z-index: 10;
          }

          >.message-alert {
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 21px;
            text-align: center;
          }

          >.message-number {
            background: var(--red);
            color: var(--pure-white);
            padding: 0 5px 1px 5px;
            display: flex;
            border-radius: 3px;
            font-size: 9px;
            font-weight: 700;
            font-style: normal;
            line-height: 15px;
          }
        }

        >.resources-avatar {
          
          .avatar-circle {
            width: 36px;
            height: 36px;
            border-radius: 50%;
          
            .picture {
              width: 36px;
              height: 36px;
              border-radius: 50%;
            }

            
            .no-picture {
              background-color: var(--grey-light);
              width: 36px;
              height: 36px;

              .icon {
                display: flex;
                justify-content: center;
                align-items: center;
            
                svg {
                  width: 18px;
                  height: 18px;
                }
              }
            }
          }
        }

        >.icon {
          width: 21px;
          height: 21px;
        }

        >.message {
          width: 24px;
          height: 24px;
          box-shadow: unset;
          margin: unset;
          display: flex;
          align-self: center;
        }
      }
    }
  }

  >.components {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 3px;
  }
}

.navbar-space-between {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  >.resources {
    display: flex;
    flex-direction: row;
    gap: 12px;
    justify-self: flex-end;

    >.resources-btn {
      border-radius: 6px;
      padding: 12px;
      align-items: center;
      gap: 12px;
      background-color: var(--pure-white);
      ;
      border: 1px solid var(--grey-medium);
      display: flex;
      flex-direction: row;
      font-size: 14px;
      position: relative;
      cursor: pointer;

      &.resources-profile {
        padding: 12px 24px;
      }

      &.resources-alert {
        // Edit to add bell icon to navbar
        display: none;

        .red-dot {
          background: var(--red);
          height: 0.9rem;
          width: 0.9rem;
          border-radius: 100%;
          position: absolute;
          right: 1.2rem;
          top: 1.2rem;
        }
      }

      &.resources-msg {

        >.red-dot {
          background: var(--red);
          height: 0.9rem;
          width: 0.9rem;
          border-radius: 100%;
          position: absolute;
          left: 2.7rem;
          top: 1.2rem;
          z-index: 10;
        }

        >.message-alert {
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 21px;
          text-align: center;
        }

        >.message-number {
          background: var(--red);
          color: var(--pure-white);
          padding: 0 5px 1px 5px;
          display: flex;
          border-radius: 3px;
          font-size: 9px;
          font-weight: 700;
          font-style: normal;
          line-height: 15px;
        }
      }

      >.resources-avatar {
        width: 24px;
        height: 24px;
        margin-right: 6px;

        & svg {
          width: 100%;
        }
      }

      >.icon {
        width: 24px;
        height: 24px;
      }

      >.message {
        width: 24px;
        height: 24px;
        box-shadow: unset;
        margin: unset;
        display: flex;
        align-self: center;
      }
    }
  }

  >.components {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 3px;
  }
}