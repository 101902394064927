.accomp-collective-system-card {
    background-color: var(--pure-white);
    box-shadow: 0 4px 6px 0 rgba(112, 130, 243, 0.08);
    padding: 12px;
    border-radius: 6px;

    .accomp-collective-system-card-content {
                display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 24px; 
    }
}  