@import '../../../stylesheets/common/fonts';
$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

.group-content {
    display: flex;
    justify-content: space-between;
    align-items: top;
    gap: 24px;
    height: 85vh;
    @media screen and (max-width: $lg) {
        flex-direction: column;
        height: 100%;
        
    }

    .group-presentation {
        width: 40%;
        @media screen and (max-width: $lg) {
            width: 100%;
        }

        .group-presentation-card {
            background-color: var(--pure-white);
            padding: 24px;
            border-radius: 6px;
            display: flex;
            flex-direction: column;
            gap: 12px;

            h2 {
                @extend .headline-2;
                font-size: 14px;
                color: var(--text-primary);
                text-transform: uppercase;
            }
            .presentation-card-container {
                display: flex;
                align-items: center;
                gap: 24px;
                width: 100%;

                .input-field {
                    width: 100%;
                }

                .big-combo {
                    width: 100%;

                    .select-container {
                        width: 100%;
                    }
                }

                label {
                    width: fit-content;
                    cursor: pointer;
                    width: 50px;
                    height: 40px;
                    border: 1px solid var(--main-color-lighter);
                    background-color: var(--main-color-lighter);
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
    
                    .icon {
                      width: 18px;

                      svg {
                        width: 100%;
                        height: 100%;
                      }
    
                      * {
                        stroke: var(--main-color);
                      }
                    }
    
                    input {
                      display: none;
                    }
                  }
            }

            .big-combo {
                .select-container {
                    width: 100%;
                }
            }
        }
    }

    .group-members {
        border: 1px dashed var(--main-color);
        background-color: var(--main-color-lighter);
        width: 60%;
        padding: 24px;
        border-radius: 6px;
        height: fit-content;
        @media screen and (max-width: $lg) {
            width: 100%;
            
        }
        
        .group-members-presentation {   
            display: flex;
            align-items: center;
            justify-content: space-between;
            h2 {
                @extend .headline-2;
                font-size: 14px;
                color: var(--text-primary);
                text-transform: uppercase;
            }
        }


        .all-members {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
            gap: 24px;
            margin: 24px 0;
        }
    }
}