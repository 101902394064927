.admin-course-templates-page {
  flex: 1;

  .tabbed-content-layout {
    > section {
      > .components {
        display: flex;
        margin-bottom: 2rem;

        .combo-filter-field {
          flex: 1;
          justify-content: flex-end;
        }
      }
    }
  }
}