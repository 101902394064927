.tabbed-content-layout {
  flex: 1;
  display: flex;
  flex-direction: column;

  .navbar-user {
    border-bottom: 1px solid var(--grey);
    margin: 0 24px;
    padding-bottom: 24px;
    display: flex;
    justify-content: end;
    gap: 12px;

    .btn {
      border: 1px solid var(--grey);
      padding: 8px 24px;
      border-radius: 6px;
      display: flex;
      align-self: center;
      align-items: center;
      gap: 12px;
      background-color: var(--pure-white);
      cursor: pointer;
    }

    .avatar {
      height: 24px;
      width: 24px;
      border-radius: 50%;
      background-color: var(--main-color);
    }

  }

  > header {
    margin-bottom: 2rem;
  }

  > section {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-x: auto;
    min-height: 40vh;
  }
}
