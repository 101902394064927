
$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;



.beneficiary-course-view {
    width: 100%;
    padding: 0 16rem;

    .course-edit-alert {
        background-color: #f8d7da;
        color: #721c24;
        padding: 10px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        border: 1px solid #f5c6cb;
        position: fixed;
        top: 0;
        width: 100%;
        left: 0;
      }
      
      .course-edit-alert .info {
        display: flex;
        align-items: center;
      }
      
      .course-edit-alert .info .tooltip-text {
        display: flex;
        align-items: center;
        margin-left: 10px;
        color: #0056b3;
        cursor: pointer;
        position: relative;
      }
      
      .course-edit-alert .info .tooltip-text:hover .tooltip-content {
        visibility: visible;
        opacity: 1;
      }
      
      .course-edit-alert .info .tooltip-content {
        visibility: hidden;
        width: 300px;
        background-color: #f8d7da;
        color: #721c24;
        text-align: left;
        border-radius: 5px;
        padding: 10px;
        position: absolute;
        z-index: 1;
        top: 38px; 
        left: -235px; 
        opacity: 0;
        transition: opacity 0.3s;
        border: 1px solid #f5c6cb;
      }
      
      .course-edit-alert .info .tooltip-content::after {
        content: "";
        position: absolute;
        top: -5px; 
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #f5c6cb transparent transparent transparent;
      }
      
      .course-edit-alert .info .tooltip-icon {
        display: flex;
        align-items: center;
        margin-left: 5px;
        font-size: 1.2em;
      }

    @media screen and (max-width: $lg) {
        padding: 0 0rem;
    }

    .beneficiary-course-view-header {
        display: flex;
        justify-content: space-between;
    }

    .beneficiary-course-view-ressouce {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;

        .message-lock {
            font-size: 12px;
            color: var(--text-secondary);
            background: var(--pure-white);
            box-shadow: 0 5px 10px 0 rgba(112, 130, 243, 0.08);
            align-items: center;
            padding-left: 5px;
            position: absolute;
            left: 150%;
            display: none;
            z-index: 100;
            width: 130px;
            border-radius: 8px;
            padding: 5px 10px;
        }
    
        .icon:hover + .message-lock {
          display: flex;
        }

        .tag-container {
            width: fit-content;
        }

        .title-container > span {
            color: var(--text-secondary)
        }
    }
}