.agenda-row-date {

  $agenda-row-date-default-color: var(--main-color) !default;
  $agenda-row-date-red-color: var(--red) !default;
  $agenda-row-date-orange-color: var(--orange) !default;
  $agenda-row-date-label-color: var(--text-secondary) !default;
  $border-radius: 3px;

 background-color: var(--pure-white);
  border-radius: $border-radius;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .top {
    width: 100%;
    height: 2rem;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;

    &[data-color="default"] {
      background-color: $agenda-row-date-default-color;
    }

    &[data-color="red"] {
      background-color: $agenda-row-date-red-color;
    }

    &[data-color="orange"] {
      background-color: $agenda-row-date-orange-color;
    }

    .month-label {
      color: white;
      font-size: 1.2rem;
      @extend .pro-semibold !optional;
    }
  }

  .bottom {
    width: 100%;
    display: flex;
    flex: 2;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .day-number {
      font-size: 2.0rem;
      @extend .pro-semibold !optional;

      &[data-color="default"] {
        color: $agenda-row-date-default-color;
      }

      &[data-color="red"] {
        color: $agenda-row-date-red-color;
      }

      &[data-color="orange"] {
        color: $agenda-row-date-orange-color;
      }
    }

    .day-label {
      font-size: 1.2rem;
      @extend .pro-semibold !optional;
      color: $agenda-row-date-label-color;
    }
  }

}
