$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

.consultant-my-beneficiaries-page {
  max-width: 100vw;

  header > .header {
    position: relative;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 4rem;
    column-gap: 1rem;

    > .big-combo {
      background-color: rgba(204, 204, 204, 0.20);
      padding-inline: 1rem;
      border-radius: 3px;
    }

    > .big-combo > .select-container > .Dropdown-root > .Dropdown-menu {
      margin-top: 0.4rem;
    }

    > .big-combo:active, .big-combo:focus {
      outline: 1px solid var(--main-color);
    }

    > .big-combo:hover {
      background-color: rgba(204, 204, 204, 0.35);
    }

    > .big-combo > .select-container > .Dropdown-root > .Dropdown-control {
      margin-bottom: 0;
    }

    > .top-search {
      padding-top: .6rem;
      height: 100%;
    }

    > .separator {
      background: var(--grey);
      height: 1px;
      flex-basis: 100%;
    }
  }

  .header-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .my-beneficiaries {
    min-height: 40vh;
    .filters {
      display: flex;
      gap: 40px;
      justify-content: start;
      
      @media screen and (max-width: $sm) { //Val
        justify-content: space-between;
        gap: 0;
      }
      margin: 24px 0;
      .big-combo {
        background-color: var(--pure-white);
        border-radius: 6px;
      }
    }
  }

  .info-page {
    margin-top: 1rem;
  }
}
