.list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  .row {
    height: auto;
    align-items: center;
    padding: 10px 20px;
    border: 2px solid #ccc;
    display: flex;
    flex-direction: col;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 5px;
    &:hover {
      background-color: #f5f5f5;
      border: 2px solid var(--main-color);
    }
    * {
      width: auto;
    }
  }
}
