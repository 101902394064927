.primary-button {
  background-color: var(--main-color);
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 30px;
  padding: 7px 3rem;
  user-select: none;
  border: 1px solid var(--main-color);
  color: white;
  background: var(--main-color);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
  border-radius: 3px;

  &:hover {
    cursor: pointer;
  }

  &:active {
    opacity: 0.75;
  }

  .icon {
    margin-right: 2rem;
    width: 15px;
    height: 15px;
  }

  .label {
    font-size: 1.4rem;
    color: white;
    white-space: nowrap;

    margin-right: auto;
    margin-left: auto;
  }

  &.outline {
    background: none;
    box-shadow: none;

    .label {
      color: var(--main-color)
    }
  }

  &.disabled {
    background:  var(--grey);
    color: white;
    border-color:  var(--grey);
    cursor: not-allowed;
    box-shadow: none;
  }
}
