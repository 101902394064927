.accomp-collective-current-job-card {
    background-color: var(--pure-white);
    box-shadow: 0 4px 6px 0 rgba(112, 130, 243, 0.08);
    padding: 12px;
    border-radius: 6px;

    .accomp-collective-current-job-card-content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 24px; 

        .accomp-collective-current-job-card-content-flex {
            display: flex;
            align-items: end;
            gap: 6px;

            span {
                font-weight: bold;
                font-size: 14px;
            }
        }
    }
}