@import '../../../stylesheets/common/fonts';

.group-card {
    background-color: var(--pure-white);
    border-radius: 6px;
    .group-card-header {
        border-bottom: 1px solid var(--grey-medium);
        padding: 12px;

    }

    .group-card-presentation-group {
        display: flex;
        flex-direction: column;
        gap: 6px;
        border-bottom: 1px solid var(--grey-medium);
        padding: 12px;
        cursor: pointer;

        .group-card-presentation-group-name {
            display: flex;
            align-items: center;
            gap: 12px;
            
            .avatar-group {
                height: 35px;
                width: 35px;
                border-radius: 50%;
                background-color: var(--main-color-lighter);
                display: flex;
                justify-content: center;
                align-items: center;

                .icon {
                    width: 14px;
                    height: auto;

                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            h1 {
                @extend .headline-1;
                font-size: 16px;
                color: var(--text-primary);
            }
        }
        

        p {
            @extend .secondary-default;
            font-size: 14px;
            color: var(--text-secondary)
        }

        .group-card-members-in-group {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .avatars-members-in-group {
                display: flex;
                .avatar-member-in-group {
                    height: 20px;
                    width: 20px;
                    border-radius: 50%;
                    background-color: var(--main-color-lighter);
                    border: 1px solid var(--main-color-light);
                    margin-right: -4px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .icon {
                        width: 9px;
                        height: auto;
                        svg {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                .avatar-more-members {
                    height: 20px;
                    width: 20px;
                    border-radius: 50%;
                    background-color: var(--main-color-lighter);
                    border: 1px solid var(--main-color-light);
                    margin-right: -4px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    
                    label {
                        @extend .tertiary-default;
                        font-size: 10px;
                        color: var(--main-color);
                    }
                }
            }
        }
    }

    .group-card-presentation-group:hover {
        background-color: var(--main-color-lighter);
    }

    .group-card-content {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid var(--grey-medium);
        gap: 12px;
        padding: 12px;

        label {
            @extend .secondary-default;
            text-transform: uppercase;
            color: var(--text-secondary);
            font-size: 14px;
        }

        p {
            @extend .primary-default;
            color: var(--text-primary);
            font-size: 14px;
            font-weight: 700;
        }
    }

    .group-card-delete {
        display: flex;
        justify-content: end;
        cursor: pointer;
        padding: 12px;
        p {
            color: var(--main-color);
            text-decoration: underline;
            font-size: 14px;
        }
    }
}