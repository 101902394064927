.quiz-question {
  $quiz-group-label-color: var(--text-primary-dark) !default;
  width: 100%;
  position: relative;
  
  > .content {
    padding-left: 4rem;
    margin-top: 2.5rem;
    display: flex;
    flex-direction: column;
  }
}
