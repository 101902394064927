@import '../../../stylesheets/common/fonts';

.agenda-side-bar {
  max-width: 100%;
  .agenda-side-bar-header,
  .agenda-side-bar-main-infos,
  .agenda-side-bar-consultant,
  .agenda-side-bar-participants,
  .agenda-side-bar-description,
  .agenda-side-bar-actions-appointment {
    padding: 12px 0;
  }
  .agenda-side-bar-main-infos,
  .agenda-side-bar-consultant,
  .agenda-side-bar-participants,
  .agenda-side-bar-description,
  .agenda-side-bar-actions-appointment {
    border-top: 1px solid var(--grey-3);
    label {
      @extend .label-uppercase;
      font-size: 12px;
      color: var(--text-secondary);
    }
  }

  .agenda-side-bar-header {
    display: flex;
    flex-direction: column;
    gap: 6px;

    .icon-back {
      color: var(--text-secondary);
      margin-bottom: 12px;
    }

    .agenda-side-bar-infos {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .agenda-side-bar-actions {
        display: flex;
        align-self: center;
        gap: 12px;
        cursor: pointer;

        svg {
          width: 16px;
          height: 16px;
        }
        svg > * {
          stroke: var(--grey);
        }
      }
    }

    h1 {
      @extend .headline-2;
      color: var(--text-primary);
      font-size: 18px;
    }
  }

  .agenda-side-bar-main-infos {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .agenda-side-bar-main-info {
      display: flex;
      align-items: center;
      gap: 12px;
      p.infos-bold {
        @extend .secondary-strong;
        color: var(--text-primary);
      }

      a {
        @extend .secondary-link;
        color: var(--main-color);
        text-decoration: underline;
      }

      p {
        cursor: pointer;
        color: var(--main-color);
        text-decoration: underline;
      }
    }
  }

  .agenda-side-bar-consultant {
    display: flex;
    flex-direction: column;

    label {
      margin-bottom: 9px;
    }
    .agenda-side-bar-consultant-info {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .agenda-side-bar-consultant-name {
        display: flex;
        justify-content: center;
        align-self: center;
        gap: 6px;

        img {
          width: 25px;
          height: 25px;
          background-image: none;
          background-color: var(--main-color);
          border-radius: 50%;
        }

        p {
          color: var(--text-primary);
        }

        .avatar-circle {
          width: 25px;
          height: 25px;
          border-radius: 50%;

          .picture {
            width: 25px;
            height: 25px;
            border-radius: 50%;
          }

          .no-picture {
            background-color: var(--main-color-lighter);
            width: 25px;
            height: 25px;

            .icon {
              display: flex;
              justify-content: center;
              align-items: center;

              svg {
                width: 12px;
                height: 12px;
              }
            }
          }
        }
      }

      svg {
        width: 16px;
        height: 16px;
      }
      svg > * {
        stroke: var(--grey);
      }
    }
  }

  .agenda-side-bar-participants {
    display: flex;
    flex-direction: column;
    gap: 6px;

    .agenda-side-bar-participants-title {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 9px;
      .agenda-side-bar-total-participants {
        width: 15px;
        height: 15px;
        background-color: var(--grey);
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          @extend .quaternary-strong;
          font-size: 9px;
          color: var(--pure-white);
        }
      }
    }

    .agenda-side-bar-all-participants {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .agenda-side-bar-participant {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .agenda-side-bar-participant-name {
          display: flex;
          justify-content: center;
          align-self: center;
          align-items: center;
          gap: 6px;

          img {
            width: 18px;
            height: 18px;
            background-image: none;
            background-color: var(--main-color);
            border-radius: 50%;
          }

          p {
            color: var(--text-primary);
          }

          .avatar-circle {
            width: 18px;
            height: 18px;
            border-radius: 50%;

            .picture {
              width: 18px;
              height: 18px;
              border-radius: 50%;
            }

            .no-picture {
              background-color: var(--main-color-lighter);
              width: 18px;
              height: 18px;

              .icon {
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                  width: 10px;
                  height: 10px;
                }
              }
            }
          }
        }

        .agenda-side-bar-participant-actions {
          display: flex;
          align-items: center;
          gap: 12px;

          .send-invitation-button {
            cursor: pointer;
            border: 1px solid var(--main-color);
            border-radius: 3px;
            background-color: transparent;
            color: var(--main-color);
            font-size: 12px;
          }

          .trash2 {
            cursor: pointer;
          }
          svg {
            width: 16px;
            height: 16px;
          }
          svg > * {
            stroke: var(--grey);
          }
        }
      }
    }
  }

  .agenda-side-bar-actions-appointment {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;

    .cancel-appointment-button {
      .primary-button {
        width: 100%;
        background-color: var(--red);
        border: 1px solid var(--red);
      }
    }
    .warning-message {    position: relative;
        bottom: -45px;
        right: 17px;
    }

    .tooltip {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-left: 5px;
      color: var(--main-color);
      position: relative;
    }

    .tooltip-text {
      visibility: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 300px;
      background-color: #f8d7da;
      border: 1px solid #f5c6cb;
      color: #721c24;
      text-align: center;
      border-radius: 6px;
      padding: 5px;
      position: absolute;
      z-index: 1;
      bottom: 140%;
      right: 10%;
      opacity: 0;
      transition: opacity 0.3s;
    }

    .tooltip:hover .tooltip-text {
      visibility: visible;
      opacity: 1;
    }
  }
}

.agenda-tallysheet-pdf {
  width: 100%;
  padding: 20px;

  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }

  th,
  td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  th {
    background-color: #f2f2f2;
  }
}
