$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

.manager-statistics-beneficiaries-page {
  display: flex;
  flex-direction: column;
  flex: 1;

  .statsTable,
  .statsChart {
    margin-top: 2rem;
  }

  .charts-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    @media screen and (max-width: $md) {
      flex-direction: column;
    }

    .statsChart.by-gender {
      flex-basis: 32%;
    }

    .statsChart.by-formation {
      flex-basis: 65%;
    }

    .statsChart.by-financer {
      flex-basis: 100%;
    }
  }
}
