.consultant-beneficiary-aside {
  display: flex;
  flex-direction: column;

  > .shared-notes:first-child {
    .shared-note-header {
      height: 3.4rem;
      padding-top: 0.4rem;
    }
  }
}
