.quiz-stars {
  $quiz-star-label-color: var(--text-primary-dark) !default;
  $quiz-star-star-color: var(--main-color) !default;

  width: 100%;
  position: relative;

  .step {
    // position: absolute;
    top: 0;
    left: 0;
  }

  > .label {
    @extend .pro-regular !optional;
    font-weight: normal;
    font-style: normal;
    display: inline-block;
    // color: $quiz-star-label-color;
    font-size: 1.8rem;
  }

  > .stars-container {
    padding-left: 4rem;
    margin-top: 1.8rem;

    .star-container {
      .icon {
        margin-right: 1.5rem;
        cursor: pointer;
        width: 2.3rem;
        display: inline-block;
      }

      &.stars-active {
        .icon {
          svg path {
            fill: $quiz-star-star-color;
          }
        }
      }
    }
  }

}
