$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

.beneficiary-accompaniment {

    display: flex;
    flex-direction: column;
    gap: 24px;

    .beneficiary-outplacement-individual, .beneficiary-accompaniment-collective, .beneficiary-collective-scheme {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 24px;

        @media screen and (max-width: $lg) {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}