
$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

.beneficiary-course-page {
  .beneficiary-course-page-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: inherit;
    height: initial;
    gap: 25px;
    @media screen and (max-width: $lg) {
      flex-direction: column;
    }
  }
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break-always {
    margin-top: 1rem;
    display: block;
    break-before: always;
  }
  .page-break {
    margin-top: 1rem;
    display: block;
    break-before: auto;
  }
  .beneficiary-parcours-right-container {
    position: initial !important;
  }
}

@page {
  size: auto;
  margin: 20mm;
}

.course-edit-alert {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  border: 1px solid #f5c6cb;
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
}

.course-edit-alert .info {
  display: flex;
  align-items: center;
}

.course-edit-alert .info .tooltip-text {
  display: flex;
  align-items: center;
  margin-left: 10px;
  color: #0056b3;
  cursor: pointer;
  position: relative;
}

.course-edit-alert .info .tooltip-text:hover .tooltip-content {
  visibility: visible;
  opacity: 1;
}

.course-edit-alert .info .tooltip-content {
  visibility: hidden;
  width: 300px;
  background-color: #f8d7da;
  color: #721c24;
  text-align: left;
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  top: 38px; 
  left: -235px; 
  opacity: 0;
  transition: opacity 0.3s;
  border: 1px solid #f5c6cb;
}

.course-edit-alert .info .tooltip-content::after {
  content: "";
  position: absolute;
  top: -5px; 
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #f5c6cb transparent transparent transparent;
}

.course-edit-alert .info .tooltip-icon {
  display: flex;
  align-items: center;
  margin-left: 5px;
  font-size: 1.2em;
}