.beneficiary-form-module-page {
  .popin-layout {
    section {
      display: flex;
      flex-direction: column;
      align-items: center;

      .questions {
        width: 60rem;

        > div, .quiz-group > .content > div {
          margin-top: 4rem;

          &:first-child {
            margin-top: 0;
          }
        }

        > .actions {
          > .primary-button {
            width: 100%;
          }
        }
      }
    }
  }

  // @include mobile() {
  //   .popin-layout {

  //     > section {
  //       .questions {
  //         width: 100%;

  //         > div, .quiz-group > .content > div {
  //           margin-top: 2rem;
  //         }

  //         > .actions {
  //           padding-left: 0.5rem;
  //           padding-right: 0.5rem;
  //         }
  //       }
  //     }
  //   }
  // }

  @media print {
    .popin-layout {

      > section {
        display: block;
        margin: 0;

        .questions {
          width: 100%;

          > div, .quiz-group > .content > div {
            margin: 0;
          }

          > .actions {
            display: none;
          }
        }
      }
    }
  }
}
