.shared-notes {
  width: 100%;

  //.shared-note-header {
  //  height: 4rem;
  //  display: flex;
  //
  //  .icon-button {
  //    //flex: 1;
  //
  //  }
  //}

  .text-container {
    width: 100%;
    overflow: hidden;
    height: 40rem;
    max-height: 0;
    border-bottom: none;

    &.expanded {
      max-height: 100vh;
      border-bottom: 1px solid var(--grey);
    }

    > .notes {
      height: 100%;

      padding: 1.5rem 2.5rem;
      color: var(--text-primary-dark);
      font-size: 1.2rem;
      @extend .pro-regular !optional;
      outline: none;
      white-space: pre-wrap;
      overflow-y: auto;
    }

    > .text-area-field {
      height: 100%;

      padding: 1.5rem 2.5rem;

      > .textarea-container {
        > textarea {
          border: 0;
          padding: 0;

          color: var(--text-primary-dark);
          font-size: 1.2rem;
          @extend .pro-regular !optional;
          outline: none;
        }
      }
    }
  }
}
