.icon-button {
  display: flex;

  svg {
    // min-width: 1rem;
    // min-height: 1rem;
    width: 1.5rem;
    height: 1.5rem;
  }

  &:hover {
    cursor: pointer;
  }

  &:active {
    opacity: 0.75;
  }

  .tooltip {
    @extend .pro-regular !optional;
    background-color: rgba(58, 58, 58, 0.38) !important;

    &:after {
      border-top-color: rgba(58, 58, 58, 0.38) !important;
    }
  }
}
