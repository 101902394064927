.coord-card {
  display: flex;
  flex-direction: column;
  background-color: var(--pure-white);
  box-shadow: 0 4px 6px 0 rgba(112, 130, 243, 0.08);
  padding: 12px;
  border-radius: 6px;
  height: fit-content;

  > .form {
    padding: 12px;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
  }

  .fields {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;

    .fields-flex {
      display: flex;
      justify-content: space-between;
      gap: 24px;

      .field-container {
        width: 100%;
      }
    }
  }

}
