.parcours-quiz {

    .parcours-quiz-content {
        margin: 24px 0;
    }

    .primary-button {
        text-align: center;
    }

    .validation-btn {
        text-align: center;
        display: flex;
        justify-content: center;
    }
}       
       
       
       // .form {
        //     .question {
        //         display: flex;
        //         align-items: center;
        //         gap: 21px;

        //         h1 {
        //             @extend .headline-1 !optional;
        //             font-size: 24px;
        //         }
        //     }

        //     .options-questions {
        //         border: 1px solid var(--grey-medium);
        //         display: flex;
        //         align-items: center;
        //         gap: 12px;
        //         padding: 6px 12px 6px 12px;
        //         margin: 36px 0;
        //         border-radius: 6px;
        //         cursor: pointer;

        //         p {
        //             @extend .secondary-default !optional;
        //             font-size: 14px;
        //         }
        //     }

        //     .validate {
        //         display: flex;
        //         justify-content: center;
        //     }
        // }