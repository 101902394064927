.attribute-course-popin {
  > .parcours-attrib-popin {
    width: 80rem;
    height: 52rem;
  }

  .attribute-course-popin-btn {
    display: flex;
    justify-content: center;
  }
}
