@import '../../../stylesheets/common/fonts';

.talent-client-card {
    background-color: var(--pure-white);
    border-radius: 6px;
    display: flex;
    padding: 14px;
        border-bottom: 1px solid var(--grey-3);
        display: flex;
        // align-items: center;
        justify-content: space-between;

    .talent-client-card-header {
        // padding: 12px;
        padding-bottom: 5px;
        border-bottom: 1px solid var(--grey-3);
        display: flex;
        align-items: center;
        gap: 12px;
        .icon {
            width: 18px;
            height: 18px;
            cursor: pointer;
        }
    }

    .talent-client-card-content {
        // padding: 12px;
        // padding-left: 12px;
        // padding-bottom: 12px;

        display: flex;
        flex-direction: column;
        gap: 6px;

        .talent-informations {
            display: flex;
            align-items: center;
            gap: 12px;

            h1 {
                @extend .headline-1;
                font-size: 14px;
                color: var(--text-primary)
            }

            .avatar {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: var(--main-color-lighter);
                display: flex;
                justify-content: center;
                align-items: center;

                .icon {
                    width: 12px;
                    height: auto;
                }
            }
        }


        p {
            @extend .primary-default;
            color: var(--text-secondary);
            color: var(--text-secondary);
            font-size: 14px;
        }
    }
    
}