.parcours-module-content-file-return-consultant {
  $parcours-module-content-file-return-consultant-color: var(--main-color) !default;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  //padding: 12px;
  //background-color: white;

  > .consultant {
    display: flex;
    align-items: center;
    width: 48%;

    .text {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      flex: 1;

      .label {
        width: 100%;
        color:var(--text-secondary);
        font-size: 1.2rem;
        @extend .pro-regular !optional;
        margin-bottom: 5px;
      }

      [class^="icon-resource-type-"] {
        width: 2rem;
        height: auto;

        .icon {
          height: 12px;
          margin-right: 5px;

          svg {
            display: block;
          }
        }
      }

      .file-name {
        text-decoration: none;
        font-size: 1.4rem;
        color: $parcours-module-content-file-return-consultant-color;
        @extend .pro-semibold !optional;
      }
    }

  }

  > .separator {
    background-color: var(--grey);
    width: 1px;
    height: 25px;
  }

  > .beneficiary {
    width: 48%;

    display: flex;
    align-items: center;

    .text {
      display: flex;
      flex-wrap: wrap;
      flex: 1;

      .label {
        width: 100%;
        color:var(--text-secondary);
        font-size: 1.2rem;
        @extend .pro-regular !optional;
        margin-bottom: 5px;
      }

      .file-name {
        font-size: 1.4rem;
        color: $parcours-module-content-file-return-consultant-color;
        @extend .pro-semibold !optional;

        > a {
          color: $parcours-module-content-file-return-consultant-color;
          text-decoration: none;
        }

        .trait {
          display: block;
          height: 1px;
          background-color: var(--main-color);
          width: 10px;
          margin: 8px 0;
        }
      }
    }

  }

}
