.beneficiary-export-course-page {

  .left-aside-layout aside,
  .icon-button.chevron-up,
  *[class^="icon-prog-state-"],
  .parcours-module-container > .header .actions,
  .parcours-step-quiz .primary-button {
    display: none;
  }

  .course-layout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    > .back-button {
      display: none;
    }

    > .beneficiary-info {
      display: none;

      @media print {
        display: block;
        color-adjust: exact !important;
        -webkit-print-color-adjust: exact !important;
      }
      padding: 10px;
      color: black !important;
    }

    > .office-logo {
      max-width: 64px;
      aspect-ratio: 1/1;
    }
  }

  .cover-photo {
    width: calc(100% + (2.5rem * 2));
    position: relative;
    //margin: -2.5rem -2.5rem 1.5rem -2.5rem;
    height: 15rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @media print {
      -webkit-print-color-adjust: exact !important;
    }
  }

  .course-content {
    margin-left: 2.8rem;

    &-list {
      padding: 0;
      list-style: none;
    }

    &-list-item {
      margin-bottom: 1rem;
    }
  }

  .course-count {
    display: flex;
    margin: 0.5rem 0 2rem;

    > div {
      font-size: 1.4rem;

      > span {
        font-size: 1.2rem;
        color:var(--text-secondary);
        text-transform: uppercase;
      }

      &:first-child {
        margin-right: 3.5rem;
      }
    }
  }

  .course-description {
    @extend .pro-regular !optional;
    font-size: 1.2rem;
    line-height: 1.4rem;
    padding-bottom: 1.2rem;
    margin-bottom: 2.7rem;
    border-bottom: 1px solid var(--grey);
  }

  .parcours-step-quiz {
    margin-bottom: 1rem;
  }

  .parcours-module-container {
    margin-bottom: 1rem;
  }

  .parcours-module-container .actions {
    display: flex;
    justify-content: space-between;
    flex: 1;
  }

  .action-item:first-child {
    .rdv-at {
      display: flex;
    }
  }

  .rdv-at {
    align-items: center;
    border-left: 1px solid var(--grey);
    padding-left: 2rem;
    margin: 0 auto;

    .rdv-info {
      margin-right: 1.3rem;
    }
  }

  .module-row-content .label {
    color: var(--main-color);
  }

  .text .children {
    margin-bottom: 0;
    padding-left: 3rem;
  }

  .action-item:first-child {
    flex: 1;
  }

  // @include mobile() {
  //   .burger-menu {
  //     display: none;
  //   }

  //   .course-layout {
  //     > .back-button {
  //       position: absolute;
  //       top: 2.1rem;
  //       left: 1.1rem;
  //       display: block;
  //     }
  //   }

  //   .cover-photo {
  //     width: auto;
  //     margin-right: -1rem;
  //     margin-left: -1rem;
  //   }

  //   .course-content {
  //     margin-left: 2.1rem;

  //     > * {
  //       margin-bottom: 3rem;
  //     }
  //   }
  // }
}


