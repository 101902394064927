.icon-checkbox {
  cursor: pointer;

  .checkbox-inner-rectangle {
    fill: var(--main-color);
  }

  .checkbox-outer-rectangle {
    stroke: var(--main-color);
  }

}

.icon-checkbox.selected {
  fill: var(--main-color);
  stroke: var(--main-color);

  rect {
    stroke: var(--main-color);
  }
}

.icon-checkbox.unselected {
  stroke: var(--main-color);
}