.page {
  @extend .pro-regular !optional;
  display: flex;
  min-height: 100vh;
  height: 100vh;

  > * {
    display: flex;
    flex: 1
  }

  @media print {
    min-height: auto;
  }
}
