.ressource-document {
    margin: 12px 0;
    display: flex;
    flex-direction: column;
    gap: 3px;

    .ressource-document-header {
        display: flex;
        align-items: center;
        gap: 12px;
        
            h1 {
                @extend .headline-1 !optional;
                font-size: 24px;
            }
    }

    .ressource-document-to-download {
        border: 1px solid var(--main-color);
        background: var(--main-color-lighter);
        display: flex;
        align-items: center;
        padding: 24px 12px;
        border-radius: 6px;
        margin-top: 12px;
        gap: 6px;

        a {
            color: var(--main-color);
            text-decoration: underline;
        }
    }

    .reading-time {
        @extend .secondary-default !optional;
        color: var(--text-secondary);
        font-size: 16px;
        line-height: 21px;
    }

    .ressource-document-container {
        margin: 24px;
        
        .description {
            @extend .primary-default !optional;
            font-size: 16px;
            line-height: 24px;
            margin-top: 24px;
        }
    }
    
        
            iframe {
                border: 0;
            }
        
            iframe.file, iframe.video {
                display: flex;
                justify-content: center;
                width: 100%;
                height: 500px;
            }
    .link {
        margin-top: 24px;
        display: flex;
        gap: 6px;

        a {
            @extend .primary-strong !optional;
            font-size: 18px;
            line-height: 21px;
            color: var(--text-primary)
        }

        a:hover {
            text-decoration: underline;
        }

        svg {
            width: 14px;
            height: auto;
        }


    }

}


.file {
    iframe {
            border: none;
            width: 100%;
    }
}

iframe {
    border-radius: 6px;
    margin-top: 12px;
    height: 70vh;
}