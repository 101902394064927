.right-aside-layout {
  flex: 1;
  display: flex;
  flex-direction: row;

  > section {
    flex: 1;
    display: flex;
    flex-flow: column;
    padding: 2rem 4rem;
    background: var(--grey-light);
  }

  > aside {
    display: flex;
    flex-flow: column;
    width: 23rem;
    padding: 1rem 0;
    background: var(--pure-white);
    padding: .6rem;
  }
}
