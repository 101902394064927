$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

.candidat-card {
  box-shadow: 0 4px 6px 0 rgba(112, 130, 243, 0.08);
  background: white;
  width: 300px;
  border-radius: 6px;
  @media screen and (max-width: $sm) {
    width: 100%;
  }

  > .header {
    display: flex;
    align-items: center;
    min-height: 50px;
    padding: 0 6px;

    .status {
      @extend .secondary-default !optional;
      color: var(--main-color);
      font-size: 1.2rem;
      background-color: var(--main-color-lighter);
      border-radius: 24px;
      padding: 3px 12px;
    }

    .tools {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex: 1;

      .consultants {
        display: flex;
        align-items: center;
        margin-right: 1.6rem;

        .avatar-circle {
          height: 2rem;
          width: 2rem;
        }

        .avatar-wrapper {
          position: relative;
          margin: -0.6rem;
        }
      }

      > .primary-button {
        padding: 0 1rem;
        margin: 1rem;

        .label {
          font-size: 1.2rem;
        }
      }

      .button-wrap {
        position: relative;

        .red-dot {
          background: var(--red);
          height: 0.7rem;
          width: 0.7rem;
          border-radius: 100%;
          position: absolute;
          right: 0.8rem;
          top: 0;
        }
      }

      .icon-button {
        margin-right: 1rem;
        width: 1.3rem;
        height: 1.3rem;
      }
    }
  }

  .candidat-infos {
    cursor: pointer;
    border-top: 1px solid var(--grey-medium);
    padding: 12px;
    align-items: center;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    &:hover {
      background: var(--main-color-lighter);
    }

    .avatar-circle {
      width: 52px;
      height: 52px;
      border-radius: 50%;

      .picture {
        width: 52px;
        height: 52px;
        border-radius: 50%;
      }

      .no-picture {
        background-color: var(--grey-light);
        width: 52px;
        height: 52px;

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    .candidat-infos-name {
      margin-bottom: 12px;
      .name {
        @extend .primary-strong !optional;
        font-size: 1.4rem;
        color: var(--text-primary-dark);
        text-align: center;
      }

      .contact {
        @extend .secondary-default !optional;
        font-size: 1.2rem;
        color: var(--text-primary-dark);
        text-align: center;
      }
    }
  }
  .action-buttons {
    display: flex;
    justify-content: end;

    p {
      cursor: pointer;
      @extend .tertiary-link !optional;
      font-size: 12px;
      padding: 6px;
      color: var(--main-color);
      text-decoration: underline;
    }
  }

  .content-card {
    padding: 1.5rem 1.4rem;
    background: white;
    border-top: 1px solid var(--grey-medium);
    display: flex;
    flex-direction: column;
    gap: 12px;
    .info-field .light-label {
      text-transform: uppercase;
    }

    .bold-label {
      font-size: 1.2rem;
    }

    .course-progress {
      align-items: center;

      .info-field {
        margin-bottom: 6px;
      }

      .pourcentage-done-course {
        padding: 0;
      }
    }
  }

  .candidat-info {
    display: block;
    font-size: 1.2rem;
    color: var(--orange);
  }

  .in-progress .info-field {
    margin-bottom: 1rem;
    flex: 1;
  }
}
