.quiz-yes-no {
  width: 100%;

  .buttons {
    display: flex;

    margin-top: 3rem;
    padding-left: 3rem;

    .primary-button {
      margin: 0 1rem;
      width: 12.2rem;
      outline: none !important;
    }

    @media print {
      .primary-button {
        background-color: var(--main-color);
        -webkit-print-color-adjust: exact !important;
        color-adjust: exact !important;

        &.outline {
          background-color: transparent !important;
        }
      }
    }
  }

  &.disabled {
    .buttons {
      .primary-button {
        &:hover {
          cursor: default;
        }
      }
    }
  }

  // @include mobile() {
  //   .buttons {
  //     margin-top: 2rem;
  //     padding-left: 2rem;
  //   }
  // }
}
