.tag-container {
    display: flex;
    justify-content: center;
    gap: 6px;
    border-radius: 24px;
    align-items: center;
    padding: 3px 12px 3px 12px;

    .icon > svg {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 12px;
        width: auto;

        g {
            stroke: var(--orange);
        }
    }

    label {
        @extend .tertiary-default !optional;
        font-size: 12px;
        line-height: 18px;
        text-wrap: nowrap;
    }
}