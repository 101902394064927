$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;


.beneficiary-maestro-container {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 24px;
    flex-flow: row wrap;
    // flex-wrap: ;
    .button-job {

        flex: 1 0 ;
        min-width: 500px;
        min-height: 200px;
        height: 200px;
        border-radius: 10px;
        border: none;
        background-color: var(--pure-white);
        display: flex;
        padding: 0;
        overflow: hidden;

        @media screen and (max-width: $xxl) {
            flex: 1 0 calc(50% - 10px);
            max-width: calc(100%);
            min-width: unset;
        }

        .illustration {
            // width: calc(100% / 2);
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            aspect-ratio: 1;
            @media screen and (max-width: $sm) {
                display: none;
            }

    
        }

        .illu-offers {
            background: var(--red-medium);
        }

        .illu-jobs {
            background: var(--orange-medium);
        }

        .job-info {
            width: 100%;
            height: 100%;
            padding: 12px 22px;

            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .title-div-job {
                display: flex;
                flex-direction: column;
                gap: 18px;

                h4 {
                    color: var(--text-primary);
                    font-weight: 700;
                    font-size: 22px;
                }

            }

            .description-job {
                color: var(--text-secondary);
            }


            .primary-button {
                padding: 4px 32px;
                width: fit-content;
                height: fit-content;
                background-color: var(--pure-white);
                border: 1px solid var(--main-color);
                border-radius: 5px;
                font-weight: 700;
                color: var(--main-color);
                cursor: pointer;

                .label {
                    color: var(--main-color);
                }
            }
        }
    }
}