$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

.course-template-list {

  .course-templates {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 0.25fr));
    gap: 24px;
    margin: 24px 0;
    justify-content: start;
    @media screen and (max-width: $md) {
      justify-content: center;
    }

    .parcours-card {
      flex: 1 0 100%;
      margin-bottom: 20px;

      @media screen and (min-width: $md) {
        flex-basis: calc(33.33% - 20px);

        &:nth-child(3n) {
          margin-right: 0;
        }
      }

      @media screen and (min-width: $lg) {
        flex-basis: calc(25% - 20px);
      }
    }
  }
}