@import "../../../stylesheets/common/fonts";

.module-row {
  .module-row-header {
    display: flex;
    align-items: center;
    width: 100%;
    background: var(--pure-white);
    border-bottom: 1px solid var(--grey-medium);

    .left-wrapper {
      display: flex;
      align-items: center;
      margin-left: 2rem;

      .icon-button {
        width: 1.2rem;
        height: 1.2rem;

        .icon {
          display: flex;

          &.chevron-down {
            transform: rotate(-90deg);
          }

          &.chevron-up {
            transform: rotate(180deg);
          }
        }
      }

      .label {
        @extend .primary-default;
        font-size: 16px;
        margin-left: 1.2rem;
        color: var(--text-primary);
        white-space: nowrap;
        cursor: pointer;
        text-decoration: none;
      }

      > [class^='icon-module-type-'] {
        width: auto;
        height: auto;

        .icon {
          width: 15px;
          height: 15px;
        }
      }

      > [class^='icon-module-type-'] .icon {
        margin: 1.5rem 1rem 1.5rem 2rem;
      }
    }

    .right-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      
      .resources-count {
        @extend .primary-default;
        padding: 12px;
        color: var(--text-primary);
        font-size: 16px;
        font-weight: 700;
      }

      .no-resources-count {
        padding: 24px;
      }

      .actions {
        display: flex;
        margin: auto 2rem;

        .icon-button {
          margin-left: 1.5rem;
        }

        .icon {
          width: 1.5rem;
        }
      }
    }
  }

  > .options {
    padding-left: 7rem;
    background: var(--background-color-2);

    .module-row-content {
      padding: 0.7rem 0;
    }
  }
}
