.parcours-document-to-return {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .description {
        color: var(--text-secondary);
    }

    .document-to-download {
        background-color: var(--pure-white);
        padding: 12px 24px;
        border-radius: 12px;
        cursor: pointer;

        span {
            color: var(--text-secondary)
        }

        .download-container {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .download {
                display: flex;
                gap: 6px;
                align-items: center;

                .ressource-icon>svg {
                    display: flex;
                    width: 15px;
                    height: 15px;
                }

                a {
                    text-decoration: none;
                    color: var(--text-primary);
                }

                a:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .document-to-upload {
        display: flex;
        flex-direction: column;
        gap: 6px;
        border-radius: 12px;

        .upload-container {
            border: 1px dashed var(--main-color);
            background-color: var(--main-color-lighter);
            padding: 24px 0;
            display: flex;
            justify-content: center;
        }
    }

    .document-uploaded {
        border: 1px solid black;
        background-color: var(--pure-white);
    }
}