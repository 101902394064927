$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

.consultant-beneficiary {
  .share-container {
    display: flex;
    align-items: center;

    .consultants {
      display: flex;
      align-items: center;
      margin-right: 1.6rem;

      // .avatar-circle {
      //   height: 2rem;
      //   width: 2rem;
      // }

      .avatar-circle {
        width: 36px;
        height: 36px;
        border-radius: 50%;

        .picture {
          width: 36px;
          height: 36px;
          border-radius: 50%;
        }

        .no-picture {
          background-color: var(--grey-light);
          width: 36px;
          height: 36px;

          .icon {
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
              width: 18px;
              height: 18px;
            }
          }
        }
      }

      .avatar-wrapper {
        position: relative;
        margin: -0.4rem;
      }
    }
  }

  .popin-layout {
    height: 100vh;
    width: 100vw;
    
    > header {
      position: relative;
      top: 0;
      left: 0;
      z-index: 500;
      .action-popin-header {
        background-color: var(--pure-white);
        
        @media screen and (max-width: $md) {
          height: auto;
          padding-bottom: 10px;
          padding-top: 10px;
          gap: 10px;

        }
        .resources-burger {
          display: none;
          
          @media screen and (max-width: $md) { //Val
            display: flex;
            cursor: pointer;
          }
        }
        > .actions-part {
          @media screen and (max-width: $md) {
            width: 100%;
            justify-content: center;
            
          }
          .label {
            @media screen and (max-width: $md) {
              font-size: 12px;
            }
          }
          > .statuses_combo {
            margin-left: 9.5rem;
            @media screen and (max-width: $md) {
              display: none;
            }
          }
        }

        .label {
          @media screen and (max-width: $md) {
            margin: 0;
          }
          .light {
            color: black;
          }

          .bold {
            font-weight: 700;
            color: black;
            @media screen and (max-width: $md) {
              text-align: center;
            }
          }
        }
      }
    }

    > section {
      padding: 0;
      overflow: auto;
      margin-right: 23rem;
      @media screen and (max-width: $md) {
        margin-right: 0rem;
      }

      .right-aside-layout {
        padding-top: 0.5rem;

        > section {
          padding-top: 0;
          width: 100%;
        }

        > aside {
          height: calc(100vh - 6rem);
          position: fixed;
          right: 0;
          @media screen and (max-width: $md) {
            display: none;
          }
        }
      }
    }
  }
  .action-mobile-burger {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: var(--grey-light);
    z-index: -1;
    top: 0;
    left: -100%;
    display: none;
    padding: 4rem;
    padding-top: 100px;
    transition: left 300ms ease-in-out;
    @media screen and (max-width: $md) {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
  }
}
