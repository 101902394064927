.dropdown-colors {
    width: fit-content;
    position: relative;
    .dropdown-colors-toggle {
        display: flex;
        padding: 6px 0;
        align-items: center;
        gap: 24px;
        cursor: pointer;

        .selected-color-theme {
            display: flex;
            align-items: center;
            gap: 12px;
        }
    }

    .dropdown-colors-options {
        position: absolute;
        bottom: 42px;
        left: 0;
        width: 100%;
        min-width: 150px;
        border: 1px solid var(--grey-light);
        margin: 0;
        padding: 0;
        background-color: var(--pure-white);

        li {
            list-style: none;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 12px;
            padding: 6px 12px;
            border-radius: 3px;
            width: 100%;
        }

        li:hover {
            background-color: var(--grey-light);
        }
    }

    .option-color {
        width: 34px;
        height: 18px;
        border-radius: 3px;
    }
}