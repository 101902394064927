@import '../../../../stylesheets/common/fonts';

.report-appointment-popin {
    display: flex;
    flex-direction: column;
    gap: 6px;

    .appointment-popin-section-title {
        display: flex;
        align-items: center;
        gap: 12px;

        p {
            @extend .primary-strong;
            color: var(--text-primary);
            font-size: 16px;
        }
    }

    textarea {
        width: 100%;
        resize: none;
        height: 100px;
        overflow: scroll;
        border-radius: 6px;
        border: 1px solid var(--grey);
        outline: none;
    }
}