.logout-button {
  @extend .pro-regular !optional;
  display: flex;
  cursor: pointer;

  .label {
    font-size: 1.4rem;
    color: var(--text-secondary);
    display: inline-block;
    text-transform: uppercase;
  }

  .icon {
    margin-right: 10px;
    width: 18px;
    height: 18px;
    display: inline-block;
    vertical-align: center;
  }

  &:active {
    opacity: 0.75;
  }
}
