.quiz-qcm-row {
  $quiz-qcm-row-label-default-color: var(--text-primary-dark);
  $quiz-qcm-row-label-active-color: var(--main-color);
  $quiz-qcm-row-border-default-color: var(--grey-medium);
  $quiz-qcm-row-border-active-color: var(--main-color);

  border: 1px solid $quiz-qcm-row-border-default-color;
  border-radius: 3px;
  background-color: transparent;
  padding: 12px;
  margin-bottom: 10px;
  cursor: pointer;
  min-height: 3rem;

  &.active {
    border: 1px solid $quiz-qcm-row-border-active-color;
    border-radius: 6px;

    > .quiz-step {
      > .label {
        color: $quiz-qcm-row-label-active-color;
      }
    }
  }

  &.disabled {
    cursor: default;
  }

  .quiz-step {
    padding-left: 0;
    display: flex;
    align-items: center;

    .step {
      display: inline-block;
      margin-right: 10px;
      position: relative;
    }
  }
}
