$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

.quiz-step {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;

  > .step {
    background-color: var(--main-color);
    color: var(--pure-white);
    border: none;
  }

  > .label {
    @extend .pro-semibold !optional;
    font-size: 24px;
    color: var(--text-primary);
    @media screen and (max-width: $md) {
      font-size: 16px;
    }
  }

  > .content {
    margin-top: 2.5rem;
  }
}
