.disabled-consultant-card {
  $consultant-card-labels-color: var(--text-primary-dark) !default;
  $consultant-card-beneficiary-label-color: var(--text-secondary) !default;
  box-shadow: 0 2px 4px 0 rgba(112, 130, 243, 0.08);
  border-radius: 3px;
  background-color: var(--pure-white);
  
  display: flex;
  flex-direction: column;

  > .top-container {
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 36px;
    cursor: pointer;

    > .avatar-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    > .informations {
      display: inline-block;
      vertical-align: top;
      color: $consultant-card-labels-color;

      > .fullname {
        font-size: 1.4rem;
        @extend .pro-semibold !optional;
        margin-bottom: 1px;
      }

      > .small-informations {
        font-size: 1.2rem;
        @extend .pro-light !optional;

        > .entry {
          margin-bottom: 1px;
        }

        margin-bottom: 13px;
      }

      > .icon-button {
        svg {
          width: 15px;
          height: 15px;
          min-height: 15px;
          min-width: 15px;
        }
      }
    }
  }

  > .bottom-container {
    border-top: 1px solid var(--grey-medium);
    padding: 10px 20px 18px;
  
  
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
