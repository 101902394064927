:root {
  // MyTalents color theme
  --mytalents-theme: #5066EF;
  --mytalents-dark-theme: #3048DE;
  --mytalents-light-theme: #B5BFFF;
  --mytalents-lighter-theme: #F5F7FF;
  --mytalents-grey-dark-theme: #17215A;

  // Yellow color theme
  --yellow-theme: #FFD569;
  --yellow-dark-theme: #F3C13F;
  --yellow-light-theme: #FFE7A9;
  --yellow-lighter-theme: #FFF5DB;
  --yellow-grey-dark-theme: #241B03;

  // Orange color theme
  --orange-theme: #FF8920;
  --orange-dark-theme: #C26715;
  --orange-light-theme: #FFC48F;
  --orange-lighter-theme: #FFF9F4;
  --orange-grey-dark-theme: #241303;

  // Green color theme
  --green-theme: #1AC2AE;
  --green-dark-theme: #136B61;
  --green-light-theme: #27E0CF;
  --green-lighter-theme: #EAF5F4;
  --green-grey-dark-theme: #042C27;

  // Red color theme
  --red-theme: #C21A1A;
  --red-dark-theme: #811111;
  --red-light-theme: #FF8686;
  --red-lighter-theme: #FFFBFB;
  --red-grey-dark-theme: #2C0303;

  // Purple color theme
  --purple-theme: #8621D6;
  --purple-dark-theme: #5F0BA1;
  --purple-light-theme: #D49DFF;
  --purple-lighter-theme: #FBF5FF;
  --purple-grey-dark-theme: #210834;

  // Color blind theme
  --color-blind-theme: #2E3770;
  --color-bling-dark-theme: #040618;
  --color-blind-light-theme: #B5BFFF;
  --color-blind-lighter-theme: #EEEFF1;
  --color-blind-grey-dark-theme: #17215A;

  // Colors text
  --text-primary: #2C2C2C;
  --text-primary-dark: #585858;
  --text-secondary: #999999;

  // Grey shades
  --grey: #BFC1CE;
  --grey-light: #F4F6F8;
  --grey-medium: #E1E1E1;
  --blue-dark: #17215A;

  // Errors
  --red: #E84343;
  --red-medium: #F3A1A1;
  --red-light: #FEF6F6;

  // Success
  --green: #69A947;
  --green-medium: #B4D4A3;
  --green-light: #F7FBF6;

  // Warning
  --orange: #FF8920;
  --orange-medium: #FFC48F;
  --orange-light: #FFF9F4;

  --pure-white: #ffffff;
  --total-black: #000000;

  --login-background-image-url: url('/static/images/background-login.jpg');
  --main-color: var(#5066EF);

}
.App[data-theme="undefined"] {
  --main-color: var(--mytalents-theme);
  --main-color-dark: var(--mytalents-dark-theme);
  --main-color-light: var(--mytalents-light-theme);
  --main-color-lighter: var(--mytalents-lighter-theme);
  --main-color-grey-dark: var(--mytalents-grey-dark-theme);
}

.App[data-theme="mytalents"] {
  --main-color: var(--mytalents-theme);
  --main-color-dark: var(--mytalents-dark-theme);
  --main-color-light: var(--mytalents-light-theme);
  --main-color-lighter: var(--mytalents-lighter-theme);
  --main-color-grey-dark: var(--mytalents-grey-dark-theme);
}

.App[data-theme="yellow"] {
  --main-color: var(--yellow-theme);
  --main-color-dark: var(--yellow-dark-theme);
  --main-color-light: var(--yellow-light-theme);
  --main-color-lighter: var(--yellow-lighter-theme);
  --main-color-grey-dark: var(--yellow-grey-dark-theme);
}

.App[data-theme="green"] {
  --main-color: var(--green-theme);
  --main-color-dark: var(--green-dark-theme);
  --main-color-light: var(--green-light-theme);
  --main-color-lighter: var(--green-lighter-theme);
  --main-color-grey-dark: var(--green-grey-dark-theme);
}

.App[data-theme="orange"] {
  --main-color: var(--orange-theme);
  --main-color-dark: var(--orange-dark-theme);
  --main-color-light: var(--orange-light-theme);
  --main-color-lighter: var(--orange-lighter-theme);
  --main-color-grey-dark: var(--orange-grey-dark-theme);
}

.App[data-theme="red"] {
  --main-color: var(--red-theme);
  --main-color-dark: var(--red-dark-theme);
  --main-color-light: var(--red-light-theme);
  --main-color-lighter: var(--red-lighter-theme);
  --main-color-grey-dark: var(--red-grey-dark-theme);
}

.App[data-theme="purple"] {
  --main-color: var(--purple-theme);
  --main-color-dark: var(--purple-dark-theme);
  --main-color-light: var(--purple-light-theme);
  --main-color-lighter: var(--purple-lighter-theme);
  --main-color-grey-dark: var(--purple-grey-dark-theme);
}

.App[data-theme="color-blind"] {
  --main-color: var(--color-blind-theme);
  --main-color-dark: var(--color-blind-dark-theme);
  --main-color-light: var(--color-blind-light-theme);
  --main-color-lighter: var(--color-blind-lighter-theme);
  --main-color-grey-dark: var(--color-blind-grey-dark-theme);
}

// // Default theme
// :root, .App[data-theme="default"] {
//   --main-color: #5066EF;
//   --main-color-dark: #3048DE;
//   --main-color-light: #B5BFFF;
//   --main-color-lighter: #F5F7FF;

//   // --background-color: #F4F6F8;
//   --main-color-transparent: #EAEDFD; //??
//   --background-color-2: #FAFAFA; //?

//   --text-primary: #2C2C2C;
//   --text-primary-dark: #585858;
//   --text-secondary: #999999;
//   // --text-secondary-dark: #E1E1E1;

//   --grey: #BFC1CE;
//   --grey-light: #F4F6F8;
//   // --grey-3: #E1E1E1;
//   --grey-medium: #E1E1E1;
//   --blue-dark: #17215A;

//   --pure-white: #ffffff;
//   --total-black: #000000;
//   --red: #E84343;
//   --red-medium: #F3A1A1;
//   --red-light: #FEF6F6;

//   --green: #69A947;
//   --green-medium: #B4D4A3;
//   --green-light: #F7FBF6;

//   --orange: #FF8920;
//   --orange-medium: #FFC48F;
//   --orange-light: #FFF9F4;

//   --login-background-image-url: url('/static/images/background-login.jpg');
// }

// // Yellow theme 
// .App[data-theme="yellow"] {
//   --main-color: #FFD569;
//   --main-color-dark: #F3C13F;
//   --main-color-light: #FFF5DB;

//   --text-primary: var(--text-primary-dark);
//   --text-primary-dark: #272727;
//   --text-secondary: #9999;
//   --text-secondary-dark: #9999;

//   --grey: #BFC1CE;
//   --grey-light: #F4F6F8;
//   --grey-3: #E1E1E1;
//   --pure-white: #ffffff;
//   --total-black: #000000;
//   --red: #E84343;
//   --red-medium: #F3A1A1;
//   --red-light: #FEF6F6;

//   --green: #69A947;
//   --green-medium: #B4D4A3;
//   --green-light: #F7FBF6;

//   --orange: #FF8920;
//   --orange-medium: #FFC48F;
//   --orange-light: #FFF9F4;
// }