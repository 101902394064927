.side-question-type-selector {
  display: flex;
  flex-direction: column;
  padding-top: 10px;

  .line-menu {
    margin-bottom: 2rem;
    display: flex;
    align-content: center;

    > .resources {
      flex: 1;
      justify-content: center;

      > .line-menu-link {
        margin: 0 2rem;
        height: auto;

        > .label-container {
          > .label {
            font-size: 1.4rem;
          }
        }

        > .selector {
          width: 2rem;
        }
      }
    }
  }

  .question-types-container {
    padding: 0 1.5rem;

    .side-question-type-item {
      margin-bottom: 1rem;
    }
  }

  [draggable] {
    &:active {
      margin-bottom: 1rem;
    }

    &.dragging {
      min-height: 3rem;

      .side-question-type-item {
        border: none;
        background: var(--pure-white);
        box-shadow: 0 3px 6px 0 rgba(112, 130, 243, 0.08);
      }
    }
  }
}
