@import '../../../stylesheets/common/fonts';

.client-talents-container {

    .client-talents-container-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        h2 {
            @extend .headline-2;
            font-size: 14px;
            color: var(--text-primary);
            text-transform: uppercase;
        }

        .client-talents-filters {
            width: 600px;
            display: flex;
            align-items: center;
            gap: 12px;
        }
    } 

    .all-client-talents {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 0.25fr));
        gap: 24px;
        margin: 24px 0;
    } 

}