@import '../../../stylesheets/common/fonts';

.consultant-beneficiary-cvs-page > table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 6px 12px;

    th, td {
        padding: 12px;
        text-align: center;
    }

    th {
        background-color: var(--main-color-light);
        @extend .primary-default;
        font-size: 14px;
        color: var(--blue-dark);
    }

    td {
        @extend .primary-default;
        font-size: 14px;
        color: var(--text-primary);
        border-radius: 6px;

        &:nth-child(1) {
            text-align: left;
        }

    }

    tr {
        background-color: var(--pure-white);
    }


    .client-name {
        @extend .primary-strong;
    }

    .action {
        cursor: pointer;

    }
    .div-icon {
        display: flex;
        justify-content: center;
    }

}