.rsw-editor {
  position: relative;
  height: 100%;
  overflow: auto;

  .rsw-toolbar {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    background-color: var(--main-color);

    .rsw-btn {
      color: var(--main-color-lighter);
    }
    .rsw-btn[data-active='true'],
    .rsw-btn:hover {
      background-color: var(--main-color-lighter);
      color: var(--main-color);
    }
  }
}

.tox-statusbar__branding {
  display: none;
}
