
.course-edit-page {
  >.layout {
    display: flex;
    height: 100vh;
    width: 100vw;

    >aside {
      height: 100vh;
      right: 0;
      width: 380px;

      >* {
        flex-flow: column;
      }
    }

    >section {
      width: 100%;
      padding: 0;
      overflow: auto;
      overflow-x: hidden;
      flex: 1; 

      >.course-edit-page-header {
        display: flex;
        flex-direction: column;

        .actions {
          display: flex;
          justify-content: space-between;
          padding: 24px 25px;

          .left-components,
          .right-components {
            display: flex;
            align-items: center;
            gap: 12px;
          }

          .left-components>input {
            border: none;
            background-color: transparent;
            min-width: 400px;
            outline: none;
            color: var(--text-primary);
            font-size: 24px;
            font-weight: 500;
          }

          .left-components>input::placeholder {
            color: var(--text-secondary);
          }
        }

        .course-illustration {
          width: 100%;
          height: 240px;
          .img-preview {
            overflow: hidden;
          }
        }

        .cover-image {
          height: 240px;
          width: 100%;
          position: relative;
          background-size: cover;

          .cover-image-actions {
            display: flex;
            justify-content: right;
            margin: 12px;

            .cover-image-updload {
              width: 105px;
              background-color: var(--main-color);
              border-radius: 50%;
              width: 40px;
              height: 40px;
              position: absolute;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              .icon {
                line-height: 1;
              }

              svg {
                width: 18px;
                height: auto;
              }

              input {
                display: none;
              }
            }

            .cover-image-resize {
              position: absolute;
              right: 70px;

              button {
                background-color: var(--pure-white);
                border-radius: 50%;
                width: 40px;
                height: 40px;
                border: 1px solid var(--main-color);
                cursor: pointer;

                .icon {
                  line-height: 1;
                }
  
                svg {
                  width: 18px;
                  height: auto;
                }
              }
            }
          }


        }
      }

      >section {
        flex: 1;
        flex-flow: column;
        padding: 2rem 4rem;

        .course-template-toggle-step-all {
          font-size: smaller;
          font-weight: bold;
          padding-top: 25px;
          color: var(--main-color);
          cursor: pointer;
          // width: 100%;
          // text-align: end;
          &:hover {
            color: var(--main-color);
            text-decoration: underline;
          }
        }

        >.informations {
          display: flex;
          align-items: flex-end;
          margin-bottom: 1.3rem;

          >.entry {
            display: flex;
            align-items: flex-end;
            margin-right: 3rem;

            >.count {
              @extend .pro-semibold !optional;
              margin-right: 0.3rem;
              font-size: 1.4rem;
              color: var(--text-primary);
              font-weight: 700;
              @extend .primary-strong !optional;
            }

            >.label {
              font-size: 1.2rem;
              text-transform: uppercase;
              color: var(--text-secondary);

            }
          }
        }

        .new-item {
          display: flex;
          justify-content: space-between;
          background-color: var(--main-color-lighter);
          border: 1px solid var(--main-color);
          border-radius: 6px;
          padding: 12px;
          align-items: center;
          width: 100%;
          cursor: pointer;
          margin-top: 24px;

          >p {
            font-size: 16px;
            font-weight: 600;
            color: var(--main-color);
          }
        }

        .course-container {
          margin: 24px 0;

          p {
            line-height: 1;
          }

          .course-container-childrens {
            border-bottom: 1px solid var(--grey-medium);
            padding: 24px 0 18px 24px;
            display: flex;
            flex-direction: column;
            gap: 12px;
            background-color: var(--grey-light);

            .add-module {
              display: flex;
              gap: 12px;
            }

            .course-module-container {
              margin: 12px 0;
            }
          }

          .droppable-modules {
            background-color: var(--main-color);
            height: 100px;
          }

          .course-step-container  {

            .course-step-duration {
              display: flex;
              align-items: center;
              gap: 12px;
              
              input[type="time"] { 
                position: relative; 
              } 
              input[type="time"]::-webkit-calendar-picker-indicator { 
                background: transparent; 
                color: transparent; 
                position: absolute;
                cursor: pointer; 
                left: 0; 
                top: 0; 
                width: 100%; 
                height: 100%; 
              }

            }
            .course-step-description {
              margin: 12px 0;
      
              .description-text-area {
                

                textarea {
  
                  width: 100%;
                  padding: 12px;
                  border: 1px solid var(--grey-medium);
                  background-color: transparent;
                  border-radius: 6px;
                  outline: none;
                }
              }
            }
          }


          .course-step,
          .course-module {
            display: flex;
            justify-content: space-between;
            background-color: var(--grey-light);
            margin-bottom: 12px;


            .course-module-actions {
              cursor: pointer;

              .button-toggle-actions {
                display: flex;
                justify-content: center;
                cursor: pointer;
                border: none;
                background-color: transparent;
                color: var(--text-secondary);
                align-items: center;

                .icon {
                  height: 2rem;
                  width: 1.6rem;
                  line-height: 1;
                }
              }

              .action-container {
                margin-left: 15px;
                cursor: pointer;
                display: flex;
                gap: 22px;

                .slide-in {
                  animation: slideInAnimation 0.2s ease-in-out;
                  animation-fill-mode: forwards;
                  opacity: 0;
                  transform: translateX(100%);

                  .icon {
                    height: 16px;
                    width: auto;
                    svg {
                      height: 16px;
                      width: auto;
                    }
                  }
                }

                @keyframes slideInAnimation {
                  from {
                    opacity: 0;
                    transform: translateX(100%);
                  }

                  to {
                    opacity: 1;
                    transform: translateX(0);
                  }
                }

                .slide-out {
                  animation: slideOutAnimation 0.5s ease-in-out;
                  animation-fill-mode: forwards;
                  opacity: 1;
                  transform: translateX(0);
                }

                @keyframes slideOutAnimation {
                  from {
                    opacity: 1;
                    transform: translateX(0);
                  }

                  to {
                    opacity: 0;
                    transform: translateX(100%);
                  }
                }

                .icon {
                  height: 2rem;
                  width: 1.6rem;
                  line-height: 1;
                }

                .close2>svg>g {
                  stroke: var(--text-primary)
                }

                svg {
                  display: block;
                }
              }

            }

            .course-step-title,
            .course-step-actions {
              display: flex;
              align-items: center;
              gap: 6px;

              p {
                @extend .primary-strong !optional;
                color: var(--text-primary);
                white-space: nowrap;
              }

              input {
                border: none;
                background-color: transparent;
                width: 100%;
                color: var(--text-primary);
                @extend .primary-strong !optional;
                outline: none;
              }

              input::placeholder {
                color: var(--text-secondary)
              }
            }



            .course-module-title,
            .course-module-actions {
              display: flex;
              align-items: center;
              gap: 6px;

              p {
                color: var(--text-secondary);
              }

              input {
                border: none;
                background-color: transparent;
                width: 100%;
                color: var(--text-primary);
                outline: none;
              }

              input::placeholder {
                color: var(--text-secondary)
              }
            }

            .course-module-title {
              width: 900px;

              p {
                white-space: nowrap;
              }
              svg {
                width: 15px;
                height: 15px;
                line-height: 1;
              }
            }

            .course-step-title {
              flex-grow: 1;
              label {
                width: fit-content;
                cursor: pointer;
                width: 50px;
                height: 40px;
                border: 1px solid var(--grey-medium);
                background-color: var(--grey-medium);
                border-radius: 6px;
                display: flex;
                justify-content: center;

                svg {
                  width: 18px;

                  * {
                    stroke: var(--text-secondary);
                  }
                }

                input {
                  display: none;
                }
              }

              img {
                width: 50px;
                height: 40px;
                border-radius: 6px;
              }
            }

            .course-step-actions {
              cursor: pointer;
              display: flex;
              align-items: center;
              gap: 22px;

              .icon {
                width: 16.4px;
                height: auto;
              }

              .icon.trash {
                stroke: red
              }
            }

          }

          .course-ressources {
            margin: 12px 0;
          }

          .new-ressource {
            display: flex;
            position: relative;
            padding: 12px;
            border-radius: 6px;
            border: 1px dashed var(--main-color);
            background-color: var(--main-color-lighter);
            height: 50px;
            margin-top: 12px;

            h3 {
              color: var(--text-secondary);
              position: absolute;
              left: calc(50% - 12px);
              font-weight: 600;
              font-size: 16px;
            }
          }

          hr {
            border: none;
            background-color: none;
            border-bottom: 1px solid var(--grey-medium);
            width: 100%;
          }
        }
      }
    }
  }

  .loading-spinner__wrapper {
    position: relative;
    background: transparent;
    margin-left: 1rem;
    width: 35px;
    height: 35px;

    svg {
      width: 35px;
      height: 35px;
    }
  }
}

.course-container-droppable-over {
  border: 1px dashed var(--main-color);
  background-color: var(--main-color-lighter);
}
