.reset-password-form {

  .reset-password-container {
    position: relative;

    .view-password-btn {
      position: absolute;
      right: 0;
      top: 12px;
      cursor: pointer;
    }
  }
  .reset-password-terms {
    > .field-container {
      display: inline-block;
    }

    > a {
      @extend .pro-semibold !optional;
      margin-top: -0.3rem;
      font-size: 1.2rem;
      color: var(--main-color);
    }
  }
}
