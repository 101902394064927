.form-row {
  $form-row-label-color: var(--text-primary-dark) !default;
  $form-row-icons-color: var(--text-secondary) !default;
  width: 100%;
  padding: 15px;
  background-color: var(--pure-white);

  > .icon {
    width: 10px;
    margin-right: 10px;
    display: inline-block;
  }

  > .label {
    color: $form-row-label-color;
    @extend .pro-semibold !optional;
    display: inline-block;
  }

  > .buttons {
    display: inline-block;
    float: right;

    .icon-button {
      display: inline-block;
      height: 13px;
      margin-left: 15px;

      svg path {
        stroke: $form-row-icons-color;
      }
    }
  }
}
