.beneficiary-courses-page {
  display: flex;
  flex-wrap: wrap;
  .course-card-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    overflow-x: hidden;

    .parcours-card {
      flex: 0 1 100%;
      margin-bottom: 20px;
      min-width: 250px;
      // max-width: 450px;
      max-width: 100%;

      .content {
        padding: 2.5rem;
      }
      @media screen and (min-width: 768px) {
        &:nth-child(3n) {
          margin-right: 0;
        }
      }

      @media screen and (min-width: 1024px) {
        flex: 0 1 calc(33.33% - 20px);
        max-width: calc(33.33% - 20px);
      }
    }
  }
}
