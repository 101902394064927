$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

.manager-consultants-page {
  flex: 1;

  .tabbed-content-layout {
    > section {
      > .consultants-wrapper {
        display: flex;
        flex-direction: column;

        > .disabled-consultants {
          margin-top: 2rem;

          display: flex;
          flex-direction: column;
        }

        > .enabled-consultants {
          display: flex;
          flex-direction: column;
        }
      }

      .informationsNoConsultants {
        margin-bottom: 2rem;
        text-align: right;
        font-size: 1.2rem;
        color: var(--text-secondary);

        > b {
          @extend .pro-semibold !optional;

          color: #6f7ff6;
        }
      }

      .cards {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -3rem 0 -2rem;
        @media screen and (max-width: $md) {
          justify-content: center;
        }

        > * {
          @media screen and (max-width: $md) {
            flex-basis: calc(100%);
            max-width: 100%;
          }
          flex-basis: calc(33% - 4rem);
          min-height: 16.5rem;
          min-width: 22rem;
          max-width: 30rem;
          margin: 0 2rem 3rem;
        }
      }
    }
  }
}
