$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

.alert-popin {
  $text-color: var(--text-primary-dark);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 3px;
  overflow: hidden;
  background-color: var(----background-color-1);

  > .action-popin-header {
    width: 100%;

    .icon {
      width: 2.2rem;
      display: flex;
    }
  }

  > .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 20px;

    > .text {
      text-align: center;
      width: 90%;
      color: $text-color;
      font-size: 1.4rem;
      @extend .pro-regular !optional;
      margin-bottom: 30px;
    }

    > .buttons {
      display: flex;
      @media screen and (max-width: $md) {
        flex-direction: column-reverse;
        align-items: center;
        gap: 5px;
        justify-content: center;
      }

      .primary-button {
        min-width: 120px;
        @media screen and (max-width: $md) {
          min-width: fit-content;
          margin: 0px;
        }

        &:nth-child(1) {
          margin-right: 7px;
          @media screen and (max-width: $md) {
            margin: 0px;
          }
        }

        &:nth-child(2) {
          margin-left: 7px;
          @media screen and (max-width: $md) {
            margin: 0px;
          }
        }
      }
    }
  }

}
