.message-popin {
  margin-top: 2rem;
  width: 48rem;

  .close-popin {
    padding: 0;

    .close-popin-content {
      border-radius: .5rem;
    }
  }

  .message-popin-content {
    .input-tag-field,
    .input-field,
    .text-area-field,
    .combo-field {
      margin-bottom: 2.7rem;
    }

    .text-area-field {
      height: 32rem;
    }

    .btn-send-wrapper {
      display: flex;
      justify-content: flex-end;
    }
  }

  // @include mobile() {
  //   width: calc(100vw - 4rem);
  //   margin: 2rem -2rem 0 1rem;

  //   .close-popin {
  //     .close-popin-content {
  //       .circle-button {
  //         width: 4rem;
  //         height: 4rem;
  //         right: -1.2rem;
  //         top: -2rem;

  //         .icon {
  //           width: 25%;
  //           height: 25%;
  //         }
  //       }
  //     }
  //   }
  // }
}
