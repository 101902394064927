.popin-layout {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  > header {
    > .action-popin-header {
      padding: 0 4rem;
      height: 6rem;
    z-index: 1002;
    }
  }

  > section {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 1.3rem 3.9rem;

    background: var(--grey-light);
  }
}
