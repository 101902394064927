.synthese-step {
  position: relative;
  width: 100%;
  padding-left: 6rem;
  cursor: pointer;

  > .header {
    > .step-count {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 1.4rem;
      @extend .pro-light !optional;
      color: var(--text-primary-dark);
    }

    > .icon-button {
      cursor: pointer;
      position: absolute;
      top: 3px;
      right: 0;
      display: block;
      width: 1.5rem;
      height: 1.5rem;

      > svg {
        width: 1.5rem;
        min-width: auto;
      }
    }

    > .title {
      font-size: 1.6rem;
      @extend .pro-semibold !optional;
      color: var(--text-primary-dark);
      margin-bottom: 2rem;
    }
  }

  > .content {
    .input-field {
      display: flex;
      padding: 1rem 0.5rem;
      margin-bottom: 1rem;
      height: 4rem;
      align-items: center;
      border: 1px solid var(--grey-medium);
      border-radius: 3px;

      > .common-form-separator {
        display: none;
      }

      border-left: 0.5rem solid var(--main-color);
    }

    .text-area-field {
      height: 20rem;

      &:not(:last-child) {
        margin-bottom: 1rem;
      }

      .textarea-container {
        > textarea {
          padding: 1rem 1rem;
        }
      }
    }

    .private {
      margin-bottom: 1rem;

      .text-area-field {
        height: 20rem;
        background: rgba(0, 0, 0, 0.05);

        &:not(:last-child) {
          margin-bottom: 1rem;
        }

        .textarea-container {
          > textarea {
            padding: 1rem 1rem;
          }
        }
      }
    }

    .hidden-area {
      overflow: hidden;
      transition: height 0.2s;
      cursor: default;
    }
  }
}
