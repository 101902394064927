.parcours-step-synthese {
  margin-bottom: 1rem;

  .parcours-step-body {
    padding: 1rem;
  }

  .synthese-complete-bar {
    display: flex;
    align-items: center;
    position: absolute;
    padding-right: 1rem;
    right: 0;

    .check-box-field {
      margin-right: 15px;
    }
  }

  .children {
    padding: 1rem;
  }
}
