.edit-customer-popin {
  background: var(--grey-light);
  border-radius: 3px;

  >.content {
    padding: 24px;
    max-height: 500px;
    overflow: scroll;

    >.cabinet-card {
      margin-right: 3.5rem;
    }

    .abo-card {
      margin-bottom: 1rem;
    }

    .invite-status {
      @extend .pro-regular !optional;
      margin-bottom: 1rem;
      font-size: 1.2rem;
      color: var(--text-secondary);
    }

    .invitation-button {
      >.primary-button {
        width: 100%;
      }
    }
  }
  .edit-customer-popin {
    display: flex;
    justify-content: end;
    padding: 12px 24px;

  }

}