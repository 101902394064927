@import '../../../stylesheets/common/fonts';

.agenda-calendar {
    .agenda-calendar-header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid var(--grey);
        padding: 12px 0;
        width: 100%;
        h2 {
            @extend .headline-2;
            color: var(--text-primary);
            font-size: 18px;
        }
        .icon {
            cursor: pointer;
        }
    }
    table.agenda-calendar-date {
        width: 100%;
        margin: 12px 6px;
        
        .calender-col {
            padding: 6px 0;
            position: relative;
            //    display: flex;
            //     justify-content: center;
            //     align-items: center;
            //     flex-direction: column;

        }

            .day-name {
                @extend .headline-2;
                color: var(--text-secondary);
                font-size: 18px;
                text-align: center
            }
    
            .day-value {
                @extend .headline-2;
                color: var(--text-primary);
                font-size: 18px;
                padding: 6px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }

            .day-value.active {
                background-color: var(--main-color);
                color: var(--pure-white);
                border-radius: 50%;
                width: 30px;
                height: 30px;
                margin: 0 auto;
            }

            .day-value.has-appointment {
                cursor: pointer;
             
            }

            .day-value.has-appointment::after {
                content: "";
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: red;
            }
    
            .isDates-grey {
                @extend .headline-2;
                color: var(--text-secondary);
                font-size: 18px;
            }
        }
   
        
    
}