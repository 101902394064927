.client-card {
  min-width: 22rem;
  min-height: 24rem;
  border-radius: 3px;
  background: var(--pure-white);
  box-shadow: 0 2px 4px 0 rgba(112, 130, 243, 0.08);

  > .top-section {
    padding: 10px 13px;
    text-align: center;
    height: 130px;

    > .client-image {
      width: 100%;
      height: 100%;
      background-size: contain;
      background-position: 50% 50%;
      background-repeat: no-repeat;
    }
  }

  > .bottom-section {
    position: relative;
    padding: 10px 13px 15px 13px;
    border-top: 1px solid var(--grey-medium);

    > .client-name {
      color: var(--text-primary-dark);
      font-size: 1.4rem;
      @extend .pro-semibold !optional;
      margin-bottom: 3px;
    }

    > .client-region {
      color: var(--text-primary-dark);
      font-size: 1.2rem;
      @extend .pro-regular !optional;
      margin-bottom: 15px;
    }

    > .consultant-section {
      > .label {
        color:var(--text-secondary);
        @extend .pro-semibold !optional;
        font-size: 1.2rem;
        margin-top: 1rem;
        text-transform: uppercase;
      }

      > .consultant-count {
        color: var(--text-primary-dark);
        font-size: 1.2rem;
        @extend .pro-semibold !optional;
        margin-top: 2px;
      }
    }

    > .buttons {
      position: absolute;
      bottom: 1.5rem;
      right: 1rem;
      display: flex;
      flex-direction: row;

      > .circle-button {
        margin-left: 1rem;
      }
    }
  }
}
