$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

.stats-table {
  overflow-x: scroll;
  .title {
    font-size: 1.2rem;
    @extend .pro-semibold !optional;
    color: var(--text-secondary);
    text-transform: uppercase;
    margin-bottom: 1rem;
    padding-left: 2rem;
  }

  table,
  thead,
  tbody,
  tr,
  th,
  td {
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
    border: none;
    border-collapse: inherit;
    border-spacing: 0;
    border-color: inherit;
    vertical-align: inherit;
    text-align: left;
    font-weight: inherit;
  }

  table {
    width: 100%;
    background-color: var(--pure-white);
    box-shadow: 0 0.4rem 0.6rem 0 rgba(112, 130, 243, 0.08);
    border-radius: 0.3rem;

    th,
    td {
      font-weight: normal;
      text-align: left;
      padding: 1rem 2rem;
      @media screen and (max-width: $md) {
        font-size: 9px !important;
        padding: 1rem;
      }
    }

    tbody tr td {
      border-top: 0.1rem solid var(--grey-medium);
    }

    tr th,
    tr td {
      border-right: 0.1rem solid var(--grey-medium);
      vertical-align: middle;

      &:last-child {
        border-right: none;
      }
    }

    tr th {
      font-size: 1.2rem;
      @extend .pro-semibold !optional;
      color: var(--main-color);
      text-transform: uppercase;
    }

    tr td {
      font-size: 1.4rem;
      @extend .pro-semibold !optional;
      color: var(--text-primary-dark);
    }

    tr td.highlight {
      font-size: 1.2rem;
      color: var(--main-color);
      text-transform: uppercase;
      white-space: nowrap;
    }
  }
}
