$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

.responsive-modal {
    min-width: 670px;
    @media screen and (max-width: $md) {
        min-width: auto;
        width: 100%;
      }
}