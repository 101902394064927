.consultant-beneficiary-assessments-page {
  .action-button {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    font-size: 1.2rem;
    color: #999;

    > button {
      margin-right: 1rem;
    }
  }
}
