.portal-forgot-password-page {
  position: absolute;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: var(--login-background-image-url) no-repeat center;
  background-size: cover;

  > .logo {
    max-width: 130px;
    margin-bottom: 5.3rem;
  }
}
