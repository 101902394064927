.consultant-beneficiary-maestros-page {
  .maestro-table {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    .maestro-column {
      border-top: 1px solidvar(--text-secondary);
      background: var(----background-color-1);
      display: flex;
      flex-direction: column;
      width: 19%;
      h4 {
        padding: 1rem 0;
        margin: 0 auto;
        font-size: 1.7rem;
        text-transform: uppercase;
        @extend .pro-light !optional;
      }
    }

    .maestro-cards-wrapper {
      width: 100%;
      border-top: 1px solidvar(--text-secondary);

      .maestro-card {
        padding: 1rem;
        margin: 1rem auto;
        background-color: var(--pure-white);
        border-radius: 3px;
        box-shadow: rgba(112, 130, 243, 0.08) 0px 2px 4px 0px;
        > strong {
          display: block;
          font-size: 1.5rem;
          @extend .pro-semibold !optional;
        }
        > em {
          font-size: 1.4rem;
          @extend .pro-light !optional;
        }
        .card-notes {
          @extend .pro-regular !optional;
          margin-top: 0.5rem;
          color: var(--text-primary-dark);
          font-size: 1.3rem;
        }
        .card-time {
          @extend .pro-regular !optional;
          color:var(--text-secondary);
          font-size: 1.2rem;
          text-align: right;
        }
      }
    }
  }
}
