.parcours-informations {
    line-height: 1;
    display: flex;
    align-items: center;
    gap: 12px;
    
    label {
        @extend .secondary-default !optional;
        font-size: 14px;
        line-height: 24px;
        color: var(--text-secondary)
    }
    p {
        @extend .secondary-strong !optional;
        font-size: 14px;
        color: var(--text-primary)
    }
}