.financial-card {
  box-shadow: 0 4px 6px 0 rgba(112, 130, 243, 0.08);

  > .icon-card-title {
    margin-left: 0.5rem;
  }

  .financial-card-content {
    padding: 1.5rem 2.5rem;
    background: white;

    .info-field {
      margin-bottom: 1rem;
    }

    .file-input-container {
      margin-bottom: 1rem;

      display: flex;
      justify-content: space-between;

      > .file-info {
        display: flex;
        align-items: center;

        .delete-icon {
          width: 3rem;
          height: 3rem;
          position: relative;
          margin-bottom: 1rem;

          .icon-button {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 1rem;
            height: 1rem;

            .icon, .icon svg {
              width: 1rem;
              height: 1rem;
              min-width: auto;
              min-height: auto;
              position: relative;
            }

            .icon svg {
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        }
      }

      > .actions {
        display: flex;
        align-items: center;

        .download-icon, .upload-icon {
          width: 3rem;
          height: 3rem;

          margin: 0 0.5rem;

          .circle-button {
            width: 3rem;
            height: 3rem;

            .circle-button .icon, .circle-button .icon svg {
              width: 1.5rem;
              height: 1.5rem;
            }
          }
        }
      }
    }

  }

  .loading-spinner__wrapper {
    position: relative;
    background: transparent;

    svg {
      height: 30px;
      width: 30px;
    }
  }

  // @include mobile() {
  //   > .content {
  //     padding: 1rem;

  //     .file-input-container {
  //       flex-direction: column;

  //       > .file-info {
  //         .info-field {
  //           margin-bottom: 0.5rem;
  //         }
  //       }

  //       > .actions {

  //         .download-icon, .upload-icon {
  //           margin-left: 0;
  //         }
  //       }
  //     }
  //   }
  // }
}
