$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

.parcours-list-resource {
  background-color: var(--pure-white);
  display: flex;
  border-radius: 6px;
  margin: 12px 0;
  position: relative;
  cursor: pointer;

  @media screen and (max-width: $md) {
    flex-direction: column;
  }

  .ressource-card-illustration {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 50px;
    padding: 15px 0;
    max-width: 56px;

    @media screen and (max-width: $md) {
      flex-direction: row;
      padding: 5px 24px;
      margin: 0;
      justify-content: start;
    }

    p {
      @extend .label-lowercase !optional;
      text-align: center;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 18px;
      color: var(--text-secondary);
    }
  }

  .ressource-card-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    padding: 12px 24px;
    border-left: 3px solid var(--grey-light);
    @media screen and (max-width: $md) {
        border-top: 3px solid var(--grey-light);
        border-left: none;
      }

      .message-lock {
        font-size: 12px;
        color: var(--text-secondary);
        background: var(--pure-white);
        box-shadow: 0 5px 10px 0 rgba(112, 130, 243, 0.08);
        align-items: center;
        padding-left: 5px;
        position: absolute;
        left: 150%;
        display: none;
        z-index: 100;
        width: 130px;
        border-radius: 8px;
        padding: 5px 10px;
    }

    .icon:hover + .message-lock {
      display: flex;
    }
    .tag-container {
      width: fit-content;
    }

    hr {
      color: var(--grey-medium);
      border: none;
      border-bottom: 1px solid;
      width: 100%;
      height: 1px;
    }

    .ressource-card-content-presentation {
      cursor: pointer;
    }
  }
}
