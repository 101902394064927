.children-item {
  &.centraltest {
    flex-wrap: wrap;
  }
}

.centraltest-module-consultant {
  width: 100%;
  display: flex;

  > * {
    padding: 1rem;
    align-items: center;
    display: flex;
  }

  > .selection {
    width: 50%;
    justify-content: space-between;
    border-right: 1px solid #e7e7e7;

    > .details {
      width: 100%;
      display: flex;
      align-items: center;

      > .label {
        margin-left: 1rem;
      }
    }
  }

  > .status {
    width: 50%;
    display: flex;
    align-items: center;

    > .details {
      width: 100%;
      display: flex;
      align-items: center;

      > .icon {
        height: 24px;
        width: 24px;
      }

      > .label {
        margin-left: 1rem;
      }

      > .label--pending {
        margin-left: 1rem;
        //color:var(--text-secondary);
      }

      > .label--invited {
        margin-left: 1rem;
        //color: var(--orange);
      }

      > .label--done {
        margin-left: 1rem;
        //color: var(--green);
      }

    }
  }
}

.centraltest-module-controls {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  align-items: center;
  margin-top: 1rem;
  border-top: 1px solid var(--grey);
}
