@import "../../stylesheets/common/fonts";


.consultant-mission-page {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;

    .mission-informations {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .primary-button {
            width: fit-content;
            align-self: end;
        }

        .alert-invalid-mission {
            @extend .primary-strong;
            color: var(--red);
            font-size: 14px;
        }
    }

    .mission-talents {

        .mission-talents-filters {
            max-width: 600px;
        }

        .mission-all-talents {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 0.25fr));
            gap: 24px;
            margin: 24px 0;
        }
    }
}

.left-navbar {
    display: flex;
    align-items: center;
    gap: 24px;

    h1 {
        font-size: 18px;
        font-weight: 800;
    }

}