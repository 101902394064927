.beneficiary-profile-consultant-page {
  flex: 1;

  // @include mobile {
  //   .line-menu {
  //     .components {
  //       .primary-button {
  //         height: 4rem;
  //         margin-top: 2rem;
  //       }
  //     }
  //   }

  //   .beneficiary-consultant {
  //     display: flex;
  //     justify-content: center;
  //   }
  // }
}
