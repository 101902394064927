@import '../../../stylesheets/common/fonts';

.container-dropdown-tag {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 3px;

    label {
        @extend .label-lowercase;
        color: var(--text-secondary);
        font-size: 12px;
    }

    .dropdown {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 6px;
        border-bottom: 1px solid var(--grey-medium);
        cursor: pointer;

        .dropdown-icon {
            cursor: pointer;

            .icon {
                width: 12px;
                height: 12px;
            }
        }

        .dropdown-placeholder {
            font-size: 14px;
            color: var(--text-secondary);
        }

        .dropdown-selected-tags {
            display: flex;
            gap: 6px;
            align-items: center;
            max-width: 500px;
            flex-wrap: wrap;
        }
    }

    .dropdown-list {
        position: absolute;
        top: 55px;
        height: fit-content;
        max-height: 300px;
        width: 100%;
        overflow: scroll;
        background-color: var(--pure-white);
        z-index: 100;
        box-shadow: 2px 3px 14px 1px rgba(186, 186, 190, 0.379);
        margin: 6px 0;
        padding: 6px;


        .dropdown-list-title {
            @extend .quaternary-strong;
            color: var(--main-color);
            font-size: 12px;
            text-transform: uppercase;
        }

        .dropdown-list-group {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        li {
            cursor: pointer;
            list-style: none;
            color: var(--text-primary);
            padding: 8px 3px;
            font-size: 14px;
        }

        li:hover {
            background-color: var(--main-color-lighter);
        }


        .dropdown-list-searchbar{
            width: 100%;
            border: none;
            border-bottom: 1px solid var(--grey-medium);
            padding: 6px;
            outline: none;
            margin-bottom: 6px;
            font-size: 14px;
        }

        .dropdown-list-select-all {
            display: flex;
            align-items: center;
            gap: 6px;
        }
    }

    .dropdown-list::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    
      scrollbar-width: none;
}