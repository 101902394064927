.parcours-step-header {
  $parcours-step-header-active-color: var(--text-primary-dark) !default;
  $parcours-step-header-disable-color: var(--text-secondary) !default;
  position: relative;

  .modules {
    min-height: 10rem;
  }

  > .parcours-step-icons {
    [class^='icon-module-type-'] {
      margin-right: .7rem;

      > .icon {
        width: 15px;
        height: 15px;
      }
    }
  }

  .parcours-step-header-content {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    cursor: pointer;

    &.not-editable {
      cursor: default;
    }

    &.active {
      color: $parcours-step-header-active-color;
    }

    &.disable {
      color: $parcours-step-header-disable-color;
    }

    [class^='icon-prog-state-'] {
      position: relative;
      margin-right: 11px;
      width: 15px;
      height: 15px;
    }

    .light {
      @extend .pro-light !optional;
      font-size: 1.4rem;
      margin-right: 20px;
    }

    .label {
      font-size: 1.6rem;
      flex: 1;
      border: none;
      background: none;

      &::placeholder {
        color:var(--text-secondary);
        opacity: 1;
      }
    }

    .trash-chevron-wrapper {
      display: flex;
      justify-content: flex-end;
      margin-left: 1rem;

      .icon-button {
        height: 2rem;
        width: 1.5rem;
        margin-left: 1.2rem;

        > .icon {
          width: 100%;
          height: 100%;
        }

        &:first-child {
          margin-left: 0;
        }
      }
    }

    [class^='icon-chevron-'] {
      width: 15px;

      .icon {
        width: 15px;
        height: 15px;
      }
    }

    input {
      @extend .pro-regular !optional;
      color: var(--text-primary-dark);
    }
  }

  // @include mobile() {
  //   > .parcours-step-header-content {
  //     align-items: flex-start;

  //     > [class^='icon-prog-state-'] {
  //       position: relative;
  //       top: 1px;
  //     }
  //   }
  // }
}
