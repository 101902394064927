[class^="icon-chevron-"] {
  width: 100%;
  height: 100%;

  svg {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }
}
