@import '../../../stylesheets/common/fonts';

.member-group-card {
    background-color: var(--pure-white);
    border-radius: 6px;
    cursor: pointer;

    .member-group-card-header {
        padding: 12px;
        border-bottom: 1px solid var(--grey-medium);
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        .icon {
            width: 18px;
            height: 18px;
            cursor: pointer;
        }
    }

    .member-group-card-content {
        padding: 12px;
        display: flex;
        flex-direction: column;
        gap: 6px;

        .member-informations {
            display: flex;
            align-items: center;
            gap: 12px;

            h1 {
                @extend .headline-1;
                font-size: 14px;
                color: var(--text-primary)
            }

            .avatar {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: var(--main-color-lighter);
                display: flex;
                justify-content: center;
                align-items: center;

                .icon {
                    width: 12px;
                    height: auto;
                }
            }
        }


        p {
            @extend .primary-default;
            color: var(--text-secondary);
            color: var(--text-secondary);
            font-size: 14px;
        }
    }
    
}