.white-brand-card {
    background-color: var(--pure-white);
    padding: 12px;

    h2 {
        font-size: 14px;
        color: var(--text-primary);
        text-transform: uppercase;
    }
    .white-brand-card-content {
        display: flex;
        align-items: center;
        gap: 24px;

    }
}