.module-appointment-edit-page {
  > .layout {
    > section {
      padding: 3rem;

      > .module-appointment-edit-form {
        display: flex;
        flex-flow: column;
        align-items: flex-start;

        > * {
          display: flex;
          margin-bottom: 2.5rem;

          > * {
            flex: 1;

            &:not(:last-child) {
              margin-right: 2rem;
            }
          }
        }

        > .description {
          align-self: stretch;
        }

        > .dates,
        > .informations {
          min-width: 50%;
        }

        > .notes {
          flex-flow: column;
          align-self: stretch;

          > * {
            margin-right: 0;
          }

          .notes-area {
            margin-bottom: 2rem;

            > .text-area-field {
              height: 19rem;
            }
          }
        }
      }
    }
  }
}
