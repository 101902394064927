.consultant-card {
  $consultant-card-labels-color: var(--text-primary-dark) !default;
  $consultant-card-beneficiary-label-color: var(--text-secondary) !default;
  background: var(--pure-white);
  box-shadow: 0 2px 4px 0 rgba(112, 130, 243, 0.08);
  border-radius: 3px;

  .actions-button {
    display: flex;
    flex-direction: row;

    .icon-button {
      margin-right: 10px;
      width: 16px;
      height: 16px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  > .top-container {
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 36px;
    cursor: pointer;

    > .avatar-container {
      // width: 35px;
      // height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    > .informations {
      display: inline-block;
      vertical-align: top;
      color: $consultant-card-labels-color;

      > .fullname {
        font-size: 1.4rem;
        @extend .pro-semibold !optional;
        margin-bottom: 1px;
      }

      > .small-informations {
        font-size: 1.2rem;
        @extend .pro-light !optional;

        > .entry {
          margin-bottom: 1px;
        }

        margin-bottom: 13px;
      }

      > .icon-button {
        svg {
          width: 15px;
          height: 15px;
          min-height: 15px;
          min-width: 15px;
        }
      }
    }
  }

  > .top-container:hover {

    background-color: var(--main-color-lighter);
  }
  

  > .bottom-container {
    border-top: 1px solid var(--grey-medium);
    padding: 10px 20px 18px;

    > .beneficiary-title {
      text-transform: uppercase;
      color: $consultant-card-beneficiary-label-color;
      font-size: 1.2rem;
      @extend .pro-semibold !optional;
      margin-bottom: 5px;
    }

    > .beneficiaries-avatars {
      display: inline-block;
      width: 100%;

      > .beneficiary-avatar-container {
        width: 20px;
        height: 20px;
        display: inline-block;
        margin-right: 10px;
        vertical-align: top;

        .avatar-circle {
          min-width: 20px;
          min-height: 20px;

          .picture {
            border: 1px solid #e8e8e8;
          }

          .no-picture {
            display: flex;
            align-items: center;
            justify-content: center;

            > .icon {
              width: 50%;
              height: auto;

              > svg {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }

    > .beneficiaries-statuses {
      text-align: center;
      .beneficiary-status-container {
        font-size: 1.4rem;
        margin-bottom: 10px;
        @extend .pro-regular !optional;

        &:last-child {
          margin-bottom: 0;
        }
        span {
          @extend .pro-semibold !optional;

          &.status-count {
            font-size: 1.5rem;
          }
        }
      }
    }
  }

  > .action-container {
    display: flex;
    justify-content: end;
    gap: 6px;
    align-items: center;
    border-top: 1px solid var(--grey-medium);
    padding: 12px;
    color: var(--main-color);
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
  } 
}
