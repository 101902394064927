    .pourcentage-done-course {
      background-color: var(--pure-white);
      padding: 12px 3px 12px 12px;
      border-radius: 6px;

      span {
        font-size: 12px;
        display: flex;
        align-items: center;
        color: var(--text-secondary);

        p {
          @extend .secondary-strong !optional;
          color: var(--text-primary);
          margin-left: 3px;
        }
      }

      .progression-bar {
        margin-top: 6px;
        background-color: var(--main-color-lighter);
        height: 6px;
        width: 100%;
        border-radius: 24px;
        position: relative;

        .progression-done-bar {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          border-radius: 24px;
          background-color: var(--main-color);
        }
      }
    }