@import '../../../stylesheets/common/fonts';

$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

.agenda-date {
  width: 60px;
  @media screen and (max-width: $md) {
    scale: 0.8;
  }

  .agenda-date-month {
    background-color: var(--main-color);
    border-radius: 6px 6px 0px 0px;
    p {
      @extend .quaternary-strong;
      color: var(--pure-white);
      text-align: center;
      padding: 3px 12px;
      font-size: 9px;
    }
  }

  .agenda-date-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: var(--grey-light);

    

    h1 {
      @extend .headline-1;
      color: var(--main-color);
      font-size: 24px;
    }

    p {
      @extend .quaternary-strong;
      font-size: 9px;
      color: var(--text-secondary);
    }
  }
}
