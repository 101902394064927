.wrapper-edit-synthesis {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  height: calc(100vh - 200px);

  .wrapper-textarea,
  .wrapper-content {
    flex: 1;
    height: 50%;
    overflow: auto;
  }

  .wrapper-content {
    border: 1px solid #ddd;
    border-radius: 0.375rem;
    padding: 0.5rem;
    background-color: var(--color-blind-lighter-theme);
  }

  .wrapper-textarea {
    .rsw-editor {
      position: relative;
      height: 100%;

      .rsw-toolbar {
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        background-color: var(--main-color);

        .rsw-btn {
          color: var(--main-color-lighter);
        }
        .rsw-btn[data-active='true'],
        .rsw-btn:hover {
          background-color: var(--main-color-lighter);
          color: var(--main-color);
        }
      }
    }
  }
}

.wrapper-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
