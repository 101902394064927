.droppable {
  padding: 0;
  border: 2px dashed transparent;
  box-sizing: border-box;
  transition: border-color 300ms, padding 100ms;

  [draggable] {
    margin-right: 0;
    margin-left: 0;
  }

  &.dragging-over {
    padding: 1rem 0;
    border-color: var(--main-color);

    [draggable] {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
}