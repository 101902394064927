.module-edit-page {
  > .layout {
    height: 100vh;

    > header {
      > .action-popin-header {
        padding: 0 2rem;
        border-bottom: .3rem solid var(--grey-light);
      }
    }

    > section {
      padding: 0;
      height: 100%;

      > .layout {
        height: 100%;
        overflow: hidden;

        > section {
          overflow: auto;

          > .count-resources {
            @extend .pro-semibold !optional;

            margin-bottom: 1rem;
            font-size: 1.2rem;
            text-transform: uppercase;
            color: #999;

            > b {
              margin-right: .5rem;
              font-size: 1.4rem;
              color: var(--text-primary-dark);
            }
          }

          > .description {
            margin-bottom: 2rem;
          }

          > .module-resource-row-list {
            flex: 1;

            > * {
              margin-bottom: 1rem;
            }
          }
        }

        > aside {
          height: 100%;
          overflow: auto;
        }
      }
    }
  }
}
