.login-form {

  .login-form-password-container {
    display: flex;
    position: relative;

    .view-password-btn {
      position: absolute;
      right: 0;
      bottom: 6px;
      cursor: pointer;
    }

  }

  > .field-container {
    width: 24rem;
    margin-bottom: 2rem;
  }

  > .primary-button {
    margin: 4rem auto 0;
  }

  > .check-box-field {
    min-width: 2rem;
    margin-bottom: 1rem;

    > label {
      .label {
        @extend .pro-semibold !optional;

        font-size: 1.2rem;
        color: var(--text-primary-dark);
      }
    }
  }

  > .forgot-password {
    display: flex;
    justify-content: end;
    margin-bottom: 2.6rem;
    font-size: 14px;
    color: var(--main-color);
    margin-top: 24px;
    text-decoration: underline;
  }

  .cgu-container {
    padding-top: 25px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: end;
    align-items: center;
    gap: 15px;
    a {
      text-decoration: underline;
      font-size: 14px;
    color: var(--main-color);
    }
  }
}
