.avatar-circle {
  width: 80px;
  height: 80px;
  border-radius: 50%;

  .picture {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
  }

  .no-picture {
    background-color: var(--grey-light);
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
  
      svg {
        width: 21px;
        height: 21px;
      }
    }
  }
}
