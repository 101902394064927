.tool-tip {
  display: flex;
  justify-content: center;

  .tool-tip-wrapper {
    background: var(--text-primary-dark);
    @extend .pro-regular !optional;
  }

  .__react_component_tooltip.type-dark.place-top:after {
    border-top-color: var(--text-primary-dark);;
  }

}
