.device-optimization-page {
  position: absolute;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: url('/static/images/background-login.jpg') no-repeat center;
  background-size: cover;

  > .logo {
    width: 130px;
    height: 65px;
    margin-bottom: 5.3rem;
    padding-top: 2rem;
  }

  > .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    padding: 4rem 4rem 3.5rem;

    .title {
      @extend .pro-semibold !optional;
      color: var(--text-primary-dark);
      margin-bottom: 3rem;
      font-size: 1.8rem;
    }

    .message {
      @extend .pro-semibold !optional;
      color: var(--main-color);
      font-size: 1.8rem;

      text-align: center;
      margin-bottom: 3.6rem;
      white-space: pre-line;

      margin-bottom: 3rem;
    }
  }
}
