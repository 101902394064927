.parcours-appointment {

    .illustration-container {
        border-radius: 12px;
        width: fit-content;
        margin: 0 auto;
        
        .ressource-icon.appointment > svg{
            width: 100px;
            height: auto;
        }

    }

    .parcours-appointment-content {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .primary-button {
            width: fit-content;
        }

        .parcours-appointment-content-no-validate {
            color: var(--text-secondary);
        }
    }

    .parcours-appointment-content-invalid-date {
        display: flex;
        justify-content: center;
    }

}