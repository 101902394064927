.consultant-missions-page {
    .consultant-missions-content {
        width: 100%;
        .consultant-missions-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }


        .consultant-missions-filter {
            display: flex;
            margin: 12px 0;
            gap: 24px;
            justify-content: end;

            .consultant-missions-search-bar {
                display: flex;
                align-self: end;
                width: 600px;
                justify-content: end;
                gap: 12px;
            }
        }
    }
}