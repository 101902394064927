.manager-consultant-edit-page {

  .layout {
    .manager-consultant-edit-page-header {
      position: relative;
      .action-popin-header {
        background-color: var(--pure-white);
  
        .label-part {
          .label {
            span {
            color: var(--pure-black);
            }
            color: var(--pure-black);
          }
        }
      }

      button {
        position: absolute;
        right: 24px;
        bottom: 5px;
      }
    }

    .tabbed-content-layout {
      >section {
        align-items: flex-start;

        >.action-button {
          display: flex;
          align-items: center;
          margin-bottom: 2rem;
          font-size: 1.2rem;
          color: #999;

          >button {
            margin-right: 1rem;
          }
        }
      }
    }
  }

}