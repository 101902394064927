$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

.subscribe-card {
  background-color: var(--pure-white);
  padding: 12px;
  border-radius: 6px;

  h2 {
    font-size: 14px;
    color: var(--text-primary);
    text-transform: uppercase;
  }

  .subscribe-card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: $md) {
      flex-direction: column;
      .primary-button {
        align-self: auto;
      }
    }

    .subscribe-total {
      display: flex;
      align-items: center;
      gap: 12px;

      .count {
        font-weight: 800;
        color: var(--main-color);
      }
    }
  }
}
