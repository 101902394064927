.parcours-list {
  width: 74.5rem;
  background: var(----background-color-1);

  .parcours-list-row {
    padding: 1.5rem 0;
    border-bottom: 1px solid var(--grey);
  }

  .circle-button-wrapper {
    display: flex;
    justify-content: flex-end;
    padding: 2rem 0 2rem;

    .circle-button {
      width: 3rem;
      height: 3rem;
    }
  }
}
