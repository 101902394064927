@import '../../../stylesheets/common/fonts';

$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

.client-container {
    display: flex;
    align-items: top;
    gap: 24px;

    @media screen and (max-width: $lg) {
        flex-direction: column;
    }

    .client-general-informations,
    .client-informations {
        width: 100%;
        border-radius: 6px;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 18px;

        h2 {
            @extend .headline-2;
            font-size: 14px;
            color: var(--text-primary);
            text-transform: uppercase;
        }
    }

    .client-general-informations {
        background-color: var(--pure-white);

        .select-container {
            width: 100%;
        }

        .input-field {
            display: initial;
            height: fit-content;
        }  
    }

    .client-informations {
        border: 1px solid var(--main-color);
        background-color: var(--main-color-lighter);

        .informations-flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 36px;

            .input-field {
                width: 100%;
                height: 100%;

            }
        }
    }

}