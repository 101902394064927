$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

.action-popin-header {
  display: flex;
  align-items: center;
  background-color: var(--main-color);
  padding: 10px 20px;
  min-width: 500px;
  @media screen and (max-width: $md) {
    min-width: auto;
    flex-flow: row wrap;
  }

  > .label-part {
    display: flex;
    flex: 1;
    align-items: center;

    > .icon, .icon svg * {
      stroke: var(--main-color);
    }

    .icon-button {
      margin-right: 1rem;
    }

    .icon {
      margin-right: 1rem;
      width: 1rem;
      position: relative;
    }

    .label {
      margin-right: 4rem;
      display: flex;
      flex: 1;
      color: var(--pure-white);
      font-size: 2rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      .light {
        @extend .pro-light !optional;
        margin-right: 10px;
        @media screen and (max-width: $md) {
          display: none;
        }
      }

      .bold {
        @extend .pro-semibold !optional;
        flex: 1;
        font-size: 2rem;
        color: var(--pure-white);
        border: none;
        appearance: none;

        &::placeholder {
          color: var(--pure-white);

          opacity: 1;
        }
      }
    }
  }

  > .actions-part {
    display: flex;

    > * {
      margin-left: .5rem;
    }

    .primary-button .label {
      color: white;
    }
  }

  // @include mobile() {
  //   min-width: 0;

  //   > .label-part {
  //     .label {
  //       margin-right: 1rem;
  //     }
  //   }
  // }
}
