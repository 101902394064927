.manager-statistics-office-page {
  display: flex;
  flex-direction: column;
  flex: 1;

  .statistics-section {
    margin-top: 2rem;

    .section-title {
      margin-bottom: 2rem;
    }

    .statsTable {
      margin-top: 2rem;
    }

    .collapseTable {
      .title {
        margin-bottom: 0;
        background-color: var(--pure-white);
        border-top: 0.1rem solid var(--grey-medium);
        border-bottom: 0.1rem solid var(--grey-medium);
      }
    }

    .consultant-filter {
      margin-bottom: 1.6rem;

      .combo-field {
        max-width: 34rem;

        .common-form-label {
          font-size: 1.2rem;
          @extend .pro-semibold !optional;
          color:var(--text-secondary);
          text-transform: uppercase;
          margin-bottom: 1rem;
          padding-left: 2rem;
        }
      }
    }
  }
}
