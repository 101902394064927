.text-area-field {
  $text-area-field-label-color: var(--text-primary-dark) !default;
  position: relative;
  
  .common-form-label {
    margin-bottom: 0.5rem;
  }

  .textarea-container {
    width: 100%;
    height: 100%;

    > textarea {
      padding: 10px;
      border-radius: 3px;
      border: 1px solid var(--grey);
      background-color: transparent;
      resize: none;
      width: 100%;
      height: 100%;
      -webkit-appearance: none;
      @extend .common-form-input !optional;
    }

    > textarea::placeholder {
      @extend .common-form-placeholder !optional;
    }
  }
}

.textarea-counter {
  position: absolute;
  bottom: -12px;
  right: 12px;
  font-size: 11px;
  // text-align: right;
  color: var(--text-secondary)
}
