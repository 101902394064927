$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

.beneficiary-parcours-left-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 45%;
  // max-width: 45%;
  padding-right: 36px;

  @media screen and (max-width: $lg) {
    width: 100%;
    padding: 0px;
  }

  .header {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--grey-medium);
    @media screen and (max-width: $lg) {
      border-bottom: none;
      padding-bottom: 0px;
    }

    .parcours-title-desktop {
      display: block;
      @media screen and (max-width: $lg) {
        display: none;
      }
    }
    .parcours-title-mobile {
      display: none;
      @media screen and (max-width: $lg) {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }

    h1 {
      @extend .headline-1 !optional;
      font-size: 24px;
      line-height: 32px;
    }

    .parcours-informations {
      display: flex;
      margin-right: 24px;
    }

    .parcours-description {
      @extend .primary-default !optional;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .steps {
    height: 100%;
    position: relative;
  }
}
