.scrollchor-link {
  text-decoration: none;
}

.export-iframe {
  border: 0;
  width: 100%;
}

.action-popin-header {
  display: none;
}
