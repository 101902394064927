.manager-statistics {
  .tabbed-content-layout {
    > section {
      flex-flow: row wrap;
      align-items: flex-start;
      margin: 0 -1.5rem;
      width: 100%;

      > * {
        margin: 0 1.5rem;
        flex-basis: 22rem;
      }
    }

    .stats-wrapper {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 100%;
      .year-filter {
        align-self: flex-end;
        margin-bottom: 1.6rem;
      }
    }
  }
}
