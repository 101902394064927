$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

.centraltest-card {
  display: flex;
  flex-direction: column;
  background: var(--pure-white);
  border-radius: 6px;
  min-width: 400px;
  min-height: 160px;
  padding: 12px;
  @media screen and (max-width: $md) {
    min-width: 100%;
  }

  h2 {
    font-size: 14px;
    color: var(--text-primary);
    text-transform: uppercase;
  }

  > .icon-card-title {
    margin-left: 10px;
    margin-bottom: 8px;
  }

  > .card {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;

    > .optin {
      margin: 2rem;
      display: flex;
      align-items: center;

      > a {
        font-size: 12px;
      }

      > a:hover {
        text-decoration: underline;
      }
    }

    > .label {
      font-size: 1.2rem;
      @extend .pro-semibold !optional;
      color: var(--text-secondary);
      text-transform: uppercase;
    }

    > .status {
      @extend .pro-semibold !optional;
      font-size: 2rem;
      margin-top: 1rem;
      margin-bottom: auto;
      width: 100%;

      > .pill {
        align-items: center;
        font-size: 13px;
        display: inline-block;
        height: 26px;
        white-space: nowrap;
        width: 100%;

        position: relative;
        border-radius: 3px;
        overflow: hidden;
        padding: 3px 12px 3px 20px;
        text-overflow: ellipsis;
        color: #595959;

        word-break: break-word;

        &:before {
          border-radius: 50%;
          content: '';
          height: 10px;
          left: 6px;
          margin-top: -5px;
          position: absolute;
          top: 50%;
          width: 10px;
        }
      }

      > .state {
        display: flex;
        flex-direction: column;
        align-items: center;

         .description--error {
          font-size: 13px;
          color: crimson;
        }

        > .error {
          width: 3rem;
          height: 3rem;
        }

        > .refresh {
          margin-top: 1rem;
          width: 5rem;
          height: 5rem;
          & svg {
            width: 5rem;
            height: 5rem;
          }
        }

        > .check {
          margin-top: 1rem;
          width: 5rem;
          height: 5rem;
          & svg {
            width: 5rem;
            height: 5rem;
            padding: 1rem;
            background-color: #6bc167;
            border-radius: 100%;
          }
        }

        > .description--default {
          font-size: 13px;
          margin-top: 0.5rem;
        }

        

        > .description--check {
          font-size: 16px;
          margin-top: 1rem;
        }

        > .description--pending {
          font-size: 16px;
          margin-top: 1rem;
        }
      }

      > .pill--on {
        background: #b4eda0;
        color: #6bc167;
      }

      > .pill--on:before {
        background: #6bc167;
      }

      > .pill--off {
        background: #c7c7c7;
        color: #444444;
      }
      > .pill--off:before {
        background: #444444;
      }

      > .pill--pending {
        background: #b6e8ff;
        color: #3c77f8;
      }
      > .pill--pending:before {
        background: #3c77f8;
      }
    }

    > .primary-button {
      margin-bottom: 10px;
    }
  }
}
