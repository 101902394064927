@import '../../../../../stylesheets/common/fonts';

.feedback-ressource {
    display: flex;
    justify-content: space-between;

    p {
        @extend .headline-2 !optional;
        color: var(--text-primary);
    }
}