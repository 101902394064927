.centraltest-office-manager-card {
  .icon-card-title {
    width: 100%;
    padding-left: 5px;
    margin-bottom: 8px;

    .icon {
      width: 15px;
      display: inline-block;

      > svg {
        vertical-align: middle;
      }
    }

    .card-title {
      display: inline-block;
      color: var(--text-primary-dark);
      font-size: 1.2rem;
      text-transform: uppercase;
      @extend .pro-semibold !optional;
      vertical-align: middle;
      margin-left: 5px;
    }
  }

  > .card {
    background-color: var(--pure-white);
    border-radius: 3px;
    padding: 12px 15px 20px;
    text-align: center;
    margin-bottom: 10px;
    box-shadow: 0 4px 6px 0 rgba(112, 130, 243, 0.08);

    &:last-child {
      margin-bottom: 0;
    }

    > .card-title {
      color:var(--text-secondary);
      font-size: 1.2rem;
      text-transform: uppercase;
      @extend .pro-semibold !optional;
      margin-bottom: 12px;
    }

    .card-count {
      font-size: 2.0rem;
      min-width: 50px;
      @extend .pro-semibold !optional;
      color: var(--main-color);
    }

    .card-content-flex {
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 1rem;
      margin-top: 10px;
      padding-bottom: 2rem;
    }

    .card-content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      row-gap: 2rem;

      .icon-button .round-minus {
        width: 1.2rem;
      }

      .icon-button .round-plus {
        width: 1.2rem;
      }
    }
  }
}
