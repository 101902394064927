.data-table {
  width: 100%;
  margin-top: 1rem;
  border-collapse: collapse;
  border-collapse: separate;

  th,
  td {
    padding: 1rem;
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
  }

  th {
    background-color: var(--main-color-light);
    color: var(--blue-dark);
    text-transform: uppercase;
    border: none;
  }

  th#empty {
    width: 30px;
    background-color: var(--pure-white);
    border-radius: 0.5rem;
  }

  td {
    color: var(--text-primary);
    border-radius: 0.5rem;
    background-color: var(--pure-white);

    &#name,
    &#nameSynthesis {
      display: flex;
      gap: 0.5rem;

      svg {
        width: 36px;
        height: 36px;
      }

      .icon-button.trash svg {
        width: 18px !important;
      }

      .icon-button.trash svg g {
        stroke-width: 1;
      }

      p {
        margin-top: 0.75rem;
      }
    }

    &#download,
    &#actions {
      div {
        display: flex;
        justify-content: space-evenly;
      }
    }
  }

  tr.row-clickable {
    cursor: pointer;
  }

  tr.row-clickable:hover {
    background-color: #efeffe;
  }
}

.wrapper-table {
  overflow: auto;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--grey);
    border-radius: 34px;
    transition: background-color 0.3s;

    &::before {
      content: '';
      position: absolute;
      height: 18px;
      width: 18px;
      left: 4px;
      bottom: 3px;
      background-color: var(--main-color-lighter);
      border-radius: 50%;
      transition: transform 0.3s;
    }
  }

  input:checked + .slider {
    background-color: var(--main-color);

    &::before {
      transform: translateX(24px);
    }
  }
}
