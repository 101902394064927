@import '../../stylesheets/common/fonts';

$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

.calendar-page {
  width: 100%;

  .calendar-page-content {
    display: flex;
    flex-direction: row;
    gap: 12px;
    padding: 24px 0;
    position: relative;
    width: 100%;
    justify-content: space-between;

    @media screen and (max-width: $xl) {
      flex-direction: column;
      padding-top: 2.5rem;
    }

    .calendar-page-all-appointments {
      display: flex;
      flex-direction: column;
      gap: 12px;
    width: 70%;

    @media screen and (max-width: $xl) {
      width: 100%;
    }

      
      .calendar-page-all-confirmed-appointments {
        border-radius: 6px;
        border: 1px solid var(--grey-medium);
        background-color: var(--pure-white);
        padding: 24px;
        border-radius: 6px;
        // min-width: 60vw;
        .calendar-page-filters {
          display: flex;
          gap: 12px;
          padding-bottom: 5px;
        }
        
        .calendar-page-all-appointments-header {
          display: flex;
          justify-content: space-between;
          @media screen and (max-width: $md) {
            flex-direction: column;
            gap: 10px;
          }

          .calendar-page-filters {
            display: flex;
            gap: 12px;
          }
        }

        .all-appointments {
          margin-top: 24px;
          display: flex;
          flex-direction: column;
          gap: 12px;
          padding-top: 12px;
          min-height: fit-content;
          max-height: 80vh;
          overflow: scroll;
          @media screen and (max-width: $lg) {
            max-height: none;
          }

          .weekly-appointment {
            border-top: 1px solid var(--grey-medium);
            position: relative;

            .weekly-appointment-header {
              p {
                @extend .label-uppercase;
                position: absolute;
                background-color: var(--pure-white);
                width: fit-content;
                max-width: 250px;
                height: fit-content;
                top: -12px;
                left: calc(50% - 100px);
                padding: 0 12px;
                color: var(--text-secondary);
                text-transform: uppercase;
                font-size: 12px;
              }
            }

            .weekly-appointments-confirmed {
              display: flex;
              flex-direction: column;
              gap: 24px;
              padding: 24px;

              @media screen and (max-width: $md) {
                padding: 24px 0px;
              }

              .agenda-row {
                width: 100%;
                @media screen and (max-width: $lg) {
                  flex-direction: column-reverse;
                  // padding-top: 2.5rem;
                }
              }
  
              .appointment-with-date {
                display: flex;
                align-items: center;
                gap: 24px;

                @media screen and (max-width: $md) {
                  align-items: start;
                  gap: 10px;
                }
              }
  
              .appointment-without-date {
                margin-left: 84px;
              }
            }

          }
        }

        .all-appointments::-webkit-scrollbar {
          display: none;
        }

        .all-appointments {
          -ms-overflow-style: none;
          overflow: -moz-scrollbars-none;
        }

        .no-appointment {
          margin-top: 24px;
        }
      }

      .calendar-page-pending-appointments {
        border: 1px solid var(--orange);
        background-color: var(--orange-light);
        border-radius: 6px;

        .calendar-page-pending-appointments-header {
          display: flex;
          align-items: center;
          gap: 12px;
          padding: 24px 24px 12px 24px;

          .bell-icon {
            display: flex;
            padding: 6px;
            align-items: center;
            justify-content: center;
            border-radius: 3px;
            background-color: var(--orange);
          }

          h2 {
            @extend .headline-2;
            color: var(--text-primary);
            font-size: 18px;
          }
        }

        .calendar-page-all-pending-appointments {
          padding: 24px 0;
          display: flex;
          flex-direction: column;
          gap: 12px;
          background-color: var(--pure-white);
            border-radius: 6px;


          .pending-appointments {
            display: flex;
            align-items: center;
            gap: 12px;
            padding: 0 24px;
  
            .agenda-row {
              width: 100%;
              @media screen and (max-width: $xl) {
                flex-direction: column-reverse;
                // padding-top: 2.5rem;
              }
            }
          }
        }

      }

    }

    .calendar-page-right-side {
      border: 1px solid var(--grey-medium);
      background-color: var(--pure-white);
      width: 30%;
      padding: 24px;

      height: fit-content;
      position: sticky;
      top: 20px;

      @media screen and (max-width: $xl) {
        width: 100%;
        position: static;

        max-height: 50%;

      }
    }
  }


}

a {
  color: var(--text-primary)
}