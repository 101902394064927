.experience-card {
  background: var(--pure-white);
  box-shadow: 0 4px 6px 0 rgba(112, 130, 243, 0.08);
  padding: 12px;
  border-radius: 6px;

  .experience-card-content {
    padding: 12px;

    > div {
      margin-bottom: 1rem;
    }
  }
}
