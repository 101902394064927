$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

.cabinet-card {
  .card {
    display: flex;
    justify-content: space-around;
    @media screen and (max-width: $md) {
      flex-direction: column;
      gap: 12px;
    }
    header {
      background-color: var(--pure-white);
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-width: 350px;
      min-height: 120px;
      gap: 24px;

      @media screen and (max-width: $md) {
        min-width: unset;
      }
      img {
        max-height: 6rem;
        max-height: 6rem;
        max-width: 10rem;
        margin-top: 10px;
        margin-bottom: 10px;
        cursor: pointer;
      }

      .circle-button {
        position: relative;
      }

      .card-name-cabinet {
        display: flex;
        flex-direction: column;
        align-items: start;

        .input-field {
          min-width: 250px;

          input {
            text-align: center;
          }

          ::-webkit-input-placeholder {
            text-align: center;
          }

          :-moz-placeholder {
            text-align: center;
          }
        }
      }
    }

    section {
      display: flex;
      flex-direction: column;
      gap: 24px;
      background-color: var(--pure-white);
      border-radius: 6px;
      min-width: 350px;
      min-height: 120px;
      padding: 24px;
      @media screen and (max-width: $md) {
        min-width: unset;
      }
    }
  }
}
