.wrapper-form {
  .navigation {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    margin: 1rem 0;

    button {
      background-color: var(--main-color);
      color: var(--main-color-lighter);
      border: none;
      cursor: pointer;
    }
    button:disabled {
      background-color: var(--grey);
    }

    button:nth-child(1) {
      border-radius: 100% 0.5rem 0.5rem 100%;
    }
    button:nth-child(2) {
      border-radius: 0.5rem 100% 100% 0.5rem;
    }

    .chevron_right,
    .chevron_left {
      translate: 0 15%;
    }
  }
  .stepsBar {
    display: flex;
    width: 100%;
    height: 12px;
    margin: 0.5rem 0;
    border: 1px solid var(--grey-medium);
    border-radius: 0.5rem;

    .current-step-bar {
      position: relative;
      height: 100%;
      background-color: var(--main-color-dark);
      transition: background-color 0.3s ease-in-out;
    }
    .current-step-bar::before {
      content: '';
      position: absolute;
      bottom: -0.75rem;
      left: 50%;
      width: 4px;
      height: 4px;
      background-color: red;
      border-radius: 0.1rem;
      transition: background-color 0.3s ease-in-out;
    }
    .step-bar {
      height: 100%;
      background-color: transparent;
      transition: background-color 0.3s ease-in-out;
    }

    .current-step-bar:nth-child(1),
    .step-bar:nth-child(1) {
      border-radius: 0.5rem 0 0 0.5rem;
    }
    .current-step-bar:nth-last-child(1),
    .step-bar:nth-last-child(1) {
      border-radius: 0 0.5rem 0.5rem 0;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    padding: 24px 0;

    .title {
      display: flex;

      p {
        margin: 0.5rem 0 !important;
        font-family: 'SourceSansPro-Regular';
      }
      p:nth-child(1) {
        display: flex;
        align-items: center;
        justify-content: center;
        // min-width: 1.5rem;
        padding: 0 0.5rem;
        font-size: 12px;
        color: var(--main-color-lighter);
        background-color: var(--main-color);
        border-radius: 0.5rem;
        margin-right: 0.5rem !important;
      }
      p:nth-child(2) {
        font-size: 14px;
        font-weight: bold;
        color: var(--text-secondary);
      }
    }
  }
}
