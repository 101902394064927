.admin-impersonate-page {
  flex: 1;
  
  .searchWrapper {
    display: flex;
    flex-direction: row;

   background-color: var(--pure-white);
    padding: 2rem;
    width: 45rem;
    box-shadow: 0 4px 6px 0 rgba(112, 130, 243, 0.08);

    .search-field {
      padding: 5px 0;
    }
    
    .primary-button {
      margin-left: 3rem;
    }
  }
  
  .usersWrapper {
    margin-top: 3rem;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > * {
      flex-basis: calc(50% - 4rem);
      margin: 0 2rem 3rem;
    }
  }
}
