$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

.accomp-collective-followup-project-card {
  background-color: var(--pure-white);
  box-shadow: 0 4px 6px 0 rgba(112, 130, 243, 0.08);
  padding: 12px;
  border-radius: 6px;

  .accomp-collective-followup-project-card-content {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .accomp-collective-followup-project-situation {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 24px;

      input[type='checkbox'] {
        display: none;
      }

      input[type='checkbox'] + label::before {
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 5px;
        background-color: transparent;
        border: 1px solid var(--main-color);
        border-radius: 50%;
      }

      input[type='checkbox']:checked + label::before {
        background-color: var(--main-color);
        border: 1px solid var(--main-color);
        text-align: center;
      }
    }

    .accomp-collective-followup-project-numbers {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 24px;
      @media screen and (max-width: $md) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}
