.disable-account-page {
  position: absolute;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: url('/static/images/background-login.jpg') no-repeat center;
  background-size: cover;

  > .logo {
    width: 130px;
    height: 65px;
    margin-bottom: 5.3rem;
    padding-top: 2rem;
  }

  > .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    padding: 4rem 4rem 3.5rem;

    .title {
      @extend .pro-semibold !optional;
      color: var(--text-primary-dark);
      margin-bottom: 3rem;
      font-size: 1.8rem;
    }

    .message {
      @extend .pro-semibold !optional;
      color: var(--main-color);
      font-size: 1.8rem;

      text-align: center;
      white-space: pre-line;

      margin-bottom: 3rem;
    }
  
    .back-login-button {
      @extend .pro-regular !optional;
      display: flex;
      align-items: center;
      cursor: pointer;
      
      > .icon {
        width: 1.2rem;
        height: 1.2rem;
        margin-right: 0.5rem;
        display: flex;
        align-items: center;
      }
  
      > .label {
        font-size: 1.4rem;
        color: #b0b0b0;
        display: inline-block;
        text-transform: uppercase;
      }
    }
  }
}
