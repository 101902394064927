.avatar-field {
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

  .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--grey-light);
    width: 80px;
    height: 80px;
    border-radius: 50%;
    position: relative;

  }

  .circle-button {
    top: -15px;
    position: relative;
    width: 30px;
    height: 30px;
    margin-bottom: -1.5rem;
  }

  .avatar-circle {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .loading-spinner__wrapper {
    position: relative;
    background: transparent;

    svg {
      height: 30px;
      width: 30px;
    }
  }
}
