.accomp-edition-card {
  background-color: var(--pure-white);
  box-shadow: 0 4px 6px 0 rgba(112, 130, 243, 0.08);
  padding: 12px;
  border-radius: 6px;

  .accomp-edition-card-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 12px;

    .flex-space-between-container {
      display: flex;
      justify-content: space-between;
      gap: 24px;
      width: 100%;

      .combo-field, .field-container, .input-field {
        width: 100%;
      }
    }

    .flex-container {
      display: flex;
      gap: 24px;
      width: 100%;
      align-items:end;

      .combo-field, .field-container, .input-field {
        width: 100%;
      }

    }

    .accompaniment-duration {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      gap: 24px;
      width: 100%;

      .input-field, .field-container  {
        width: 100%;
      }
    }
  }

}