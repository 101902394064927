.notification-list {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  width: 30rem;
  display: flex;
  flex-flow: column;
  align-items: center;
  cursor: pointer;
  z-index: 2000;

  > .info-page {
    margin: 1rem 0;
  }
}
