.consultant-form-module-preview-page {
  .popin-layout {
    section {
      display: flex;
      flex-direction: column;
      align-items: center;

      .questions {
        width: 60rem;

        > div, .quiz-group > .content > div {
          margin-top: 4rem;

          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
}
