.password-popin {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 8rem 5rem;
  width: 60rem;
  min-height: 30rem;
  border-radius: 0.3rem;
  background: var(--pure-white);

  > .close-button {
    position: absolute;
    top: 2.5rem;
    right: 2.5rem
  }

  > .title {
    @extend .pro-semibold !optional;

    margin-bottom: .4rem;
    font-size: 2rem;
    color: var(--main-color);
  }

  > .description {
    @extend .pro-regular !optional;

    margin-bottom: 2.6rem;
    font-size: 1.4rem;
    text-align: center;
    color: var(--text-primary-dark);
  }

  > form {
    width: 25rem;

    > .field-container {
      margin-bottom: 3rem;
      width: 100%;
    }

    > button {
      width: 100%;
      box-shadow: rgba(112, 130, 243, 0.5) 0 2px 4px 0;
    }
  }
}
