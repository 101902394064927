@import '../../../stylesheets/common/fonts';

.popin-add-group-filters {
    display: flex;
    gap: 12px;
    align-items: center;
}

.add-group-popin-content {
    margin-top: 24px;
    min-height: 200px;
    max-height: 500px;
    overflow: auto;

    .add-new-members {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .choose-existing-talent {
            .select-container {
                width: 100%;
            }
            
            label {
                @extend .secondary-default;
                font-size: 14px;
                text-transform: uppercase;
                color: var(--text-secondary);
            }
        }
        
        .add-new-talent {
            display: flex;
            flex-direction: column;
            gap: 12px;
            
            .add-new-talent-info {
                display: flex;
                gap: 24px;
                
                .input-field {
                    width: 100%;
                }
                
            }
            
            .radio-field {
                display: flex;
                align-items: center;
                
                .radio-container {
                    .label {
                        @extend .primary-default;
                        color: var(--text-primary);
                    }
                }
            }
            
            .add-new-talent-action {
                display: flex;
                justify-content: end;
                
                .primary-button {
                    padding: 3px 24px;
                }
            }
            
            label {
                @extend .secondary-default;
                font-size: 14px;
                text-transform: uppercase;
                color: var(--text-secondary);
            }

            .Dropdown-option {
                z-index: 1000;
            }
        }
        .new-members {
            .new-members-container {
                border-radius: 6px;
                padding: 12px 0;
                display: flex;
                gap: 6px;
                flex-wrap: wrap;
            }

            label {
                @extend .secondary-default;
                font-size: 14px;
                text-transform: uppercase;
                color: var(--main-color-dark);
            }
        }
        
        
    }
    
    
    .import-members {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .import-members-export-document {
            display: flex;
            flex-direction: column;
            gap: 12px;

            span {
                @extend .secondary-default;
                 color: var(--text-primary);
                 font-size: 14px;
                 max-width: 600px;

            }
    
            a {
                @extend .secondary-default;
                color: var(--main-color);
                font-size: 14px;
            }

            a:hover {
                text-decoration: underline;
            }
    
            .export-document {
                background-color: var(--grey-light);
                padding: 12px;
                border-radius: 6px;
                border: 1px solid var(--grey-medium);
                display: flex;
                align-items: center;
                gap: 12px;
            }
        }

        .import-members-import-document {
            display: flex;
            flex-direction: column;
            gap: 12px;
        }

        h2 {
            @extend .secondary-default;
            font-size: 14px;
            text-transform: uppercase;
            color: var(--text-secondary);
            font-weight: 700;
        }

            
        .import-document {
            background-color: var(--grey-light);
            padding: 12px;
            border-radius: 6px;
            border: 1px solid var(--grey-medium);
            display: flex;
            align-items: center;
            justify-content: space-between;

            .document-name {
            display: flex;
            align-items: center;
            gap: 12px;

                p {
                    @extend .secondary-default;
                    color: var(--main-color);
                    font-size: 14px;
                }
    
                p:hover {
                    text-decoration: underline;
                }
            }

            .icon {
                cursor: pointer;
            }

        }
    }
}