.user-attrib-row {
  $user-attribut-color: var(--main-color) !default;

  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  color: #575757;
  min-width: 400px;
  background: var(--pure-white);
  box-shadow: 0 3px 6px 0 rgba(112, 130, 243, 0.08);
  border-radius: 3px;

  .avatar-circle {
    height: 4.5rem;
    width: 4.5rem;
    margin-right: 10px;
  }

  .name {
    display: flex;
    flex: 3;
    @extend .pro-semibold !optional;
    font-size: 1.4rem;
  }

  .status {
    display: flex;
    @extend .pro-semibold !optional;
    font-size: 1.2rem;
    color: $user-attribut-color;
    flex: 2
  }

  .separator {
    background-color: #d9d9d9;
    width: 1px;
    height: 32px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .action {
    display: flex;
    flex: 2;
    flex-direction: column;
  }

}
