.parcours-step-game {
  $parcours-step-game-label-active-color: var(--text-primary-dark) !default;
  $parcours-step-game-label-disable-color: var(--text-secondary) !default;
  $parcours-step-game-text-color: var(--text-primary-dark) !default;

  display: flex;
  padding: 10px 10px 15px 10px;
 background-color: var(--pure-white);
  cursor: pointer;

  &.active {
    .label {
      color: $parcours-step-game-label-active-color;
    }
  }

  &.disable {
    .label {
      color: $parcours-step-game-label-disable-color;
    }
  }

  > .icons {
    display: flex;
    margin-right: 10px;

    [class^="icon-prog-state-"], [class^="icon-module-type-"] {
      .icon {
        width: 15px;
        height: 15px;
      }

      &:nth-child(1) {
        margin-right: 15px;
      }
    }
  }

  > .text-container {
    flex: 1;

    .label {
      width: 100%;
      font-size: 1.4rem;
      @extend .pro-semibold !optional;
      margin-bottom: 10px;
    }

    .text {
      width: 100%;
      font-size: 1.2rem;
      @extend .pro-light !optional;
      color: $parcours-step-game-text-color

    }
  }
}
