$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

.parcours-all-steps {
  height: auto;
  position: sticky;
  top: 20px;
  overflow-x: hidden;
  // height: 0;

  h3 {
    @extend .headline-3 !optional;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 12px;
    padding: 0 12px;
    @media screen and (max-width: $md) {
      display: none;
    }
  }

  .all-steps {
    display: flex;
    flex-direction: column;
    // padding: 0;
    gap: 12px;
    @media screen and (max-width: $lg) {
      flex-direction: row;
      padding-bottom: 50px;
      overflow-x: scroll;

    }
  }

  .step-card {
    background-color: var(--pure-white);
    padding: 12px;
    display: flex;
    gap: 12px;
    cursor: pointer;
    position: relative;
    border-radius: 6px;
    border: 1px solid var(--grey-medium);
    @media screen and (max-width: $lg) {
        max-width: 70%;
      }
    // min-width: 120px;

    .step-illustration {
      width: 100px;
      height: 100px;
      width: 100px;
      border-radius: 6px;
      background-size: cover;
      aspect-ratio: 1;
      @media screen and (max-width: $md) {
        display: none;
      }
    }

    .step-title {
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: calc(100% - 150px);
      min-width: 100px;
      @media screen and (max-width: $md) {
        width: 100%;
      }

      .tag-container {
        width: fit-content;
      }

      h2 {
        @extend .headline-2 !optional;
        font-size: 18px;
        line-height: 26px;
        text-wrap: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  .done {
    .step-illustration {
      background-color: var(--main-color-light);
    }
    h2 {
      color: var(--text-secondary);
    }
  }

  .step-active {
    border: 2px solid var(--main-color);
    background-color: var(--main-color-lighter);
  }
}
