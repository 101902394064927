@import '../../../stylesheets/common/fonts';

.new-tag {
    padding: 3px 12px;
    border-radius: 24px;
    border: none;
    cursor: inherit;
    display: flex;
    align-items: center;
    gap: 6px;
    width: fit-content;

    p {
        @extend .tertiary-default;
        font-size: 12px;
    }

    .new-tag-icon {
        height: 8px;
        width: 8px;
        cursor: pointer;

        >.icon {
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                height: 8px;
                width: 8px;
            }
        }
    }

}