$font-path: "../../static/fonts/";

@mixin textStyle($classname, $name, $weight) {
  $fontpath: "#{$font-path}#{$name}/#{$name}";
  @font-face {
    font-family: "#{$name}";
    src: url('#{$fontpath}.eot');
    src: url('#{$fontpath}.eot?#iefix') format('embedded-opentype'),
    url('#{$fontpath}.woff') format('woff'),
    url('#{$fontpath}.ttf') format('truetype'),
    url('#{$fontpath}.svg') format('svg');
    font-weight: $weight;
    font-style: normal;
  }

  .#{$classname} {
    font-family: $name;
  }
}

// New text styles
@include textStyle('headline-1', 'SourceSansPro-Semibold', 600);
@include textStyle('headline-2', 'SourceSansPro-Semibold', 600);
@include textStyle('headline-3', 'SourceSansPro-Semibold', 600);

@include textStyle('primary-default', 'SourceSansPro-Regular', 400);
@include textStyle('primary-strong', 'SourceSansPro-Bold', 700);
@include textStyle('primary-link', 'SourceSansPro-Regular', 400);

@include textStyle('secondary-default', 'SourceSansPro-Regular', 400);
@include textStyle('secondary-strong', 'SourceSansPro-Bold', 700);
@include textStyle('secondary-link', 'SourceSansPro-Regular', 400);

@include textStyle('tertiary-default', 'SourceSansPro-Regular', 400);
@include textStyle('tertiary-strong', 'SourceSansPro-Bold', 700);
@include textStyle('tertiary-link', 'SourceSansPro-Regular', 400);

@include textStyle('quaternary-default', 'SourceSansPro-Regular', 400);
@include textStyle('quaternary-strong', 'SourceSansPro-Bold', 700);
@include textStyle('quaternary-link', 'SourceSansPro-Regular', 400);

@include textStyle('label-uppercase', 'SourceSansPro-Bold', 600);
@include textStyle('label-lowercase', 'SourceSansPro-Regular', 400);


