.drop-file-popin {
  > .action-popin {
    > .content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      > * {
        width: 34rem;
        max-width: 34rem;
      }

      > .input-field {
        margin-bottom: 2rem;
      }

      .border {
        background:var(--text-secondary);
      }

      .drag-n-drop {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px dashed var(--main-color);
        border-radius: 6px;
        background-color: var(--main-color-lighter);
        padding: 24px;
        margin-bottom: 2rem;

        .drop-step-zone {
          border: none;
          background-color: none;
          padding: 0;
        }
      }

      .combo-icon {
        margin-bottom: 2rem;
        z-index: 10;

        > .select-container {
          position: relative;
          z-index: 0;
          width: 100%;
          color: var(--text-primary-dark);
          background: transparent;
          @extend .pro-semibold !optional;
          font-size: 1.4rem;

          .Dropdown-menu {
            position: absolute;
          }

          .Dropdown-root {
            width: 100%;
            margin-top: .3rem;
            cursor: default;
          }

          .Dropdown-menu {
            background: white;
            width: 100%;
            box-shadow: 0 3px 6px 0 rgba(112, 130, 243, 0.08);
            margin-top: -.1rem;
          }

          .Dropdown-option {
            display: flex;
            align-items: center;
            height: 3rem;
            font-size: 1.2rem;
            padding-left: 2.5rem;

            &:hover {
              background-color: rgba(112, 130, 243, 0.10);
            }
          }

          > .icon {
            position: absolute;
            z-index: -1;
            bottom: .5rem;
            right: 0;
            width: 1.5rem;
          }

          .document {
            // @include IconComboBg('document.svg');
          }

          .video {
            // @include IconComboBg('play-circle.svg');
          }

          .link {
            // @include IconComboBg('link.svg');
          }

          .to_return_document {
            // @include IconComboBg('repeat.svg');
          }

          .Dropdown-placeholder {
            display: flex;
            align-items: center;
            height: 2.5rem;
            background-size: auto 1.5rem;
            background-position: center left;
            border-bottom: 1px solid var(--grey);
          }
        }
      }

      .or {
        margin: 0.5rem;
        text-transform: lowercase;
        color:var(--text-secondary);
        font-size: 1.4rem;
        @extend .pro-regular !optional;
      }

      .file {
        display: flex;
        align-items: center;
       background-color: var(--pure-white);
        padding: 1rem 1.3rem;
        color: var(--text-primary-dark);
        @extend .pro-semibold !optional;
        font-size: 1.4rem;
        box-shadow: 0 3px 6px 0 rgba(112, 130, 243, 0.08);

        > .document {
          padding-right: 0.7rem;
          height: 1.5rem;
          width: 2rem;
          display: flex;
        }

        > .name {
          flex: 1;
        }

        > .close {
          margin-left: 1rem;
          width: 1.2rem;

          svg {
            min-width: 1rem;
          }
        }

        > .preview2 {
          width: 1.7rem;
          margin-left: 1rem;
        }
      }

      .drop-step-zone,
      .primary-button {
        width: 100%;
      }

      .text-area-field {
        textarea {
          height: 10rem;
        }
      }
    }

    .input-field-choices {
      display: flex;
      align-items: center;
      gap: 24px;
      margin-bottom: 2rem;

      label {
        cursor: pointer;
        display: flex;
        gap: 9px;
        align-items: center;
        font-size: 14px;
        color: var(--text-primary);

        input[type="radio"] {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          width: 15px; /* largeur de 9 pixels */
          height: 15px;
          border-radius: 50%;
          border: 1px solid var(--main-color); /* bordure rouge */
          outline: none;
          position: relative;
          // top: 3px;
        }
        
        input[type="radio"]:checked::before {
          position: absolute;
          top: 2px;
          left: 2px;
          width: 9px; /* diamètre de la coche de 5 pixels */
          height: 9px;
          border-radius: 50%;
          background-color: var(--main-color); /* cocher rouge */
          content: "";
        }
      }
    }

    .drop-file-popin-actions {
      display: flex;
      gap: 12px;
      margin-top: 28px;
    }
  }

  .url-fields {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    > .text-area-field {
      flex: 1;
    }

    > .icon-button {
      width: 2rem;
      height: 2rem;
      margin-left: 1rem;
    }
  }
  .loading-spinner__wrapper {
    position: relative;
    background: transparent;

    svg {
      height: 45px;
    }
  }
}
