.parcours-timeline-steps {
    position: relative;

    h3 {
        text-align: center;
        font-size: 14px;
        height: 20px;
    }

    .timeline {
        display: flex;
        align-items: end;

        button {
            width: 100%;
            height: 15px;
            background-color: transparent;
            border: none;
            position: relative;
            border-top: 1px solid var(--grey-medium);
            border-bottom: 1px solid var(--grey-medium);
            border-right: 1px solid var(--grey-medium);
            display: flex;
            justify-content: center;
            cursor: pointer;
            

            span {
                font-size: 12px;
                line-height: 1;
            }
        }

        button:hover {
            background-color: var(--grey-medium);
        }

        .tooltip {
            display: none;
            width: 400px;
            position: absolute;
            bottom: 20px;
            left: calc(50% - 200px);
            z-index: 1;
            border-radius: 6px;
            padding: 6px;

            span {
                font-size: 14px;
            }

        }

        .dot {
            position: absolute;
            height: 5px;
            width: 5px;
            border-radius: 50%;
            background-color: red;
            bottom: -10px;
        }

        .tooltip::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: var(--grey-medium) transparent transparent transparent;
        }

        button:hover .tooltip {
            display: block;
            background-color: var(--grey-medium);
        }

        button.active {
            background-color: var(--main-color);
            border-color: var(--main-color);
            border-style: solid;
            border-width: 1px 1px 1px 0;
            border-right: 1px solid var(--grey-medium);
        }


        button.current-question-active {
            background-color: var(--main-color-dark);
            border-color: var(--main-color-dark);
            border-style: solid;
            border-width: 1px 1px 1px 0;
            border-right: 1px solid var(--grey-medium);
        }

        button.active:hover {
            background-color: var(--main-color);
        }

        button.active:first-child {
            border: 1px solid var(--main-color);
            border-right: 1px solid var(--grey-medium);
            border-radius: 6px 0px 0px 6px;
        }
        button.active:last-child {
            border: 1px solid var(--main-color);
            border-radius: 0px 6px 6px 0px;
        }

        button:first-child {
            border-left: 1px solid var(--grey-medium);
            border-radius: 6px 0px 0px 6px;
        }

        button:last-child {
            // border: 1px solid var(--grey-medium);
            border-radius: 0px 6px 6px 0px;
        }

    }

}