.centraltest-confirmation-popin {
  $text-color: var(--text-primary-dark);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 3px;
  overflow: hidden;
  background-color: var(--grey-light);
 

  > .action-popin-header {
    width: 100%;

    .icon {
      width: 2.2rem;
      display: flex;
    }
  }

  > .loader {
    margin: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > svg {
      margin-bottom: 1rem;
      height: 32px;
    }
  }

  > .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 2rem;

    > .combo-field {
      width: 100%;
      margin-bottom: 2rem;
    }

    > .text {
      text-align: center;
      width: 90%;
      color: $text-color;
      font-size: 1.4rem;
      @extend .pro-regular !optional;
      margin-bottom: 30px;
    }

    > .buttons {
      display: flex;

      .primary-button {
        min-width: 120px;

        &:nth-child(1) {
          margin-right: 7px;
        }

        &:nth-child(2) {
          margin-left: 7px;
        }
      }
    }
  }

}
