$step-color: #6f80f2;

.step {
  display: flex;
  padding: 0 5px;
  min-width: 20px;
  min-height: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  @extend .pro-regular !optional;
  font-size: 1.4rem;
  background-color: $step-color;
  border: 1px solid $step-color;
  border-radius: 4px;
  color: var(--pure-white);

  &.outline {
    background-color: transparent;
    color: $step-color;
  }
}
