.module-edit-popin {
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-width: 720px;
  padding-bottom: 24px;

  .popin-header {
    background-color: var(--main-color);
    padding: 12px 24px;
    border-radius: 6px 6px 0 0;

    h1 {
      color: var(--pure-white);
      font-size: 24px;
      font-weight: 600;
      text-transform: lowercase;
    }

    h1:first-letter {
      text-transform: uppercase;
  }
  }

  .module-edit-content {
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .module-edit-content-children {
      display: flex;
      flex-direction: column;
      gap: 3px;
      p {
        font-size: 1.2rem;
        color: var(--text-primary-dark);
      }
    }

    .module-edit-feedback, .module-edit-synthesis {
      display: flex;
      flex-direction: column;

      .text-area-feedback, .text-area-synthesis {
        outline: none;
        border: 1px solid var(--grey-medium);
        border-radius: 6px;
        height: 200px;  
        padding: 6px 12px;
        resize: none;
        font-size: 1.4rem;
        color: var(--text-primary-dark);;
      }
    }

    .module-edit-popin-options {
      display: flex;
      flex-direction: column;

      .input-field-choices {
        display: flex;
        align-items: center;
        gap: 24px;
        margin-bottom: 2rem;

        label {
          cursor: pointer;
          display: flex;
          gap: 9px;
          align-items: center;
          font-size: 14px;
          color: var(--text-primary);

          input[type="radio"] {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            border: 1px solid var(--main-color);
            outline: none;
            position: relative;
            // top: 3px;
          }

          input[type="radio"]:checked::before {
            position: absolute;
            top: 2px;
            left: 2px;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background-color: var(--main-color);
            content: "";
          }
        }
      }

      .document-upload {
        display: flex;
        justify-content: space-between;
      }

      .delete-document-upload {
        cursor: pointer;
      }
    }

    .url-fields {
      text-align: center;
      background-color: var(--main-color-lighter);
      border: 1px dashed var(--main-color);
      border-radius: 6px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 12px;
      padding: 24px 0;
      .drop-step-zone {
        border: none;
        padding: 0;

      }
      .or {
        color: var(--text-secondary)
      }

      .primary-button {
        align-self: center;
        width: fit-content;
      }
    }

  }

  .module-edit-actions {
    display: flex;
    justify-content: center;
    gap: 12px;
  }
}