$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

.consultant-beneficiary-accompaniment-page {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .primary-button {
        width: fit-content;
        display: flex;
        align-self: end;
    }
    .consultant-beneficiary-accompaniment-content {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .consultant-beneficiary-accompaniment-header {
            display: flex;
            flex-direction: column;
            gap: 24px;
        }
        .consultant-beneficiary-accompaniment-type {
            width: 80%;
            @media screen and (max-width: $lg) {
                width: 100%;
                
            }
        }
       .consultant-beneficiary-accompaniment-collective {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 24px; 
            @media screen and (max-width: $lg) {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }
}