.accomp-collective-synthese-co-card {
    background-color: var(--pure-white);
    box-shadow: 0 4px 6px 0 rgba(112, 130, 243, 0.08);
    padding: 12px;
    border-radius: 6px;
    .accomp-collective-synthese-co-card-header {
        display: flex;
        justify-content: space-between;
    }

    .accomp-collective-synthese-co-card-content {
        padding-top: 25px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .accomp-collective-synthese-co-card-item {
            width: 100%;
            height: auto;
            padding: 18px 24px;
            display: flex;
            justify-content: space-between;
            // cursor: pointer;
            background-color: var(--main-color-lighter);
            border-radius: 5px;
            .accomp-collective-synthese-co-card-item-actions {
                display: flex;
                gap: 25px;
            }
        }
    }
}

.accomp-collective-synthese-co-popin {
    display: flex;
    flex-direction: column;
    gap: 12px;
}