.beneficiary-tally-sheet-page {
  background-color: var(--mytalents-theme);
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .img-wrapper {
    width: 100%;
    position: absolute;
    left: 0px;
    bottom: 0px;
    z-index: 0;
    img {
        width: 100%;
        height: 100vh;
        position: absolute;
        left: 0px;
        bottom: 0px;
        z-index: 0;
        object-fit: cover;
      }
      div {
        width: 100vw;
        height: 100vh;
        position: absolute;
        left: 0px;
        bottom: 0px;
        z-index: 0;
        background-color: #17215aed;
      }
  }

  

  .tallysheet-wrapper {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    height: 100%;
    width: 100%;

    .logo {
        width: 200px;
        height: auto;
        margin-bottom: 5.3rem;
        padding-top: 2rem;
      }
    
      .modal-tallysheet {
        background-color: var(--pure-white);
        
        padding: 24px;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;

        .tallysheet-message-wrapper {
          display: flex;
          justify-content: center;
          padding-bottom: 24px;
          width: 100%;
          border-bottom: 1px solid var(--grey-medium);
        }

        .tallysheet-input-wrapper {
          display: flex;
          flex-direction: column;

          justify-content: center;

          gap: 24px;
        }
        
        .modal-tallysheet-title-success {
            font-size: 14px;
            color: #69a947;
            font-weight: 600;
            background-color: #69a94749;
            padding: 10px;
            border-radius: 15px;
        }

        .modal-tallysheet-title-warning {
            font-size: 14px;
            color: #f5a623;
            font-weight: 600;
            background-color: #f5a62349;
            padding: 10px;
            border-radius: 15px;
        }

        .modal-tallysheet-title-error {
            font-size: 14px;
            color: #f44336;
            font-weight: 600;
            background-color: #f4433649;
            padding: 10px;
            border-radius: 15px;
        }
      }
      p {
        padding-top: 24px;
      }
  }

  
}
