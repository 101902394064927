$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

.line-menu {
  display: flex;
  flex-flow: row wrap;
  color: var(--text-primary);
  // padding-bottom: 2.5rem;
  justify-content: flex-end;

  > .resources {
    display: flex;
    flex-direction: row;
    gap: 12px;
    justify-self: flex-end;

    > .resources-btn {
      border-radius: 6px;
      padding: 12px;
      align-items: center;
      gap: 12px;
      background-color: var(--pure-white);
      border: 1px solid var(--grey-medium);
      display: flex;
      flex-direction: row;
      font-size: 14px;
      position: relative;
      cursor: pointer;

      &.resources-profile {
        padding: 12px 24px;
      }

      &.resources-alert {
        // Edit to add bell icon to navbar
        display: none;

        .red-dot {
          background: var(--red);
          height: 0.9rem;
          width: 0.9rem;
          border-radius: 100%;
          position: absolute;
          right: 1.2rem;
          top: 1.2rem;
        }
      }

      &.resources-msg {
        > .red-dot {
          background: var(--red);
          height: 0.9rem;
          width: 0.9rem;
          border-radius: 100%;
          position: absolute;
          left: 2.7rem;
          top: 1.2rem;
          z-index: 10;
        }

        > .message-alert {
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 21px;
          text-align: center;
        }

        > .message-number {
          background: var(--red);
          color: var(--pure-white);
          padding: 0 5px 1px 5px;
          display: flex;
          border-radius: 3px;
          font-size: 9px;
          font-weight: 700;
          font-style: normal;
          line-height: 15px;
        }
      }

      > .resources-avatar {
        width: 24px;
        height: 24px;
        margin-right: 6px;

        & svg {
          width: 100%;
        }
      }

      > .icon {
        width: 24px;
        height: 24px;
      }

      > .message {
        width: 24px;
        height: 24px;
        box-shadow: unset;
        margin: unset;
        display: flex;
        align-self: center;
      }
    }
  }

  > .components {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 3px;
  }
}

.line-menu-components {
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  border-bottom: 1px solid var(--grey-medium);
  color: var(--text-primary);
  justify-content: flex-start;
  @media screen and (max-width: $md) {
    flex-direction: column;
    gap: 10px;
    border-bottom: 1px solid var(--grey-medium);

  }

  > .resources-link {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 6rem;
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: none;
    z-index: 420;
    cursor: grab;
    width: 100%;
    max-width: fit-content;
    // padding-bottom: 10px;

    @media screen and (max-width: $md) {
      // justify-content: space-between;
      gap: 0px;

    }

    &::-webkit-scrollbar {
      display: none;
    }

    > .line-menu-link {
      min-width: fit-content;
      @media screen and (max-width: $md) {
        flex: 0 0 auto;
        width: 40%;
      }
    }

    > .line-menu-link:not(:last-child) {
      // margin-right: 6rem;
      // justify-content: space-between;
      gap: 10px;
    }
  }

  > .components {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 3px;
    width: 100%;
    .primary-button {
      display: none;
    }
  }
}

.navbar-grabbing {
  cursor: grabbing !important;
}

@media screen and (max-width: $xxl) {
  // Top Row
  .line-menu {
    > .resources {
      gap: 15px;

      > .resources-btn {
        border: unset;
        background-color: unset;
        padding: 0;

        &.resources-profile {
          padding: 0;

          > .resources-avatar {
            margin-right: 0;
          }
        }

        &.resources-msg {
          > p {
            display: none;
          }

          > .message-number {
            display: none !important;
          }

          > .red-dot {
            top: 0.2rem;
            left: 1.6rem;
          }
        }

        &.resources-alert {
          > .red-dot {
            top: 0.2rem;
            left: 1.6rem;
          }
        }
      }

      > .resources-profile p {
        display: none;
      }

      > .resources-avatar p {
        display: none;
      }
    }
  }

  // Bottom Row
  // .line-menu-components {

  //   > .resources-link::-webkit-scrollbar {
  //     display: none;
  //   }

  //   > .resources-link {
  //     width: 100%;
  //     overflow-x: scroll;
  //     overflow-y: hidden;
  //     scrollbar-width: none;
  //     z-index: 420;
  //     cursor: grab;

  //     > .line-menu-link {
  //       min-width: fit-content;
  //       @media screen and (max-width: $md) {
  //         flex: 0 0 auto;
  //         width: 20%;
  //       }
  //     }
  //   }
  // }
}
