.stats-bar-chart {
  .title {
    font-size: 1.2rem;
    @extend .pro-semibold !optional;
    color: var(--text-secondary);
    text-transform: uppercase;
    margin-bottom: 1rem;
    padding-left: 2rem;
  }

  .no-data {
    font-size: 1.4rem;
    @extend .pro-semibold !optional;
    color: var(--text-primary-dark);
  }

  .chart-wrapper {
   background-color: var(--pure-white);
    box-shadow: 0 0.4rem 0.6rem 0 rgba(112, 130, 243, 0.08);
    border-radius: 0.3rem;
    padding: 2rem;

    svg {
      display: block;
      overflow: visible;
      width: 100%;
      height: 35em !important;
    }
  }
}
