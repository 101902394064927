.welcome-popin {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 4rem 5rem 3.5rem;

  .digit-line-step {
    margin-bottom: 3.5rem;
  }

  .title {
    color: var(--main-color);
    margin-bottom: 2.1rem;
  }

  .content {
    text-align: center;
    color: var(--text-primary-dark);
    margin-bottom: 3.6rem;
    white-space: pre-line;
  }

  .input-field,
  .combo-field,
  .date-field,
  .address {
    width: 70%;
    margin-bottom: 1rem;

    .input-field {
      width: 100%;
    }
  }

  .address {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .input-field:first-child {
      width: 100%;
      margin-bottom: 2rem;
    }

    .input-field:nth-child(2) {
      width: 47%;
    }

    .input-field:nth-child(3) {
      width: 47%;
    }
  }

  .button-wrapper {
    display: flex;

    .primary-button {
      width: 12rem;
      margin: 4rem 0.5rem;
    }
  }

  // @include mobile() {
  //   width: 90%;
  //   padding: 4rem 1.5rem 1rem;

  //   .digit-line-step {
  //     margin-bottom: 3rem;
  //   }

  //   .title {
  //     text-align: center;
  //   }
  //   .content {
  //     margin-bottom: 2rem;
  //   }

  //   .input-field,
  //   .combo-field,
  //   .date-field,
  //   .address {
  //     width: 80%;
  //   }

  //   .button-wrapper {
  //     .primary-button {
  //       margin: 2rem 0.5rem;
  //     }
  //   }
  // }
}
