.documents-accompaniment-card {
    background-color: var(--pure-white);
    box-shadow: 0 4px 6px 0 rgba(112, 130, 243, 0.08);
    padding: 12px;
    border-radius: 6px;

    .documents-accompaniment-content {
        display: flex;
        flex-direction: column;
        gap: 12px;

        p {
            font-size: 12px;
            color: var(--text-secondary);
            text-transform: uppercase;
        }

        .document-accompaniment {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 6px;
            border-radius: 6px;

            a {
                font-size: 14px;
            } 
            .document-accompaniment-actions {
                display: flex;
                gap: 6px 12px;
            cursor: pointer;

            }
        }

        .document-accompaniment:hover {
            background-color: var(--main-color-lighter);
        }
    }

    .drop-step-zone {
        background-color: var(--main-color-lighter);
    }
}