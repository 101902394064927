.parcours-step {
  display: flex;
  flex-direction: column;
 background-color: var(--pure-white);
  box-shadow: 0 4px 6px 0 rgba(112, 130, 243, 0.08);

  &.active {
    .parcours-step-label {
      color: var(--text-primary-dark);
    }
  }

  &.disable {
    .parcours-step-label {
      color:var(--text-secondary);
    }
  }

  &.editable {
    .parcours-step-body {
      margin-left: 0;
    }
  }

  .parcours-step-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;

    .parcours-step-edit-bar {
      margin-left: auto;
      display: flex;
      flex-direction: row;

      .icon-button {
        margin-left: 16px;
      }
    }

    .parcours-step-icons {
      display: flex;
      flex-direction: row;
      align-items: center;

      > * {
        width: 15px;
        margin-right: 15px;
      }
    }

    .parcours-step-title {
      flex-grow: 1;

      .parcours-step-label {
        font-size: 1.4rem;
        @extend .pro-semibold !optional;
      }

    }

  }

  .parcours-step-body {
    margin-left: 3rem;
    color: var(--text-primary-dark);
    font-size: 1.2rem;
    @extend .pro-light !optional;
    padding: 0 1rem 1rem;
  }

  .parcours-step-separator {
    height: 1px;
    flex-grow: 1;
    background-color: var(--grey);
  }

  .parcours-step-footer {
    display: flex;
    flex-direction: row;
    color: black;
    align-items: center;
    padding: 1rem;

    .parcours-step-footer-item {
      margin-right: 15px;
    }
  }

}
