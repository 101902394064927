$errorColor: var(--red);

.field-container {
  &.error {
    > .common-form-label {
      color: $errorColor
    }

    > .error {
      font-size: 1.1rem;
      color: $errorColor;
    }
  }
}
