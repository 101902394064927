.video-popin {
  border-radius: 3px;
  overflow: hidden;
  width: 100%;
  height: 100%;

  > .head {
    display: inline-block;
    width: 100%;
    padding: 12px 22px;
    background-color: var(--pure-white);
    position: relative;

    > .icon {
      display: inline-block;
      height: 15px;
      vertical-align: middle;
      margin-right: 12px;
      position: relative;
      top: 2px;

      svg {
        width: 15px;
        height: 15px;
      }
    }

    .title {
      display: inline-block;
      font-size: 2.0rem;
      @extend .pro-semibold !optional;
      color: var(--text-primary-dark);
      vertical-align: middle;
    }

    .circle-button {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);

      .icon {
        width: 1rem;
        height: 1rem;
      }
    }
  }

  .video-container {
    width: 100%;
    height: 100%;
    background: black;

    > div, .youtube {
      width: 100%;
      height: 100%;
    }
  }

  // @include mobile() {
  //   .video-container {
  //     iframe {
  //       width: 100% !important;
  //     }
  //   }
  // }
}
