.form-question-row {
  width: 100%;

  display: flex;
  align-items: center;
  height: 4rem;

  .question-number {
    position: relative;
    width: 4rem;
    height: 4rem;

    background-color: var(--pure-white);

    box-shadow: 0 0.3rem 0.6rem rgba(112, 130, 243, 0.08);

    > span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @extend .pro-semibold !optional; 
      font-size: 1.4rem;
      color: var(--text-primary-dark);
    }

    margin-right: 0.6rem;
  }

  .question-infos {
    display: flex;
    flex: 1;
    align-items: center;
    height: 4rem;

    background-color: var(--pure-white);

    box-shadow: 0 0.3rem 0.6rem rgba(112, 130, 243, 0.08);

    .question-icon {
      padding: 1rem;

      .icon {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    .question-title {
      flex: 1;

      &, & > * {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      @extend .pro-semibold !optional;
      font-size: 1.4rem;
      color: var(--text-primary-dark);
    }

    .question-actions {
      display: flex;

      .icon-button {
        padding: 1rem;

        .icon {
          width: 1.4rem;
          height: 1.4rem;
        }
      }
    }
  }
}
