$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

.beneficiary-profile-content {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .beneficiary-account-not-active {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      color: var(--red);
      font-weight: bold;
    }
  }
  .beneficiary-profile-content-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;

    @media screen and (max-width: $lg) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .beneficiary-profile-content-save {
    display: flex;
    justify-content: end;
    @media screen and (max-width: $md) {
      justify-content: center;
      
    }
  }

  .notification-container {
    z-index: 100;
  }
}
