.consultant-beneficiary-profile-page {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .action-button {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    font-size: 1.2rem;
    color: var(--text-secondary);

    > button {
      margin-right: 1rem;
    }
  }

  button {
    width: fit-content;
  }
}
