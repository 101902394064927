.beneficiary-page-aside {

    .logos {
      display: flex;
      gap: 12px;
        align-items: center;

      .separator {
        height: 22px;
        width: 1px;
        background-color: var(--grey-medium);
      }
      img {
        width: 50px;
        height: auto;
      }

      .office-logo {
        width: 90px;
        height: auto;
      }
    }
    .footer {
        display: flex;
        flex-direction: column;
        gap: 12px;
        .tutorials {
           @extend .pro-regular !optional;
           display: flex;
           gap: 12px;
           cursor: pointer;
         
           a {
             font-size: 1.4rem;
             color: var(--text-secondary);
             display: inline-block;
             text-transform: uppercase;
           }
         
           .icon {
             width: 18px;
             height: auto;
             line-height: 1;

             svg {
              width: 100%;
              height: 100%;
             }

           }
         
           &:active {
             opacity: 0.75;
           }
       }
    }
}
