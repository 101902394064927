// @import "../../../stylesheets/common/fonts";


.modal-header {
    background-color: var(--main-color);
    padding: 12px 24px;
    border-radius: 6px 6px 0 0;

    
    h1 {
        color: var(--pure-white);
        font-size: 24px;
    }
}

.modal-content {
    padding: 24px;
    max-height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.modal-content::-webkit-scrollbar {
    width: 10px; 
}

.modal-content::-webkit-scrollbar-thumb {
    background-color: var(--grey); 
    border-radius: 5px; 
}

.modal-content::-webkit-scrollbar-track {
    background-color: var(--grey-light);
}

.modal-footer {
    display: flex;
    justify-content: center;
    gap: 12px;
    padding-bottom: 24px;

    .primary-button.cancel {
        background-color: var(--pure-white);
        border: 1px solid var(--main-color);

        .label {
            color: var(--main-color);
        }
    }
}