@import '../../../stylesheets/common/fonts';

.missions-list-wrapper {
    overflow: auto;
  .missions-list {
    width: 100%;
    border-collapse: separate;
    border-spacing: 6px 12px;

    th,
    td {
      padding: 12px;
      text-align: center;
    }

    th {
      background-color: var(--main-color-light);
      @extend .primary-default;
      font-size: 14px;
      color: var(--blue-dark);
    }

    td {
      @extend .primary-default;
      font-size: 14px;
      color: var(--text-primary);
      border-radius: 6px;

      &:nth-child(1) {
        text-align: left;
      }
    }

    tr {
      background-color: var(--pure-white);
    }

    tr.row-clickable {
      cursor: pointer;
    }

    tr.row-clickable:hover {
      background-color: #efeffe;
    }

    .mission-name {
      @extend .primary-strong;
    }

    .mission-consultant,
    .mission-talents {
      display: flex;
      flex-wrap: wrap;
      gap: 6px;
      max-width: 450px;

      .new-tag {
        p {
          @extend .primary-default;
          font-size: 14px;
          color: var(--text-primary);
        }
      }
    }

    .mission-no-talents {
      text-align: left;
    }

    .actions-reporting {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 6px;
      cursor: auto;
    }

    .actions-reporting.dowloadable {
      height: 100%;
      cursor: pointer;
      a {
        color: var(--text-primary);
        font-weight: 600;
      }
    }
    .actions-reporting.dowloadable:hover {
      text-decoration: underline;
    }

    .actions-reporting.no-downloadable {
      cursor: auto;
      height: 100%;
      p {
        color: var(--text-secondary);
      }
    }

    .actions {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 24px;

      .icon {
        cursor: pointer;
      }

      .icon.preview {
        width: 16px;
        height: auto;
      }

      .icon.trash2 {
        width: auto;
        height: 16px;
      }
    }
    .div-icon {
      display: flex;
      justify-content: center;
    }
  }
}
