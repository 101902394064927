
@import '../../../../stylesheets/common/fonts';

.loading-spinner__wrapper {
    position: relative;
    background: transparent;
    z-index: 1001;
    svg {
      height: 30px;
      width: 30px;
    }

  }

.edit-appointment-popin-content{
    display: flex;
    flex-direction: column;
    gap: 24px;

    .edit-appointment-popin-cause {
        display: flex;
        gap: 12px;

    }

    .appointment-popin-content-checkboxes {
        display: flex;
        gap: 24px;
    }

    .appointment-popin-section-title {
        display: flex;
        align-items: center;
        gap: 12px;

        p {
            @extend .primary-strong;
            color: var(--text-primary);
            font-size: 16px;
        }
    }

    .appointment-popin-type {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .appointment-popin-content-sorter-buttons {
            display: flex;
            gap: 12px;
    
            .sorter-button {
                width: fit-content;
            }
        }

        .appointment-popin-content-checkboxes {
            display: flex;
            gap: 24px;
        }
    }

    .appointment-popin-time {
        display: flex;
        flex-direction: column;
        gap: 6px;

        .appointment-popin-inputs-time {
            display: flex;
            gap: 12px;
            width: 100%;

            .input-field {
                width: 100%;

                input[disabled] {
                    color: var(--text-secondary)
                }
            }
        }
    }

    .appointment-popin-consultant {
        display: flex;
        flex-direction: column;
        gap: 6px;
    }

    .appointment-popin-talents {
        display: flex;
        flex-direction: column;
        gap: 6px;
    }

    .videocall_url {
        display: block;
    }

    .videocall_url_hidden {
        display: none;
    }

    .appointment-popin-procedures {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .appointment-popin-procedures-1 {
            
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 6px;
        }
        .appointment-popin-procedures-2 {
            display: flex;
            gap: 12px;
            width: 100%;
            justify-content: space-between;

            .appointment-popin-procedures-address, .appointment-popin-procedures-phone {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 6px;
            }
    }
    }

    .appointment-popin-signature {
    display: flex;
        flex-direction: column;
        gap: 6px;
    }

    .appointment-popin-description, .appointment-popin-reason-canceled, .appointment-popin-reason-rescheduled {
        display: flex;
        flex-direction: column;
        gap: 6px;

        textarea {
            width: 100%;
            resize: none;
            height: 100px;
            border: 1px solid var(--grey-medium);
            border-radius: 6px;
            outline: none;
            color: var(--text-primary);
        }
        textarea[disabled] {
            color: var(--text-secondary);
        }
    }

    *[disabled] {
        color: var(--text-secondary);
    } 
}
