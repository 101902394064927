.quiz-combo {
  $quiz-combo-label-color: var(--text-primary-dark) !default;
  width: 100%;

  > .quiz-step {
    margin-bottom: 2rem;
  }

  .combo-field {
    padding-left: 4rem;
  }

  .quizz-combo-printable {
    display: none;
  }

  // @include mobile() {
  //   .combo-field {
  //     padding-left: 3rem;
  //   }
  // }

  @media print {
    .combo-field {
      display: none;
    }

    .quizz-combo-printable {
      display: block;
      word-break: break-all;
    }
  }
}
