.quiz-todo {
    p {
        color: var(--text-primary);
        font-weight: bold;
    }

    span {
        color: var(--text-secondary);
        font-size: 14px;
    }
}

.quiz-locked {
    p {
        color: var(--text-secondary);
    }

    span {
        color: var(--text-secondary);
        font-size: 14px;
    }
}

.quiz-title {
    color: var(--text-primary);
    font-weight: bold;
}

.quiz-inprogress {
    display: flex;
    align-items: center;
    gap: 12px;

    p {
        color: var(--text-secondary);
    }

    .progression-bar {
        background-color: var(--main-color-lighter);
        height: 6px;
        width: 100%;
        border-radius: 24px;
        position: relative;

        .progression-done-bar {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            border-radius: 24px;
            background-color: var(--main-color);
        }
    }
}