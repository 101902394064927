@import '../common/fonts';

.common-form-label {
  @extend .secondary-default;
  font-size: 14px;
  color: var(--text-secondary);

  .error & {
    @extend .secondary-default;
    color: var(--red);
  }
}

.common-form-placeholder {
  @extend .headline-2;
  font-size: 14px;
  color: var(--text-secondary);
}

.common-form-input {
  @extend .headline-2;
  font-size: 14px;
  color: var(--text-primary);
  border: none;
  padding: 3px 0;

  &:focus {
    outline: none;
  }
}

.common-form-separator {
  background: var(--grey-medium);
  display: flex;
  width: 100%;
  height: 1px;

  .error & {
    background: var(--red);
  }
}
