@import '../../../stylesheets/common/fonts';

.client-missions-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    
    .client-missions-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        h2 {
            @extend .headline-2;
            font-size: 14px;
            color: var(--text-primary);
            text-transform: uppercase;
        }

        .client-missions-filters {
            width: 600px;
            display: flex;
            align-items: center;
            gap: 12px;
        }
    } 
}