.beneficiary-invalidation-page {
  position: absolute;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: url('/static/images/background-login.jpg') no-repeat center;
  background-size: cover;

  > .logo {
    width: 130px;
    height: 65px;
    margin-bottom: 5.3rem;
    padding-top: 2rem;
  }
}
