html {
  min-height: 100%;
  height: 100%;
  font-size: 62.5%;
}

body {
  @extend .pro-regular !optional;

  margin: 0;
  min-height: 100%;
  font-size: 1.6rem;
  color: var(--text-primary-dark);
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

p {
  margin: 0;
  padding: 0;
}
