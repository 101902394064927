$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

.question-illustration {
    width: 400px;
    height: auto;
    display: flex;
    align-self: center;
    @media screen and (max-width: $sm) {
        width: auto;
      }
}