.consultant-my-groups-page {

    .consultant-my-groups-content {
    width: 100%;

        .consultant-my-groups-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .consultant-my-groups-filters {
            display: flex;
            justify-content: end;
            margin: 24px 0;
            gap: 24px;
            
            .search-field {
                max-width: 600px;
                align-self: end;
            }
        }

        .all-groups {}
    }
}