.form-edit-page {
    padding: 0;

    >.right-aside-layout {
        section {
            padding: 0;
            min-height: 100vh;

            .form-edit-page-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 24px 48px;
                background-color: var(--pure-white);

                .form-edit-page-title {
                    display: flex;
                    align-items: center;
                    gap: 20px;

                    input {
                        width: 500px;
                        border: none;
                        outline: none;
                        font-size: 24px;
                        color: var(--text-primary);
                    }

                    input::placeholer {
                        color: var(--text-secondary);
                    }

                    button {
                        cursor: pointer;
                        border: none;
                        background-color: transparent;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }

            .form-edit-page-content {
                display: flex;
                flex-direction: column;
                gap: 24px;
                padding: 24px 48px;
                height: 100%;

                p>span {
                    font-weight: 700;
                }
            }

            .droppable-area {
                display: flex;
                flex-direction: column;
                gap: 12px;
                padding: 3px;
            }

            .droppable-area-dragging {
                display: flex;
                flex-direction: column;
                gap: 12px;
                padding: 3px;
                border: 1px dashed var(--main-color);
            }

            .droppable-new-question {
                width: 100%;
                height: 48px;
                border: 1px dashed var(--main-color);
                background-color: var(--main-color-lighter);
                border-radius: 6px;
                display: flex;
                justify-content: center;
                align-items: center;
                align-self: center;
                color: var(--text-secondary)
            }

            .droppable-area-over {
                border: 1px dashed var(--main-color);
            }



        }
    }

    aside {
        min-width: 380px;

        .side-resource-selector {

            .side-resource-selector-content {
                .ressource-icon {
                    svg {
                        width: auto;
                        height: 32px;
                    }
                }
            }
        }
    }
}

.form-container-droppable-over {
    border: 1px dashed var(--main-color);
    border-radius: 6px;
    background-color: var(--main-color-lighter);
}