.manager-beneficiary-groups-page {
  flex: 1;

  .tabbed-content-layout {
    > section {
      > .circle-button {
        margin-bottom: 1.2rem;
      }
    }
  }
}