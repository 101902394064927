
$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

.beneficiary-job-page {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 24px;
    flex-flow: row wrap;
    // flex-wrap: ;
    .button-job {
        // width: 100%;
        // max-width: 100%;
        flex: 1 0 calc(30% - 10px);
        max-width: calc(30% - 10px);
        min-width: 500px;
        min-height: 200px;
        height: 200px;
        border-radius: 10px;
        border: none;
        background-color: var(--pure-white);
        display: flex;
        padding: 0;
        overflow: hidden;

        @media screen and (max-width: $xxl) {
            flex: 1 0 calc(50% - 10px);
            max-width: calc(100%);
            min-width: unset;
        }
            img {
                // width: calc(100% / 2);
                height: 100%;
                padding: 20px;
                aspect-ratio: 1;
                @media screen and (max-width: $sm) {
                    display: none;
                }
            }
        

        .job-info {
            width: 100%;
            height: 100%;
            padding: 12px 24px;

            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .title-div-job {
                display: flex;
                justify-content: space-between;

                h4 {
                    color: var(--text-primary);
                    font-weight: 700;
                    font-size: 22px;
                }

                p {
                    color: lightgray;
                }
            }

            .description-job {
                color: var(--text-secondary);
            }

            button {
                padding: 4px 32px;
                width: fit-content;
                height: fit-content;
                background-color: var(--pure-white);
                border: 2px solid #5066EF;
                border-radius: 5px;
                font-weight: 700;
                color: #5066EF;
                cursor: pointer;
            }
        }
    }
}