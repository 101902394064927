.popin-consultant-share-beneficiary {

    .consultant-share-beneficiary {
        display: flex;
        flex-direction: column;
        gap: 24px;
    
        .consultant-share-beneficiary-container,
        .consultant-unshare-beneficiary-container {
            display: flex;
            flex-direction: column;
    
            label {
                font-size: 12px;
                color: var(--grey-dark);
            }

            select {
                border: none;
                border-bottom: 1px solid var(--grey-light);
                outline: none;
                cursor: pointer;
            }


        }
    }


    .footer {
        display: flex;
        justify-content: center;
        margin-bottom: 24px;
    }
} 



