//
// Base
//
@import "base/reset";
@import "base/colors";
@import "base/mixins";
@import "base/sizes";
//
// Common
//
@import "common/common-form";
@import "common/parcours-step";
// @import "common/fonts";
@import "common/icon";
//
// Components
//
@import "pages";
@import "layouts";
@import "containers";
@import "components";



html {
  min-height: 100%;
  height: 100%;
  font-size: 62.5%; }

body {
  margin: 0;
  min-height: 100%;
  font-size: 1.6rem;
  color: var(--text-primary-dark); 
  
//   line-height: 1;
}

* {
  box-sizing: border-box;
  font-family: 'SourceSansPro-Regular', 'SourceSansPro-Bold';
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

p, h1, h2, h3 {
  margin: 0;
  padding: 0; 
  font-weight: initial;
}

li {
  list-style: none;
}
