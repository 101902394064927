[class^="icon-resource-type-"] {
  $icon-resource-type-grey-color: #585858 !default;
  $icon-resource-type-purple-color: var(--main-color) !default;
  width: 100%;
  height: 100%;

  > svg {
    width: 100%;
    height: 100%;
  }

  &.purple {
    svg * {
      stroke: $icon-resource-type-purple-color;
    }
  }

  &.grey {
    svg * {
      stroke: $icon-resource-type-grey-color;
    }
  }
}
