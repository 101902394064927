@import '../../../stylesheets/common/fonts';

.toaster-mytalents {
    background-color: var(--blue-dark);
    position: absolute;
    bottom: 12px;
    right: 12px;
    width: 420px;
    border-radius: 6px;
    display: flex;

    .toaster-mytalents-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px 0px 0px 6px;
        width: 50px;
    }

    .toaster-mytalents-orange {
        background-color: var(--orange);
    }

    .toaster-mytalents-red {
        background-color: var(--red);
    }

    .toaster-mytalents-green {
        background-color: var(--green);
    }

    .toaster-mytalents-content {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
        padding: 12px;

        h2,
        p {
            color: var(--pure-white);
            font-size: 14px;
        }

        h2 {
            @extend .secondary-strong;
        }

        p {
            @extend .secondary-default;
        }
    }

    .toaster-mytalents-close-button {
        padding: 12px;

        background-color: transparent;
        border: none;
        .icon {
            width: 18px;
            height: 18px;
        }
    }
}