@import '../../../stylesheets/common/fonts';

.quiz-text-area {
  width: 100%;
  margin-top: 1.8rem;

  p {
    @extend .label-lowercase;
    font-size: 12px;
    color: var(--text-secondary)
  }

  .text-area-field {
    height: 200px;

  }

  .text-area-field-printable {
    display: none;
  }


  .step {
    width: 2rem;
  }
}
