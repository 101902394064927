@import '../../../stylesheets/common/fonts';
$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

.mission-container {
    display: flex;
    align-items: top;
    justify-content: space-between;
    gap: 32px;
    @media screen and (max-width: $md) {
        flex-direction: column;
    }

    .mission-informations, .mission-participants {
        width: 100%;
        border-radius: 6px;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 18px;

        h2 {
            @extend .headline-2;
            font-size: 14px;
            color: var(--text-primary);
            text-transform: uppercase;
            color: var(--main-color);
        }

        .big-combo {
            .select-container {
                width: 100%;
            }
        }

        .mission-participants-title {
            display: flex;
            align-items: center;
            gap: 6px;
            .share-talents {
                cursor: pointer;
                font-size: 12px;
                padding: 6px;
                color: var(--main-color);
                // text-decoration: underline;
            }
        }
    }

    .mission-informations {
        background-color: var(--pure-white);
    }

    .mission-participants {
        background-color: var(--main-color-lighter);
        border: 1px dashed var(--main-color);
        
        .mission-participants-consultants {
            display: flex;
            flex-direction: column;
            gap: 12px;

            .mission-participants-all-consultants {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(200px, 200px));
                grid-gap: 24px;
                gap: 24px;
                margin: 24px 0;
            }
        }
    }
}