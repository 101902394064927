$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

.presentation-card {
  background-color: var(--pure-white);
  box-shadow: 0 0.4rem 0.6rem 0 rgba(112, 130, 243, 0.08);
  border-radius: 6px;
  padding: 12px;

  >.form {
    display: flex;
    flex-direction: row;
    padding: 12px;
    @media screen and (max-width: $md) {
      flex-direction: column;
      
    }


    .photo {
      margin-top: 2rem;
      margin-left: 0.5rem;
      margin-right: 3rem;
      @media screen and (max-width: $md) {
        margin: 0;
      }

      // .avatar-circle {
      //   width: 8rem;
      //   height: 8rem;
      // }
    }

    .fields {
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: 100%;

      .fields-flex {
        display: flex;
        gap: 24px;
        justify-content: space-between;

        .input-field,
        .field-container {
          width: 100%;
        }
      }

      .input-field {
        .birth_date {
          .date-selector-wrapper {
            width: 100%;
          }
        }
      }
    }
  }
}