.parcours-card {
  // width: 380px;
  border-radius: 12px;
  background-color: var(--pure-white);
  box-shadow: rgba(173, 173, 173, 0.1) 4px 1rem 1rem 0;
  cursor: pointer;
  
  .parcours-image {
    height: 12.5rem;
    border-radius: 12px 12px 0 0;
    background-size: cover;
  }

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: var(--pure-white);
    border-radius: 0 0 12px 12px;
    overflow: hidden;
    @media screen and (max-width: 640px) {
      padding: 2.5rem;
    }

    .label {
      @extend .pro-semibold !optional;
      color: var(--text-primary);
      margin-bottom: 24px;
      min-height: 60px;
    }

    .course-informations {
      display: flex;
      gap: 24px;

      .entry {
        display: flex;
        align-items: center;
        gap: 12px;

        .course-informations-label {
          display: flex;
          flex-direction: column;
          align-content: center;


          label {
            color: var(--text-secondary);
            margin-bottom: 3px;
            line-height: 1;
          }

          p {
            @extend .pro-bold !optional;
            color: var(--text-primary);
            line-height: 1;

          }

        }
      }

    }

    .pourcentage-done-course {
      margin-top: 27px;
      background-color: var(--main-color-lighter);
      padding: 12px 3px 12px 12px;

      span {
        display: flex;
        align-items: center;
        color: var(--text-secondary);

        p {
          @extend .pro-semibold !optional;
          color: var(--text-primary);
          margin-left: 3px;
        }
      }

      .progression-bar {
        margin-top: 6px;
        background-color: var(--pure-white);
        height: 6px;
        width: 100%;
        border-radius: 24px;
        position: relative;

        .progression-done-bar {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          border-radius: 24px;
          background-color: var(--main-color);
        }
      }
    }
  }

  > .actions {
    display: flex;
    flex-flow: row;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top: 1px solid #D8D8D8;

    > .action-button {
      flex: 1;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;

      &:not(:last-child) {
        position: relative;

        &:after {
          position: absolute;
          top: 5px;
          right: 0;
          content: '';
          display: block;
          height: 2rem;
          border-right: 1px solid #D8D8D8;
        }
      }

      > .icon {
        width: 1.5rem;
        height: 1.5rem;
        margin-bottom: .7rem;
      }

      > span {
        @extend .pro-semibold !optional;
        font-size: 1rem;
        text-transform: uppercase;
        color: var(--text-secondary);
      }

      &:hover {
        > span {
          color: var(--main-color);
        }
      }
    }
  }

}

.parcours-card:hover {
  box-shadow: rgba(173, 173, 173, 0.1) 8px 2rem 2rem 0;
}

// .parcours-card {
//   width: 100%;
//   border-radius: .3rem;
//   background-color: var(--pure-white);
//   box-shadow: rgba(173, 173, 173, 0.1) 4px 1rem 1rem 0;

//   &:hover {
//     box-shadow: rgba(173, 173, 173, 0.3) 4px 1rem 1rem 0;
//     transition: box-shadow 500ms;
//   }

//   &-inner {
//     > .parcours-image {
//       background-size: cover;
//       background-position: 50% 50%;
//       // @include aspect-ratio(220, 120);
//     }

//     > .bottom {
//       padding: 1rem 1rem 2rem;

//       > .label {
//         @extend .pro-semibold !optional;
//         margin-bottom: 1.5rem;
//         height: 6rem;
//         color: var(--text-primary-dark);
//         font-size: 1.4rem;
//         overflow: hidden;
//         text-overflow: ellipsis;
//       }

//       > .course-informations {
//         display: inline-block;
//         width: 100%;

//         > .entry {
//           margin-right: 2rem;
//           display: inline-block;
//           float: left;

//           &:last-child {
//             margin-right: 0;
//           }

//           > .entry-label {
//             @extend .pro-semibold !optional;
//             margin-bottom: 0.4rem;
//             color:var(--text-secondary);
//             font-size: 1.2rem;
//             text-transform: uppercase;
//           }

//           > .entry-count {
//             @extend .pro-semibold !optional;
//             text-align: center;
//             font-size: 1.2rem;
//             color: var(--text-primary-dark);
//           }
//         }
//       }
//     }
//   }


