.card-title {
    width: 100%;
    height: 150px;
    min-height: 150px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    overflow: hidden;
    margin-bottom: 15px;
    box-shadow: rgba(173, 173, 173, 0.1) 4px 1rem 1rem 0px;
    svg {
      width: auto;
      height: 100%;
      border-radius: 10px;
    }
    div {
      margin: auto 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px;

      .icon {
        width: auto;
        padding: 0;
      }
    }
  }