.header-iasynthesis-page {
  position: relative;
  .beta {
    position: absolute;
    right: 1rem;
    bottom: -1rem;
    padding: 0.5rem;
    width: fit-content;
    background-color: red;
    color: white;
  }
}

// EDIT MODE STYLE
.wrapper-edit-page {
  .header-edit-page {
    display: flex;
    justify-content: flex-end;

    .wrapper-actions-button {
      display: flex;
      gap: 1rem;
    }
  }

  .wrapper-title-status {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0.5rem 0;

    p.status {
      position: absolute;
      right: 1rem;
      bottom: -1rem;
      padding: 4px 8px;
      background-color: var(--main-color);
      color: var(--main-color-lighter);
      border-radius: 0.5rem 0.5rem 0 0;
    }
  }

  .wrapper-inputs {
    display: flex;
  }

  .wrapper-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 1rem 0;

    .wrapper-directives,
    .wrapper-datasources {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 1rem;
      width: 50%;
      font-size: 14px;
    }

    .wrapper-directives {
      border-right: 1px solid var(--main-color-light);
    }
  }
}

.popin-iasynthesis-edit {
  min-width: 90% !important;
  height: 90% !important;
  padding: 2rem !important;
  // overflow: auto !important;
}

.error-container,
.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  z-index: 99999999;
}
