@import "../../stylesheets/common/fonts";

.consultant-client-page {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;

    .client-informations {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .primary-button {
            width: fit-content;
            align-self: end;
        }

        .alert-invalid-client {
            @extend .primary-strong;
            color: var(--red);
            font-size: 14px;
        }
    }
}

.left-navbar {
    display: flex;
    align-items: center;
    gap: 24px;

    h1 {
        font-size: 18px;
        font-weight: 800;
    }

}