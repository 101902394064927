.edit-question-form-module-popin {
  width: 90vw;
  height: 80vh;

  .layout {
    height: 100%;

    .aside {
      padding: 12px 24px;
      width: 40vw;
      background-color: var(--pure-white);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      .toggle-btn {
        display: none;
      }


      .edit-question-aside {
        // width: 35rem !important;
        display: flex;
        flex-direction: column;
        gap: 12px;
        position: initial;
        height: 100%;
        padding: 0;


        .question-type-container {
          display: flex;
          height: 4.5rem;
          width: 100%;
          align-items: center;

          .icon-form-question-type {
            width: 1.5rem;
            height: 1.5rem;

            .icon {
              width: 1.5rem;
              height: 1.5rem;
            }
          }

          .title {
            @extend .pro-semibold !optional;
            font-size: 1.4rem;
            margin-left: 1rem;
          }
        }

        .question-title,
        .question-specific {
          height: 16.5rem;
          width: 100%;

          .text-area-field {
            height: calc(100% - 2rem);
            > .common-form-label {
              color: var(--text-secondary);
            }
          }

        }

        .question-image {
          display: flex;
          flex-direction: column;
          gap: 12px;
          .question-image-title {
            display: flex;
            align-items: center;
            gap: 6px;

            p {
              @extend .tertiary-strong !optional;
              color: var(--text-primary);
              font-size: 14px;
            }
          }
        }

        .question-specific {
          border-top: 0;

          &.question-specific-multiple-choice {
            height: 17rem;
            overflow: auto;
          }

          .question-specific-multiple-choice-title {
            @extend .common-form-label !optional;
            margin-bottom: 0.5rem;
          }

          .question-specific-multiple-choice-list {
            .form-multiple-choice-configuration-row {
              margin-bottom: 1rem;
            }
          }
        }

        .question-configuration {
          flex: 1;
          width: 100%;

          .question-configuration-title {
            @extend .common-form-label !optional;
            color: var(--text-secondary)
          }

          .question-prop-list {
            margin-top: 1rem;

            .configuration-prop-container {
              display: flex;
              align-items: center;
              justify-content: space-between;
              height: 2rem;
              margin-bottom: 1rem;

              .configuration-prop-description {
                @extend .tertiary-strong !optional;
                font-size: 1.4rem;
                color: var(--text-primary);
              }
            }

            .configuration-prop-container-with-details {
              display: flex;
              flex-direction: column;
            }

            .configuration-prop-details {}
          }
        }

        .actions {
          display: flex;
          justify-content: space-around;
        }
      }
    }

    // .aside {
    //   width: 35rem;
    //   border: 1px solid red;
    //   padding: 24px;

    //   .toggle-btn {
    //     display: none;
    //   }

    //   .edit-question-aside {
    //     width: 35rem !important;
    //     display: flex;
    //     flex-direction: column;
    //     position: initial;
    //     height: auto;
    //     padding: 0;

    //     .question-type-container {
    //       display: flex;
    //       height: 4.5rem;
    //       width: 100%;
    //       align-items: center;
    //       padding: 0 2rem;

    //       .icon-form-question-type {
    //         width: 1.5rem;
    //         height: 1.5rem;

    //         .icon {
    //           width: 1.5rem;
    //           height: 1.5rem;
    //         }
    //       }

    //       .title {
    //         @extend .pro-semibold !optional;
    //         font-size: 1.4rem;
    //         margin-left: 1rem;
    //       }
    //     }

    //     .question-title, .question-specific {
    //       height: 16.5rem;
    //       width: 100%;
    //       padding: 2rem;
    //       border-top: var(--grey) solid 0.1rem;
    //       border-bottom: var(--grey) solid 0.1rem;

    //       .text-area-field {
    //         height: calc(100% - 2rem);
    //       }
    //     }

    //     .question-specific {
    //       border-top: 0;

    //       &.question-specific-multiple-choice {
    //         height: 17rem;
    //         overflow: auto;
    //       }

    //       .question-specific-multiple-choice-title {
    //         @extend .common-form-label !optional;
    //         margin-bottom: 0.5rem;
    //       }

    //       .question-specific-multiple-choice-list {
    //         .form-multiple-choice-configuration-row {
    //           margin-bottom: 1rem;
    //         }
    //       }
    //     }

    //     .question-configuration {
    //       flex: 1;
    //       width: 100%;
    //       padding: 2rem;

    //       .question-configuration-title {
    //         @extend .common-form-label !optional;
    //       }

    //       .question-prop-list {
    //         margin-top: 1rem;

    //         .configuration-prop-container {
    //           display: flex;
    //           align-items: center;
    //           justify-content: space-between;
    //           height: 2rem;
    //           margin-bottom: 1rem;

    //           .configuration-prop-description {
    //             @extend .pro-semibold !optional;
    //             font-size: 1.4rem;
    //             color: var(--text-primary-dark);
    //           }
    //         }

    //         .configuration-prop-container-with-details {
    //           display: flex;
    //           flex-direction: column;
    //         }

    //         .configuration-prop-details {

    //         }
    //       }
    //     }

    //     .actions {
    //       padding: 2rem;
    //       display: flex;
    //       justify-content: space-around;
    //     }
    //   }
    // }
    .content {
      height: 100%;
      width: 100%;

      .question-overview {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-height: 100%;
        overflow: auto;

        header {
          width: 3rem;
          border-top: 0.3rem solid var(--main-color);
          margin-bottom: 12px;
        }

        .overview {
          > div > div {
            padding-left: 0;
          }

          .quiz-text-area {
            display: flex;
            flex-direction: column;
            gap: 12px;
            p {
              @extend .headline !optional;
              color: var(--text-primary);
              font-size: 24px;
            }
          }

          > div > .quiz-step {
            > .step {
              display: none;
            }

            > .label {
              font-size: 2.4rem;
            }
          }

          textarea {
            height: 10rem;
          }

          .quiz-group {
            .content {
              opacity: 0.6;

              .other-questions {
                @extend .pro-semibold !optional;
                font-size: 2.4rem;
              }
            }
          }
        }
      }
    
    }
  }
}