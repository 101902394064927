.settings-card {
  $label-color: var(--text-primary-dark);
  background-color: var(--pure-white);
  padding: 10px 10px 23px;

  > .label {
    padding-left: 18px;
    font-size: 1.2rem;
    color: $label-color;
    @extend .pro-light !optional;
    margin-bottom: 5px;
  }

  .checkboxes {
    .check-box-field {
      label {
        > .label {
          color: var(--text-primary-dark);
          font-size: 1.2rem;
          @extend .pro-semibold !optional;
        }
      }

      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
