.close-popin {
  padding-top: 15px;
  padding-right: 15px;

  .close-popin-content {
    position: relative;
   background-color: var(--pure-white);
    width: 100%;
    height: 100%;
    padding: 20pt;

    > .circle-button {
      width: 30px;
      height: 30px;
      position: absolute;
      right: -15px;
      top: -15px;
    }

    .close-popin-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.7);
    }
  }

}
