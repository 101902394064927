.parcours-step-quiz {
  .parcours-step-body {
    padding: 1rem;
  }

  .question-counter {
    .count-value {
      color: var(--text-primary-dark);
      margin-right: 5px;
      font-size: 1.2rem;
    }

    .count-label {
      color:var(--text-secondary);
      font-size: 1.2rem;
    }

    &:not(:last-child) {
      margin-right: 2.4rem;
    }
  }

  .primary-button {
    margin-left: auto;
  }

  .quiz-complete-bar {
    display: flex;
    align-items: center;
    position: absolute;
    padding-right: 1rem;
    right: 0;

    .check-box-field {
      margin-right: 1.5rem;
    }
  }

  // @include mobile() {
  //   .children {
  //     .children-item {
  //       flex-direction: column;
  //       align-items: flex-start;
  //     }
  //   }

  //   .quiz-complete-bar {
  //     position: relative;
  //     width: 100%;
  //     padding: 0;
  //     justify-content: space-between;

  //     .circle-button {
  //       width: 3rem;
  //       height: 3rem;
  //     }
  //   }
  // }
}
