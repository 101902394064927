@import '../../../stylesheets/common/fonts';

.delete-group-popin {
    p {
         @extend .primary-default;
          font-size: 14px;
          color: var(--text-primary);

        span {
            @extend .primary-strong;
        }
    } 
}