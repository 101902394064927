$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

.beneficiary-parcours-all-modules {
  overflow: auto; /* ou scroll */
  max-height: 70vh;
  height: 70vh;

}

.beneficiary-parcours-all-modules-Export {
  overflow: auto; /* ou scroll */
  max-height: none;
  height: 100%;
}

.beneficiary-parcours-right-container {
  background-color: var(--pure-white);
  padding: 36px 36px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 20px;

  @media screen and (max-width: $md) {
    position: relative;
    z-index: 1;
    padding: 36px 0px;

    .background-white {
      position: absolute;
      top: 0;
      left: -20%;
      width: 140%;
      height: 100%;
      background-color: var(--pure-white);
      z-index: -1;
    }
  }

  .beneficiary-parcours-step-header {
    .cover-picture {
      width: 100%;
      height: 320px;
      border-radius: 6px;
      background-size: cover;
    }

    .cover-picture-default {
      width: 100%;
      height: 0px;
      border-radius: 6px;
      background-color: var(--red-medium);
    }

    .tag-container {
      width: fit-content;
      margin-bottom: 12px;
    }

    h1 {
      @extend .headline-1 !optional;
      font-size: 24px;
      line-height: 32px;
    }

    .description {
      font-size: 16px;
      line-height: 24px;
      color: var(--text-secondary);
    }

    .beneficiary-parcours-step-infos {
      // margin: 30px 0 18px 0;
      display: flex;
      justify-content: end;

      h3 {
        @extend .headline-3 !optional;
        font-size: 16px;
        line-height: 24px;
      }

      .parcours-group-infos {
        display: flex;
        gap: 12px;
      }
    }
  }
}
