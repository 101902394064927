@import '../../../../stylesheets/common/fonts';

.appointment-popin-content{
    display: flex;
    flex-direction: column;
    gap: 24px;

    .appointment-popin-section-title {
        display: flex;
        align-items: center;
        gap: 12px;

        p {
            @extend .primary-strong;
            color: var(--text-primary);
            font-size: 16px;
        }

        svg {
            width: 100%;
            height: 100%;
        }
    }

    .appointment-popin-type {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .appointment-popin-content-sorter-buttons {
            display: flex;
            gap: 12px;
    
            .sorter-button {
                width: fit-content;
            }
        }

        .appointment-popin-content-checkboxes {
            display: flex;
            gap: 24px;
            font-size: 14px;
        }

        .appointment-popin-obligatory {
            display: flex;
            gap: 24px;
            color: var(--text-primary);
            font-size: 14px;
        }
    }

    .appointment-popin-time {
        display: flex;
        flex-direction: column;
        gap: 6px;

        .appointment-popin-inputs-time {
            display: flex;
            gap: 12px;
            width: 100%;

            .input-field {
                width: 100%;
            }

            input:disabled {
                color: var(--text-secondary)
            }
        }
    }

    .appointment-visio-warning {
        color: var(--red);
        font-size: 1.2rem;
        font-style: italic;
    }

    .videocall_url {
        display: block;
    }

    .videocall_url_hidden {
        display: none;
    }

    .appointment-popin-consultant {
        display: flex;
        flex-direction: column;
        gap: 6px;
    }

    .appointment-popin-talents {
        display: flex;
        flex-direction: column;
        gap: 6px;
    }

    .appointment-popin-procedures {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .appointment-popin-procedures-1 {
            
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 6px;
        }
        .appointment-popin-procedures-2 {
            display: flex;
            gap: 12px;
            width: 100%;
            justify-content: space-between;

            .appointment-popin-procedures-address, .appointment-popin-procedures-phone {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 6px;
            }
    }
    }

    .appointment-popin-signature {
    display: flex;
        flex-direction: column;
        gap: 6px;
    }


    .appointment-popin-description {
        display: flex;
        flex-direction: column;
        gap: 6px;

        textarea {
            width: 100%;
            resize: none;
            height: 100px;
            border: 1px solid var(--grey-medium);
            border-radius: 6px;
            outline: none;
    }
    }

}