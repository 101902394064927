.parcours-module-content-link {
  $parcours-module-content-default-color: var(--text-primary-dark) !default;
  $parcours-module-content-active-color: var(--main-color) !default;

  width: 100%;
  display: flex;
  align-items: center;
  //padding: 12px;
  //background-color: white;
  cursor: pointer;

  &.activated {
    > .label {
      color: $parcours-module-content-active-color
    }
  }

  > [class^="icon-resource-type-"] {
    width: auto;
    height: auto;

    .icon {
      width: 12px;
      height: 12px;
      margin-right: 5px;

      svg {
        display: block;
      }
    }
  }

  > .label {
    font-size: 1.4rem;
    text-decoration: none;
    color: $parcours-module-content-default-color;
    @extend .pro-semibold !optional;
  }

}
