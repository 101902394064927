.consultant-course-templates-preview-page {
  > .layout {
    > aside {
      > * {
        flex-flow: column;
      }
    }

    > section {
      padding: 0;

      > header {
        position: relative;
        display: flex;
        padding: 2rem 2.5rem;
        height: 16rem;
        background: no-repeat center;
        background-size: cover;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: rgba(255, 255, 255, 0.7);
        }

        > div {
          position: relative;
          flex: 1;
        }
      }

      > section {
        flex: 1;
        display: flex;
        flex-flow: column;
        padding: 2rem 4rem;

        > .title {
          @extend .pro-semibold !optional;
          margin-bottom: .6rem;
          width: 100%;
          font-size: 2rem;
          color: var(--text-primary-dark);
          background: none;
          border: none;
        }

        > .informations {
          display: flex;
          align-items: flex-end;
          margin-bottom: 1.3rem;

          > .entry {
            display: flex;
            align-items: flex-end;
            margin-right: 3rem;

            > .count {
              @extend .pro-semibold !optional;
              margin-right: 0.7rem;
              font-size: 1.4rem;
              color: var(--text-primary-dark);
            }

            > .label {
              font-size: 1.2rem;
              text-transform: uppercase;
              color:var(--text-secondary);
            }
          }
        }

        > .description {
          margin-bottom: 2.5rem;
          @extend .pro-regular !optional;
          font-size: 1.4rem;
          color: var(--text-primary-dark);
        }

        > .steps {
          flex: 1;

          .course-step {
            margin-bottom: 5rem;

            > .parcours-step-header {
              .trash-chevron-wrapper {
                margin: 0 1.1rem;
              }
            }

            .modules {
              > * {
                margin-bottom: 1rem;
              }
            }
          }
        }
      }
    }
  }
}
