.circle-button {
  $background-accent-color: var(--main-color);
  $background-grey1-color: var(--grey);
  $background-grey2-color:var(--text-secondary);
  $background-grey3-color: var(--text-primary-dark);
  $background-red1-color: var(--red);
  $background-orange1-color: var(--orange);
  $background-green1-color: var(--green);

  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: $background-accent-color;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(112, 130, 243, 0.30);

  &[data-background-color="accent"] {
    background-color: $background-accent-color;
  }

  &[data-background-color="grey1"] {
    background-color: $background-grey1-color;
  }

  &[data-background-color="grey2"] {
    background-color: $background-grey2-color;
  }

  &[data-background-color="grey3"] {
    background-color: $background-grey3-color;
  }

  &[data-background-color="red1"] {
    background-color: $background-red1-color;
  }

  &[data-background-color="orange1"] {
    background-color: $background-orange1-color;
  }

  &[data-background-color="green1"] {
    background-color: $background-green1-color;
  }

  &[data-background-color="white"] {
   background-color: var(--pure-white);
  }

  > .icon {
    width: 12px;
    height: 12px;

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &:active {
    opacity: 0.75;
  }

  &.disabled {
    background: $background-grey1-color;
    color: white;
    border-color: $background-grey1-color;
    cursor: default;
    box-shadow: none;
  }

}
