$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

.quiz-opinion-scale {
  $cell-color: var(--main-color) !default;
  $cell-text-color: var(--text-primary-dark) !default;
  width: 100%;

  .cells-container {
    margin-top: 3rem;
    padding-left: 4rem;
    @media screen and (max-width: $lg) {
      padding-left: 0;
    }

    .cell {
      display: inline-block;
      width: 4.3rem;
      height: 2.8rem;
      border: 0.1rem solid $cell-color;
      border-right: 0;
      position: relative;
      cursor: pointer;
      @media screen and (max-width: $lg) {
        width: 3.3rem;
        height: 2.8rem;
      }
      @media screen and (max-width: $lg) {
        width: 2.3rem;
        height: 2.8rem;
      }

      &:first-child {
        border-top-left-radius: 0.3rem;
        border-bottom-left-radius: 0.3rem;
      }

      &:last-child {
        border-top-right-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
        border-right: 0.1rem solid $cell-color;
      }

      > span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @extend .pro-semibold !optional;
        font-size: 1.4rem;
        color: $cell-text-color;
      }

      &.active {
        -webkit-print-color-adjust: exact;
        background-color: $cell-color;
        border-left: 0.1rem solid var(--pure-white);

        &:first-child {
          border-left: 0.1rem solid $cell-color;
        }

        > span {
          color: var(--pure-white);
        }
      }
    }
  }

  // @include mobile() {
  //   .cells-container {
  //     margin-top: 2rem;
  //     padding-left: 3rem;
  //     display: flex;

  //     .cell {
  //       flex: 1;
  //       width: 100%;
  //     }
  //   }
  // }
}
