.side-resource-selector {
  display: flex;
  flex-flow: column;
  overflow: scroll;
  padding: 24px;
  height: 100%;

  .line-menu {
    margin-bottom: .5rem;
    display: block;
    align-content: center;

    > .resources {
      flex: 1;
      justify-content: space-between;

      > .line-menu-link {
        height: auto;
        width: 100%;

        > .label-container {
          > .label {
            font-size: 1.4rem;
          }
        }

        >.label-container .selected {
          @extend .primary-strong !optional;
        }

        > .selector {
          width: 100%;
        }
      }
    }
  }

  .side-resource-selector-content {
    margin-top: 24px;

    .new-ressources {
      margin: 14px 0;
    } 
  
    .search-field {
      margin-bottom: 1.2rem;
  
      > input {
        text-align: right;
      }
  
      > .icon {
        margin: 0;
        width: 1.3rem;
      }
    }
  
    .combo-fields-wrapper {
      display: flex;
      margin: 1rem -0.5rem 1.5rem -0.5rem;
      justify-content: space-between;
  
      .common-form-separator {
        display: none;
      }
  
      > .combo-field {
        flex: 1;
        margin: 0 0.5rem;
  
        > .select-container {
          border-bottom: none;
  
          > select {
            @extend .pro-regular !optional;
            margin-bottom: -0.4rem;
            font-size: 1.4rem;
            color:var(--text-secondary);
          }
  
          > .icon {
            width: 1rem;
  
            > svg [stroke] {
              stroke:var(--text-secondary);
            }
          }
        }
      }
    }
  
    .side-resource-item {
      margin-bottom: 1rem;
  
      .main {
        .ressource-icon {
          margin-right: 0.7rem;
          height: 36px;
          width: 36px;
          svg > * {
            display: block;
            height: 36px;
            width: auto;
          }
        }
      }
    }
  
    [draggable] {
      &:active {
        margin-bottom: 1rem;
      }
  
      &.dragging {
        .side-resource-item {
          border: none;
          background: var(--pure-white);
          box-shadow: 0 3px 6px 0 rgba(112, 130, 243, 0.08);
        }
      }
    }
  
    .new-items {
      @extend .pro-semibold !optional;
  
      margin-top: 1rem;
      font-size: 1.4rem;
      color: var(--main-color);
      height: 100%;
      overflow: auto;
  
      .item {
        padding: .8rem 1rem 1rem;
        border-radius: 0.2rem;
        margin-bottom: 1rem;
  
        > .title {
          margin-bottom: .8rem;
        }
      }
  
      .group {
        @extend .item;
        padding-bottom: 0;
        background: var(----background-color-1);
        border: 1px solid var(--main-color);
  
        &.dragging {
          padding: 0;
          border: none;
          background: none;
  
          > .title {
            font-size: 1.6rem;
            color: var(--total-black)
          }
        }
      }
    }
  
    .existing-items {
      padding: 0 1rem;
      height: 100%;
      overflow: auto;
    }
  }

  .all-ressources {
    label {
        @extend .primary-strong !optional;
        color: var(--text-primary);
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 30px;
    }

    .droppable {
      margin-top: 6px;
    }
  }

  .filters-ressources {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    padding: 12px;

    .filters-ressources-title {
      display: flex;
      align-items: center;
      gap: 6px;

      p {
        color: var(--text-secondary);
        font-size: 12px;
      }

      .icon {
        width: 12px;
        height: auto;
      }
    }

    .search-field {
      margin: 0;
      padding: 8px 0;
      input {
        text-align: left;

      }
    }

    .big-combo {
      margin: 6px 0;
      border-bottom: 1px solid var(--grey-medium);
      border: none;
     
      .select-container {
        color: var(--text-primary-dark);
        width: 100%;
        padding: 6px 0;
      } 

      .Dropdown-menu {
        .Dropdown-option {
          font-size: 14px;
        }
      }
    }
  }
}
